/* eslint-disable */
import React from 'react';
import { AuthContext } from '../../context/context';
import Alerts from '../Misc/Alerts';
import ConnectedIntegrations from './ConnectedIntegrations';
import IntegrationCards from './IntegrationCards';
import { useStyles } from './styles.js';

function Integrations() {
  const {
    state,
    dispatch,
  } = React.useContext(AuthContext);
  const classes = useStyles();
  const [msg, setMsg] = React.useState('');
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);
  const [prog, setProg] = React.useState(true);
  const [teamMembers, setTeamMembers] = React.useState([]);


  React.useEffect(() => {
  }, []);

  return (
    <div className={classes.mainCard}>
      <h1> Manage Integrations </h1>
      <div />
      <Alerts msg={msg} type={type} openMsg={openMsg} setOpenMsg={setOpenMsg} />
      <div className="row pt-3 mt-3" style={{ paddingBottom: '25px' }}>
        <IntegrationCards />
        <div>
          <div className='pt-3 mt-3'>
            <h2> Connected Books </h2>
            <ConnectedIntegrations />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Integrations;
