/* eslint-disable */

import React, { useState } from 'react';
import { Chart } from "react-google-charts";
import { AuthContext } from '../../../context/context';
import { setListItemsParsed } from '../helpers';
export const options = {
    title: "",
    legend: {
        position: 'top'
    },
    // chartArea: { width: '100%', right: 50 }
    chartArea: {
        left: 100, // 50 pixels from the left edge of the chart
        top: 50, // 20 pixels from the top of the chart
        bottom: 50, // 20 pixels from the top of the chart
        right: 50, // 20 pixels from the top of the chart

        width: '100%', // 70% of the chart width
        height: '100%', // 80% of the chart height
    },

};

const PieChartBoard = (props) => {
    const {
        state: {
            integrations, integrationInfo, selectedIntegration, selectedBoard
        }, dispatch
    } = React.useContext(AuthContext);

    const [parsedDataTablePie, setParsedDataTablePie] = useState([])

    React.useEffect(() => {
        setListItems(props.data, props.board.boardData, props.board.boardFormulas)
    }, [props.board, props.data])

    const setListItems = (allData, data, formulas) => {
        let result = setListItemsParsed(allData, data, formulas)
        setParsedDataTablePie(result);
    }

    // const setListItemsFormulasParsed = (data) => {
    //     setListItemsFormulas(data);
    //     let formulas = doFormulas(listItems ,data);
    //     const result = formulas.map(item => [item.name, item.totalAmount]);


    //     let combinedPie = [...parsedDataTablePie, ...result]
    //     setParsedDataTablePie(combinedPie)
    //
    //   }
    return (
        <>
            {parsedDataTablePie &&
                <Chart
                    chartType="PieChart"
                    data={parsedDataTablePie}
                    options={options}
                    width={"100%"}
                    height={"100%"}
                />
            }
        </>
    );
};

export default PieChartBoard;
