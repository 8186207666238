/* eslint-disable */
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react';
import NewProject from '../../components/Projects/NewProject';
import NewBoard from '../../components/ClientPerformance/NewBoard.js';
import { AuthContext } from '../../context/context';
import Integrations from './Integrations';
import Profile from './Profile';
import Project from './Project';
import Board from './Board';

import Subscription from './Subscription';
import Team from './Team';
import { useStyles } from './styles.js';

function Settings() {
  const {
    state: {
      presetComponent, allBoards
    },
    dispatch,
  } = React.useContext(AuthContext);

  const classes = useStyles();
  const [component, setComponent] = React.useState(<Subscription />);
  // const { projects, boards, allBoards, selectedBoard } = state;
  const [isSelected, setSelectedBoard] = useState(null);

  useEffect(() => {
    if (presetComponent !== null) {
      setComponent(presetComponent);
      dispatch({
        type: "SET_PRESET_COMPONENT",
        payload: null,
      });
    }
  }, [presetComponent]);


  const changeComponent = (componentType, index) => {

    if (componentType === 'Profile') {
      setComponent(<Profile />);
    } else if (componentType === 'Subscription') {
      setComponent(<Subscription />);
    } else if (componentType === 'Team') {
      setComponent(<Team />);
    } else if (componentType === 'Board') {
      setComponent(<Board index={index} />);
    } else if (componentType === 'Integrations') {
      setComponent(<Integrations />);
    }
  };

  const [backDropOpen, setBackDropOpen] = React.useState(false);
  const handleCloseBackDrop = () => {
    setBackDropOpen(false);
  };
  const handleToggleBackDrop = () => {
    setBackDropOpen(!backDropOpen);
  };

  const newProject = <Button variant="text" style={{ textTransform: 'none', color: '#55616E' }} onClick={handleToggleBackDrop}>+ Create new</Button>;
  const newBoard = <Button variant="text" style={{ textTransform: 'none', color: '#55616E' }} onClick={handleToggleBackDrop}>+ Create new</Button>;
  const liRef = useRef(null);



  return (
    <div className={classes.container} >
      <h1 className="page-headings">Settings</h1>
      <div className={classes.card}>
        <div className="row">
          <div className="col-2">
            <div className={classes.navBar}>
              <p>
                {' '}
                <button
                  className={classes.buttonStyle}
                  style={{ color: '#55616E' }}
                  onClick={() => changeComponent('Profile')}
                >
                  {' '}
                  Profile Settings
                </button>
              </p>
              <p>
                {' '}
                <button
                  className={classes.buttonStyle}
                  style={{ color: '#55616E' }}
                  onClick={() => changeComponent('Subscription')}
                >
                  {' '}
                  Subscription Settings
                  {' '}
                </button>
              </p>
              <p>
                {' '}
                <button
                  className={classes.buttonStyle}
                  style={{ color: '#55616E' }}
                  onClick={() => changeComponent('Team')}
                >
                  {' '}
                  Team Settings
                </button>
              </p>
              <p>
                {' '}
                <button
                  className={classes.buttonStyle}
                  style={{ color: '#55616E' }}
                  onClick={() => changeComponent('Integrations')}
                >
                  {' '}
                  Manage Integrations
                </button>
              </p>
              <hr className="mt-5 mb-5" />
              <p className={classes.buttonStyle} style={{ color: '#55616E', opacity: '0.34' }}> CLIENTS SETTINGS </p>
              {/* {boards.map((board, index) => (
                <p>
                  {' '}
                  <button
                    className={classes.buttonStyle}
                    style={{ color: '#55616E' }}
                    onClick={() => changeComponent('Board', index)}
                  >
                    {' '}
                    {board.boardName}
                    {' '}

                  </button>
                </p>

              ))} */}
              {allBoards && allBoards.boards && allBoards.boards.map((board, index) => {

                            return (
                              <>{board && board.favorite === "false" && board.owner === allBoards.userId &&
                                <>
                                  <div className="row pl-3">
                                    <ul class="board-list">
                                      <li ref={liRef}
                                        onClick={() => changeComponent('Board', index)}
                                      >
                                        <div class="col-sm-9">
                                            <button class="board-button" 
                                            >
                                              <span class="board-name" style={{ textAlign: "left"}}>{board.boardName}</span>
                                            </button>
                                          </div>
                                      </li>
                                    </ul>
                                  </div>
                                </>
                              }</>
                            )
                          })}

              {newBoard}
              <div>
                {backDropOpen && <NewBoard backDropOpen={backDropOpen} handleCloseBackDrop={handleCloseBackDrop} />}
              </div>
            </div>
          </div>
          <div className="col-1">
            <div className={classes.lineVertical} />
          </div>
          <div className="col-8">
            {component}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
