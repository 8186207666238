/* eslint-disable */

import { reject } from 'lodash';
import axios from './axios';

export const intuitLogin = async (data) => new Promise((resolve, reject) => {
  axios
    .get('/intuitLogin')
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      console.error(err);
      reject(err);
    });
});

export const accessToken = async (url) => new Promise((resolve, reject) => {
  axios
    .get(url)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const getCompanyInfo = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/getCompanyInfo', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const cleanGpt = async () => new Promise((resolve, reject) => {
  axios
    .get("/clean-gpt")
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
})

export const syncAllData = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/syncAllData', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const validateToken = async (data) => new Promise((resolve, reject) => {
  console.log('access_token: ' + data.access_token);
  console.log('refresh_token: ' + data.refresh_token);
  axios
    .post('/validateToken', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const refreshToken = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/refreshToken', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const getIntegrationInfo = async () => new Promise((resolve, reject) => {
  axios
    .get('/getIntegrationInfo')
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const getIntegrationBoards = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/getIntegrationBoards', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const changeIntegrationBoardName = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/renameIntegrationBoard', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((error) => {
      reject(error);
    })
});

export const getInvitesBoardData = async (data) => new Promise((resolve, reject) => {
  axios
    .get("/getInvitesBoardData")
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const addNewBoard = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/addNewBoard', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});


export const addNewTab = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/addNewTab', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const deleteTab = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/deleteTab', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const updateTabName = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/updateTabName', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const duplicateBoardItem = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/duplicateBoardItem', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const deleteBoard = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/deleteBoard', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const deleteSubBoard = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/deleteSubBoard', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const saveLayout = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/saveLayout', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const createAndAddBoardName = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/createAndAddBoardName', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const favoriteBoard = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/favoriteBoard', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});
export const deleteIntegrationInfo = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/deleteIntegrationInfo', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const getIntegrationData = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/getIntegrationData', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const getQuickbooksUserInfo = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/getQuickbooksUserInfo', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const getBalanceSheet = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/getBalanceSheet', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const getCashFlow = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/getCashFlow', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const getIncomeStatement = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/getIncomeStatement', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});



export const getCorporatePerformanceData = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/getCorporatePerformanceData', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});