/* eslint-disable */

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LOGO from '../../assets/Dx.png';
import axios from '../../context/axios';
import { AuthContext } from '../../context/context';
import { getUser, getUserPurchasing } from '../../context/fetch-service';
import { getIntegrationInfo } from '../../context/intuit-service';
import { useStyles } from './styles.js';
import { myCompany } from '../../context/company-service.js';

function Login() {
  const classes = useStyles();
  const history = useNavigate();
  const { state: {
    companyName
  }, dispatch } = React.useContext(AuthContext);
  const [passwordType, setPasswordType] = React.useState('password');
  const [alertClass, setAlertClass] = React.useState('');
  const [err, setErr] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [loginForm, setLoginForm] = React.useState({
    email: '',
    password: '',
  });

  const handleLogin = (e) => {
    const { name, value } = e.target;
    setLoginForm((prevState) =>
    ({
      ...prevState,
      [name]: value,
    }));
  };

  function groupPlansAndSumQuantities(subscriptionData) {
    const planMap = new Map();

    subscriptionData.forEach(subscription => {
      if (subscription.subscriptionDetails && subscription.subscriptionDetails.items && subscription.subscriptionDetails.items.data) {
        subscription.subscriptionDetails.items.data.forEach(item => {
          if (item.plan) {
            const planID = item.plan.id;
            const quantity = item.quantity;
            const isActive = item.plan.active;

            if (planMap.has(planID)) {
              const existingPlan = planMap.get(planID);
              existingPlan.quantity += quantity;
              existingPlan.active = existingPlan.active || isActive; // Set to true if either plan is active
            } else {
              planMap.set(planID, { plan: item.plan, quantity, active: isActive });
            }
          }
        });
      }
    });

    return Array.from(planMap.values());
  }

  function categorizePlans(subscriptionData) {
    const categorizedData = {
      clients: [],
      editors: []
    };

    const productMapping = {
      "prod_OhUTJRysPqIDJA": "clients",
      "prod_OhURofTlRdkPM8": "clients",
      "prod_OhUSqKIYaZvFxJ": "editors",
      "prod_OhUQO75zUu0V35": "editors"
    };

    subscriptionData.forEach(subscription => {
      if (subscription.subscriptionDetails && subscription.subscriptionDetails.items && subscription.subscriptionDetails.items.data) {
        subscription.subscriptionDetails.items.data.forEach(item => {
          if (item.plan && item.plan.product && productMapping[item.plan.product]) {
            const category = productMapping[item.plan.product];
            categorizedData[category].push({
              subscriptionID: subscription.subscriptionDetails.id,
              plan: item.plan,
              quantity: item.quantity
            });
          }
        });
      }
    });

    return categorizedData;
  }



  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const login = await axios.post('/login', loginForm);
      if (login.status === 200 || login.status === 201) {
        let [user, integrationInfoResponse, purchasing] = await Promise.all([
          getUser(login.data.token),
          getIntegrationInfo(),
          getUserPurchasing({logIn: true})
        ]);

        if (integrationInfoResponse) {
          dispatch({
            type: 'SET_INTEGRATIONINFO',
            payload: integrationInfoResponse.data.integrations,
          });
        }
        
        const companyDetails = await myCompany();
        if (companyDetails.data?.mycompany?.businessName) {
          console.log(companyDetails.data?.mycompany?.businessName);
          dispatch({
            type: "SET_COMPANY_NAME",
            payload: companyDetails.data?.mycompany?.businessName,
          });
        }

        

        let data = groupPlansAndSumQuantities(purchasing);
        let planDetails = categorizePlans(purchasing)
        purchasing = { ...purchasing, planDetails }
        if (purchasing) {
          dispatch({
            type: 'SET_PURCHASING',
            payload: purchasing,
          });
        }

        // let data = groupPlansAndSumQuantities(purchasing);




        if (user) {
          setErr('');
          setAlertClass('show');
          setLoginForm({
            email: '',
            password: '',
          });
          dispatch({
            type: 'LOGIN',
            payload: {
              token: login.data.token,
              user,
              access_token:
                user.access_token && user.access_token.length > 0
                  ? user.access_token[0].access_token
                  : null,
            },
          });
          setLoader(false);
          window.location.href = '/clientperformance';
        }
      }
    } catch (e) {
      setAlertClass('show');
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else if (e.response.data.message) {
          setErr(e.response.data.message.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
  };

  const handlePwdType = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className="row">
          <div className={[classes.toolbar, classes.Logo].join(' ')}>
            <Link to="/">
              <img src={LOGO} alt="logo-black" style={{ width: '190px', height: '30px', marginLeft: '30px' }} />
            </Link>
          </div>
        </div>
        <div>
          <div className={classes.container}>
            <h1 className="display-4 text-center mb-3" style={{ color: '#55616E' }}>
              Sign in
            </h1>
            <p className="text-center mb-3" style={{ fontSize: 20}}>
              New to Datatrixs?
              {' '}
              <Link to="/signup">Sign up</Link>
              .
            </p>
            {/* <a href="https://finpro.auth.us-east-1.amazoncognito.com/login?client_id=4to7j1osoqh5t8vrame1qbd5vs&response_type=token&scope=email+openid+profile&redirect_uri=http://localhost:3001"><img src={gmailLogin} alt="logo-black" style={{ marginTop: '10px', marginBottom: '10px' }} /></a> */}

            <p className="text-center mb-3 or-divider" style={{ fontSize: 20}}>
              {' '}
              or
            </p>
            {err && (
              <div className={`alert alert-danger alert-dismissible fade ${alertClass}`} role="alert">
                <strong>{err}</strong>
                <button onClick={handleCloseAlert} type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group" style={{ marginTop: '20px' }}>
                <input type="email" name="email" value={loginForm.email} onChange={handleLogin} className="form-control" required placeholder="Email" />
              </div>

              <div className="form-group">
                <div className="input-group input-group-merge">
                  <input className="form-control" type={passwordType} name="password" value={loginForm.password} onChange={handleLogin} required placeholder="Password" />

                  <span onClick={handlePwdType} className="input-group-text">
                    <i style={{ cursor: 'pointer', fontSize: '12px' }} className="fe fe-eye" />
                  </span>
                </div>
              </div>
              <div style={{ float: 'right', marginTop: '-20px', marginBottom: '20px' }}>
                <Link to="/forgotpassword" className="form-text small text-muted">
                  Forgot password?
                </Link>
              </div>

              <button disabled={loader} className="btn btn-lg btn-block btn-custom mb-3" style={{ background: 'black', margin: 0 }}>
                {loader && (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!loader && 'Sign in'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
