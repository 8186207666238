import { Button } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { makeStyles } from '@material-ui/styles';
import Alert from '@mui/material/Alert';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GA from '../views/GandA/GandA';
import Marketing from '../views/Marketing/Marketing';
import RD from '../views/RandD/RandD';
import Sales from '../views/Sales/Sales';
import ForecastInputs from './ForecastsComponents/ForecastInputs';
import ForecastsPageView from './ForecastsComponents/ForecastsPageView';

import { AuthContext } from '../context/context';
import './CSS/ForecastsPage.scss';

const Styles = makeStyles({

  link: {
    color: 'black',
  },

  btn: {
    width: '15%',
    backgroundColor: '#5abcdc',
    borderRadius: '1rem',
    fontWeight: 'bold',
    fontFamily: 'cerebri sans',
    fontSize: '1rem',
    padding: '.8rem 1rem',
  },
});
const ForcastsPage = () => {
  const [component, setComponent] = useState('revenue');

  const {
    state: {
      user, switchUser, purchasing,
    },
  } = React.useContext(AuthContext);
  const history = useNavigate();
  const userSub = purchasing;

  const ForecastNavBtn = ({ props }) => {
    const classes = Styles();

    const changeComponent = () => {
      setComponent(props.link);
    };
    return (
      <>
        <Button onClick={changeComponent} startIcon={props.icon} className={classes.btn} id="btn" style={{ backgroundColor: '#CAD4D7' }}>
          {' '}
          {props.title}
          {' '}
        </Button>
      </>
    );
  };

  const AllComponents = (active) => {
    switch (active) {
      case 'revenue':
        return <ForecastInputs />;
      case 'sales':
        return <Sales />;
      case 'marketing':
        return <Marketing />;
      case 'rd':
        return <RD />;
      case 'ga':
        return <GA />;
      default:
        return <ForecastInputs />;
    }
  };

  const ForecastsNavBtnData = [
    {
      title: 'Revenue',
      link: 'revenue',
      icon: <EqualizerIcon />,
    },
    {
      title: 'Sales',
      link: 'sales',
      icon: <ReceiptOutlinedIcon />,
    },
    {
      title: 'Marketing',
      link: 'marketing',
      icon: <HomeWorkOutlinedIcon />,
    },
    {
      title: 'R & D',
      link: 'rd',
      icon: <EqualizerIcon />,
    },
    {
      title: 'G & A',
      link: 'ga',
      icon: <PlaylistAddCheckOutlinedIcon />,
    },
  ];

  let message = '';
  let expired = false;
  let date = new Date(userSub[0].trialStartDate);
  date.setDate(date.getDate() + 7);
  let expiryDate = Math.round((date.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
  if (userSub[0].trialStatus) {
    if (expiryDate > 0) {
      message = `Your Trial Ends in ${expiryDate} Days!`;
    } else {
      message = "We think you'll like it here! Choose a free trial to get started";
      expired = true;
    }
  }

  return (
    <>
      <div className="forcastspage_container">
        <div className="forcastspage_container" />
        {user.email !== switchUser.userName && switchUser.userName
          ? (
            <div>
              <Alert severity="success">{'Currently on: ' + switchUser.userName}</Alert>
            </div>
          )
          : <></>}
        <ForecastsPageView />
      </div>
    </>
  );
};

export default ForcastsPage;
