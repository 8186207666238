/*eslint-disable*/
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import Profile from '../../assets/profile.png';
import axios from '../../context/axios.js';
import { AuthContext } from '../../context/context.js';
import { getProjects, getAllBoards } from '../../context/fetch-service.js';
import { updateProject } from '../../context/project-service.js';
import { updateBoard } from '../../context/board-service.js';
import { changeIntegrationBoardName } from '../../context/intuit-service.js';

import LoadingBtn from '../Misc/LoadingBtn.js';
import InviteProject from './InviteProject.js';
import InviteBoard from './InviteBoard.js';
import { useStyles } from './styles.js';

function Board(props) {
  const {
    state,
    dispatch,
  } = React.useContext(AuthContext);
  const { allBoards, selectedBoard, selectedTab, tabs, switchUser, purchasing } = state;
  const classes = useStyles();
  const index = props.index;
  const currentboardId = allBoards.boards[index]._id;
  // console.log(currentboardId);
  // console.log(allBoards);

  // const { user } = state;
  // const [boardName, setBoardName] = React.useState(allBoards.boardName);
  // console.log(boardId);
  //const boardName = "test";
  const [boardName, setBoardName] = React.useState(allBoards.boards[index].boardName);


  // const [currency, setCurrency] = React.useState(allBoards[props.index].currency || '');
  // const [fiscalYear, setFiscalYear] = React.useState(allBoards[props.index].fiscalYear || '');

  const [msg, setMsg] = React.useState('');
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);
  const [prog, setProg] = React.useState(true);
  const [file, setFile] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');
  const [inviteData, setInviteData] = React.useState([]);
  const [totalEdits, setTotalEdits] = React.useState(3)
  const [currentEdits, setCurrentEdits] = React.useState(0);
  const [refreshflag, setrefreshflag] = React.useState(false);

  // useEffect(async () => {

  //   setCurrency('USD');
  // }, [props]);

  const updateBoardSettings = async () => {
    // const formData = new FormData();
    let data = {
      newName: boardName,
      board_id: currentboardId
    }
    try {
      let newBoards = allBoards;
      for (let i = 0; i < newBoards.boards.length; i = i + 1) {
        if (newBoards.boards[i]._id === currentboardId) {
          newBoards.boards[i].boardName = boardName;
          break;
        }
      }
      // console.log(data);
      const message = await changeIntegrationBoardName(data);
      if (message.status == 200) {
        dispatch({
          type: "SET_ALLBOARDS",
          payload: newBoards,
        });
      }
    } catch(error) {
      console.error(error);
    }
    // try {
    //   // changeProfile();
    //   const board = await updateBoard({
    //     currentboardId, boardName
    //   });
    // } catch (e) {
    //   const board = await updateBoard({
    //     currentboardId, boardName
    //   });
    // }
    // console.log(updateBoardSettings);
    // fetchData();
  };

  // const changeProfile = async () => {
  //   const formData = new FormData();
  //   try {
  //     let boardId = allBoards[props.index]._id;
  //     formData.append('image', file, file.name, projectId);
  //     file.projectId = projectId;
  //     const profile = await axios.post('/projectImage', { formData, projectId });
  //   } catch (e) {

  //   }
  // };

  // const fetchData = async () => {
  //   await getAllBoards(switchUser.userName)
  //     .then((allBoards) => {
  //       dispatch({
  //         type: 'SET_ALLBOARDS',
  //         payload: allBoards,
  //       });
  //     });
  // };

  const deleteUserInvite = async (email) => {
    setProg(false);
    const url = '/deleteUserInviteBoards';
    axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
    await axios
      .post(url, { recipientId: email, boardId: currentboardId })
      .then((res) => {
        setType('success');
        setMsg(res.data.message);
        setProg(true);
      })
      .catch((err) => {
        setType('error');
        if (err.response && err.response.data) {
          setMsg(err.response.data.message);
        } else {
          setMsg(err.message);
        }
      });
    setOpenMsg(true);
    setProg(true);
    getInvited();
    // await getAllBoards(switchUser.userName)
    //   .then((allBoards) => {
    //     dispatch({
    //       type: 'SET_ALLBOARDS',
    //       payload: allBoards,
    //     });
    //   });
  };

  React.useEffect(async () => {
    // Fetch invite data from the API
    setBoardName(allBoards.boards[index].boardName);
    getInvited();
    if (purchasing.planDetails.editors[0].quantity) {
      setTotalEdits(purchasing.planDetails.editors[0].quantity)
    }
  }, [currentboardId]);

  React.useEffect(async() => {
    if(refreshflag){
      getInvited();
      if (purchasing.planDetails.editors[0].quantity) {
        setTotalEdits(purchasing.planDetails.editors[0].quantity)
      }
      setrefreshflag(false);
    }
    //console.log("test output\n");
  }, [refreshflag]);

  const onChangeFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setFileUrl(reader.result);
        setFile(file);
      };
    }
  };

  // const handleMonthChange = (event) => {
  //   setFiscalYear(event.target.value);
  // };

  const handleAccessChange = async (event, email) => {
    let access = event.target.value;
    let recipientId = email;
    let boardId = currentboardId;
    let boardUserId = allBoards.userId;

    // if (newAccess === 'CAN_MODIFY' && previousAccess !== 'CAN_MODIFY' && currentEdits >= totalEdits) {
    //   setShowSubscription(true);
    //   return;
    // } else {
    //   setShowSubscription(false);
    // }

    const previousinvite = inviteData.find(invite => invite.recipientId === recipientId);
    let previousAccess = previousinvite ? previousinvite.access : null;

    if (access === 'CAN_MODIFY' && previousAccess !== 'CAN_MODIFY') {
      setCurrentEdits(currentEdits + 1);
    } else if (access === 'CAN_VIEW' && previousAccess === 'CAN_MODIFY') {
      setCurrentEdits(currentEdits - 1);
    }

    try {
      const response = await axios.post('/modifyUserInviteBoards', {
        recipientId: recipientId,
        boardId: boardId,
        boardUserId: boardUserId,
        access: access
      });

      console.log('Updated recipient data:', response.data.updatedRecipient);
      setInviteData(prevData =>
        prevData.map(invite =>
          invite.recipientId === recipientId ? { ...invite, access: access } : invite
        )
      );
    } catch (error) {
      console.error('Error updating invite access:', error);
    }

    // const url = '/modifyUserInviteBoards';
    // axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
    // await axios
    //   .post(url, { access, recipientId, boardId, boardUserId, senderId })
    //   .then((res) => {
    //   })
    //   .catch((err) => {
    //   });
  };

  const getInvited = async () => {
    await axios.get('/getInvitesBoards')
      .then(response => {
        // console.log(response);
        const data = response.data.data;
        // console.log(data[0].invited);
        if (data && data.length > 0) {
          const filteredData = data[0].invited.filter(invite => invite.boardId === currentboardId);

          setInviteData(filteredData);
          // console.log(filteredData);

          const modifyAccessEntries = data.flatMap(entry => entry.invited.filter(item => item.access === "CAN_MODIFY"));
          const distinctRecipientIds = [...new Set(modifyAccessEntries.map(item => item.recipientId))];
          const totalDistinctRecipientIds = distinctRecipientIds.length;
          // console.log(distinctRecipientIds);
          setCurrentEdits(totalDistinctRecipientIds)
          // props.setTotalAvailable(totalEdits - totalDistinctRecipientIds)
          // 
        }
      })
      .catch(error => {
        console.error('Error fetching invite data:', error);
      });
  }

  const RenderInvites = (inviteData) => (
    <div>
      {inviteData.map((members, i) => (
        <div
          className="row"
          style={{
            margin: 0,
          }}
        >
          <div className="col-1">
            <img
              style={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                marginRight: '2.5rem',
              }}
              src={members && members.profile ? `${members.profile}` : Profile}
            />
          </div>
          <div className="col-8" style={{ marginLeft: '30px' }}>
            <div
              className="text-lowercase"
            >
              {/* <div className="row">
                {members.firstName + ' ' + members.lastName}
              </div> */}
              {members.firstName && members.lastName && (
                <div className="row">
                  {members.firstName + ' ' + members.lastName}
                </div>
              )}
              <div className="row">
                {members.recipientId}
              </div>
            </div>
          </div>
          <div className="col-1">
            <FormControl variant="standard" style={{ paddingTop: '2px' }}>
              <Select value={members.access} name="access" label="Access" onChange={(e) => handleAccessChange(e, members.recipientId)} disableUnderline>
                <MenuItem value="CAN_MODIFY">Editor</MenuItem>
                <ListSubheader>Can edit but not share with others.</ListSubheader>
                <MenuItem value="CAN_VIEW">Viewer</MenuItem>
                <ListSubheader>Cannot edit or share with others.</ListSubheader>
              </Select>
            </FormControl>
          </div>
          <div className="col-1">
            <LoadingBtn prog={prog} styleprop={classes.buttonStyle} onclick={deleteUserInvite} name="Delete" functiondata={members.recipientId} />
          </div>
        </div>
      ))}
    </div>
  );

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div className={classes.mainCard}>
      <h1> Client Settings </h1>
      <Card sx={{ width: '100%' }}>
        <CardContent style={{ padding: '50px 50px 50px 50px' }}>
          <div className="row">
          {/* {allBoards && allBoards.boards && (
            <div className="col-6">
              <TextField
                variant="standard"
                style={{ paddingBottom: '15px', width: '100%' }}
                onChange={() => setBoardName(event.target.value)}
                required
                label="Client name"
                value={boardName}
              />
              <hr />
              <Button style={{ width: '100%', backgroundColor: '#220FFE' }} onClick={() => updateBoardSettings()} variant="contained">Update</Button>
            </div>
            )} */}
          
          {allBoards?.boards && (
            <div className="col-6">
              <TextField
                variant="standard"
                style={{ paddingBottom: '15px', width: '100%' }}
                onChange={(event) => setBoardName(event.target.value)}
                required
                label="Client name"
                value={boardName}
              />
              <hr />
              <Button
                style={{ width: '100%', backgroundColor: '#220FFE' }}
                onClick={updateBoardSettings}
                variant="contained"
              >
                Update Client Name
              </Button>
            </div>
          )}

            

            {/* <div className="col-sm-6">
              <img src={fileUrl !== '' && fileUrl !== null ? fileUrl : uploadProject} style={{ width: '250px', height: '250px', borderRadius: '25px' }} className="rounded-square" />
              <span className="btn">
                <>
                  <Button onClick={handleClick} style={{ textTransform: 'none' }}>
                    Change Icon
                  </Button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    onChange={(e) => onChangeFile(e)}
                  />
                </>

              </span>
            </div> */}
          </div>
        </CardContent>
      </Card>
      <div className="row" style={{ marginTop: '50px', width: '100%' }}>
        <div className="col-sm-10">
          <h3> Members </h3>
        </div>
        {allBoards && allBoards._id && (
          <div className="col-sm-2">
            <InviteBoard
              disabled={false}
              boardUserId={allBoards.userId}
              boardId={currentboardId}
              page={"Client_Setting"}
              refreshflag = {refreshflag}
              setrefreshflag = {setrefreshflag}/>
          </div>
        )}
      </div>
      <hr />
      <div className="row" style={{ width: '100%' }}>
        { (
            <>
              {RenderInvites(inviteData)}
            </>
          )}
      </div>
    </div>
  );
}

export default Board;
