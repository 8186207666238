/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Tour from 'reactour';
import addCollaborator from '../assets/undraw_collaborators_re_hont.svg';
import aiAssistantTour from '../assets/undraw_artificial_intelligence_re_enpp.svg';
import contentTour from '../assets/undraw_add_content_re_vgqa.svg';
import shareTour from '../assets/undraw_share_link_re_54rx.svg';
import endTour from '../assets/undraw_approve_qwp7.svg';
import './AppTour.css';


// Define custom styles
const tourStyles = {
  tooltip: (base) => ({
    ...base,
    backgroundColor: '#222', // Change the background color of the tooltip
    color: '#hjuyse', // Change the text color
    borderRadius: '10px', // Add rounded corners
    padding: '20px', // Increase padding
    maxWidth: '400px', // Set maximum width for the tooltip
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Add box shadow
  }),
  button: (base) => ({
    ...base,
    backgroundColor: '#4CAF50', // Customize the background color of buttons
    color: '#fff', // Change the text color of buttons
    borderRadius: '5px', // Add rounded corners to buttons
    padding: '10px 20px', // Increase padding of buttons
    border: 'none', // Remove border from buttons
    cursor: 'pointer', // Change cursor to pointer on hover
  }),
  close: (base) => ({
    ...base,
    color: '#ff0000', // Customize the color of the close button
  }),
};

const AppTour = () => {

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (!isTourOpen) {
      setCurrentStep(0);
    }
  }, [isTourOpen])

  const handleHover = (toggle) => {
    setHoveredButton(toggle);
  };

  const steps = [
    {
      selector: '.first-step',
      content: (
        <div style={{ fontWeight: 'bold' }}>
          <img className="mb-3" src={addCollaborator} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} /> 
          <p>Get started by adding a new client to begin building tailored reports.</p>
        </div>
      ),
    },
    {
      selector: '.second-step',
      content: (
        <div style={{ fontWeight: 'bold' }}>
          <img className="mb-3" src={aiAssistantTour} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} />
          <p>
            Advisory reports offer expert recommendations and analysis on key issues, providing valuable insights and actionable guidance. Use the AI Assistant to add trend analysis, business performance overviews, and personalized recommendations to your client's report.
          </p>
        </div>
      ),
    },
    {
      selector: '.third-step',
      content: (
        <div style={{ fontWeight: 'bold' }}>
          <img className="mb-3" src={contentTour} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} />
          <p>
            This is your toolkit for adding content to client reports. Use Mira to include AI-generated charts and enhance your reports with data-driven insights. Or, manually add charts and insights using the chart and text tools. Choose specific data points, apply formulas, and add notes to create customized visualizations and narratives.
          </p>
        </div>
      ),
    },
    {
      selector: '.fourth-step',
      content: (
        <div style={{ fontWeight: 'bold' }}>
          <img className="mb-3" src={shareTour} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} />
          <p>
            Ready to share your report for review or with a client? Click the share button to collaborate or distribute.
          </p>
        </div>
      ),
    },
    {
      selector: '.fifth-step',
      content: (
        <div style={{ fontWeight: 'bold' }}>
          <img className="mb-3" src={endTour} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} />
          <p>
            You've completed the tour! You can always revisit it by clicking here.
          </p>
        </div>
      ),
    },


  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "20px",
        }}
      >
        <button
          onClick={() => setIsTourOpen(true)}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          style={{
            backgroundColor: hoveredButton ? "white" : "#ff6b2b",
            border: hoveredButton
              ? "1px solid #ff6b2b"
              : "1px solid transparent",
            color: hoveredButton ? "#ff6b2b" : "white",
            transition: "0.2s ease-in-out",
            // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            width: "100px",
            height: "40px",
            boxSizing: "border-box",
            margin: "0px",
            fontFamily: [
              "-apple-system",
              "BlinkMacSystemFont",
              '"Segoe UI"',
              "Roboto",
              '"Helvetica Neue"',
              "Arial",
              "sans-serif",
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(","),
            fontWeight: 'bold'
          }}
          className="button"
        >
          Start Tour
        </button>
      </div>
      <Tour
        scrollSmooth
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        styles={{ tourStyles }}
        goToStep={currentStep}
        getCurrentStep={(step) => setCurrentStep(step)}
      />
    </>
  );
};

export default AppTour;
