/* eslint-disable */

import React from 'react';

function LoadingCard() {
    return (
        <div className="col-sm-12 mt-3">
            <div
                className="ui card mb-2"
                style={{ width: '94%', height: '100%', marginRight: 'auto', marginLeft: 'auto', }}
            >
                <div
                    className="header"
                    style={{
                        maxHeight: '70px',
                        fontWeight: 'bold',
                        paddingLeft: '20px',
                        paddingTop: '10px',
                        fontSize: '20px',
                        display: 'grid',
                    }}
                >
                    <div className="loading-row col-sm-3" />

                </div>
                <div className="content">
                    <div className="loading-row col-sm-12" style={{ height: "490px" }} />

                </div>
            </div>
        </div>
    );
}

export default LoadingCard;
