/* eslint-disable */

import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//import axios from 'axios';
import axios from '../../context/axios';
import React, { useEffect, useState } from 'react';
import Profile from '../../assets/profile.png';
import { AuthContext } from '../../context/context';
import LoadingBtn from '../Misc/LoadingBtn';
import SubscriptionPopup from './SubscriptionPopup.js';
function InviteView(props) {
  const {
    state: {
      integrations, integrationInfo, selectedIntegration, selectedBoard, allBoards, switchUser, purchasing
    }, dispatch
  } = React.useContext(AuthContext);
  const [inviteData, setInviteData] = useState([]);
  const [totalEdits, setTotalEdits] = useState(3);
  const [currentEdits, setCurrentEdits] = useState(0);
  const [prog, setProg] = React.useState(true);
  const [showSubscription, setShowSubscription] = useState(false);

  useEffect(async () => {
    // Fetch invite data from the API
    getBoards();
    // if (purchasing.planDetails.editors[0].quantity) {
    //   setTotalEdits(purchasing.planDetails.editors[0].quantity)
    // }
  }, [props.boardId]);

  useEffect(async () => {
    // Fetch invite data from the API
    if(props.inviterefresh){
      // if (purchasing.planDetails.editors[0].quantity) {
      //   setTotalEdits(purchasing.planDetails.editors[0].quantity)
      // }
      props.setinviterefresh(false);
      getBoards();
    }
  }, [props.inviterefresh]);

  const getBoards = async () => {
    await axios.get('/getInvitesBoards')
      .then(response => {
        const data = response.data.data;
        //console.log(data);
        if (data && data.length > 0) {
          const filteredData = data[0].invited.filter(invite => invite.boardId === props.boardId);

          setInviteData(filteredData);

          const modifyAccessEntries = data.flatMap(entry => entry.invited.filter(item => item.access === "CAN_MODIFY"));
          const distinctRecipientIds = [...new Set(modifyAccessEntries.map(item => item.recipientId))];
          const totalDistinctRecipientIds = distinctRecipientIds.length;
          // console.log(distinctRecipientIds);
          setCurrentEdits(totalDistinctRecipientIds)
          // props.setTotalAvailable(totalEdits - totalDistinctRecipientIds)
          // 
        }
      })
      .catch(error => {
        console.error('Error fetching invite data:', error);
      });
  }

  useEffect(() => {
    props.setTotalAvailable(totalEdits - currentEdits);
    if (currentEdits > totalEdits) {
      setShowSubscription(true);
    } else {
      setShowSubscription(false);
    }
  }, [totalEdits, currentEdits]);

  const handleAccessChange = async (event, recipientId) => {
    const newAccess = event.target.value;
    let previousAccess;
    setInviteData(prevData => {
      const invite = prevData.find(invite => invite.recipientId === recipientId);
      previousAccess = invite ? invite.access : null;
      return prevData;
    });

    if (newAccess === 'CAN_MODIFY' && previousAccess !== 'CAN_MODIFY' && currentEdits >= totalEdits) {
      setShowSubscription(true);
      return;
    } else {
      setShowSubscription(false);
    }

    if (newAccess === 'CAN_MODIFY' && previousAccess !== 'CAN_MODIFY') {
      setCurrentEdits(currentEdits + 1);
    } else if (newAccess === 'CAN_VIEW' && previousAccess === 'CAN_MODIFY') {
      setCurrentEdits(currentEdits - 1);
    }

    try {

      const response = await axios.post('/modifyUserInviteBoards', {
        recipientId,
        boardId: props.boardId,
        boardUserId: allBoards.boards[selectedBoard]._id,
        access: newAccess
      });
      console.log(allBoards.boards[selectedBoard]);

      console.log('Updated recipient data:', response.data.updatedRecipient);
      setInviteData(prevData =>
        prevData.map(invite =>
          invite.recipientId === recipientId ? { ...invite, access: newAccess } : invite
        )
      );
    } catch (error) {
      console.error('Error updating invite access:', error);
    }
  };

  const deleteUserInvite = async (event, email) => {

    setProg(false);
    const url = '/deleteUserInviteBoards';
    axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
    await axios
      .post(url, { recipientId: event, boardId: props.boardId })
      .then((res) => {
        setProg(true);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          // setMsg(err.response.data.message);
        } else {
          // setMsg(err.message);
        }
      });

    await getBoards();
    // setOpenMsg(true);
    // setProg(true);

    // await getProjects(switchUser.userName)
    //   .then((projects) => {
    //     dispatch({
    //       type: 'SET_PROJECTS',
    //       payload: projects,
    //     });
    //   });
    // Handle delete user invite logic
  };



  return (
    <div>
      <h3>Invites</h3>
      {inviteData.map(invite => (
        <div key={invite.recipientId} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ marginRight: '10px' }}>
            <img
              style={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
              }}
              src={invite && invite.profile ? `${invite.profile}` : Profile}
              alt="Profile"
            />
          </div>
          <div style={{ marginLeft: '5px' }}>
            <div className="">
              {invite.firstName && invite.lastName && (
                  <div className="row">
                    {invite.firstName + ' ' + invite.lastName}
                  </div>
              )}
              <div className="row">{invite.recipientId}</div>
            </div>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <FormControl variant="standard" style={{ paddingTop: '2px' }}>
              <Select value={invite.access} name="access" label="Access" onChange={(e) => handleAccessChange(e, invite.recipientId)}
                disabled={false} disableUnderline>
                <MenuItem value="CAN_MODIFY">Editor</MenuItem>
                <ListSubheader>Can edit but not share with others.</ListSubheader>
                <MenuItem value="CAN_VIEW">Viewer</MenuItem>
                <ListSubheader>Cannot edit or share with others.</ListSubheader>
              </Select>
            </FormControl>
          </div>
          <div>
            <LoadingBtn prog={prog} onclick={deleteUserInvite} name="Delete" functiondata={invite.recipientId} />
          </div>
        </div>
      ))}
      
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <p>{totalEdits - currentEdits} Editors Available</p>
          </div>
        </div>
      </div>
      <div class="container">
        {showSubscription && <SubscriptionPopup />}
      </div>
    </div>

  );


}

export default InviteView;
