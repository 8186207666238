/* eslint-disable */

import React from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import american_express from '../../assets/Cards/american_express.png';
import creditCard from '../../assets/Cards/credit_card.png';
import discover from '../../assets/Cards/discover.png';
import mastercard from '../../assets/Cards/mastercard.png';
import visa from '../../assets/Cards/visa.png';
import { AuthContext } from '../../context/context';
import {
  getPaymentInfo,
  manageSubscription,
  subscription,
  userInvoices,
} from '../../context/subscription-service';
import { useStyles } from './styles.js';

import { useEffect } from 'react';

function Subscription() {
  const {
    state: {
      user, billingDetails, purchasing, isAuthenticated,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const classes = useStyles();
  const [paymentPlanType, setPaymentPlanType] = React.useState('You subscribed to Paid Plan');
  const [monthly, setMonthly] = React.useState(false);
  const [yearly, setYearly] = React.useState(true);
  const [transactions, setTransactions] = React.useState([]);
  const [seats, setSeats] = React.useState('1');
  const [boards, setBoards] = React.useState('0');

  const [value, setValue] = React.useState('Annually');
  const [message, setMessage] = React.useState('$996.00 per year (17% discount)');
  const [messageBoards, setMessageBoards] = React.useState('$996.00 per year (17% discount)');

  const [card, setCard] = React.useState(creditCard);
  const [cardInfo, setCardInfo] = React.useState('');
  const [nextInvoice, setNextInvoice] = React.useState('');
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  useEffect(() => {
    // if (purchasing[0] && purchasing[0].subscriptionDetails) {
    //   let quantity = purchasing[0].subscriptionDetails.quantity
    //   setSeats(quantity)
    //   if (purchasing[0].subscriptionDetails.plan.interval === "year") {
    //     setMessage(`${formatter.format(996 * quantity)} per year (17% discount)`);
    //     setValue("Annually")
    //     setYearly(true);
    //     setMonthly(false);
    //   } else {
    //     setMessage(`${formatter.format(100 * quantity)} per month`);

    //     setValue("Monthly")
    //     setYearly(true);
    //     setMonthly(false);
    //   }

    // }

  }, [purchasing])

  formatter.format(2500); /* $2,500.00 */

  const handleChange = (event) => {
    setSeats(event.target.value);
    if (value === 'Annually') {
      setMessage(`${formatter.format(996 * event.target.value)} per year (17% discount)`);
    } else {
      setMessage(`${formatter.format(100 * event.target.value)} per month`);
    }
  };

  const handleChangeBoards = (event) => {
    setBoards(event.target.value);
    if (value === 'Annually') {
      setMessageBoards(`${formatter.format(996 * event.target.value)} per year (17% discount)`);
    } else {
      setMessageBoards(`${formatter.format(100 * event.target.value)} per month`);
    }
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === 'Annually') {
      setMessage(`${formatter.format(996 * seats)} per year (17% discount)`);
      setYearly(true);
      setMonthly(false);
    } else {
      setMessage(`${formatter.format(100 * seats)} per month`);
      setMessageBoards(`${formatter.format(100 * seats)} per month`);
      setMonthly(true);
      setYearly(false);
    }
  };

  const PurchaseSubscription = async (e) => {
    e.preventDefault();
    const subscriptionType = monthly ? 'INTRO_MONTHLY' : 'INTRO_YEARLY';
    const { protocol, hostname, port } = window.location;
    const URL = `${protocol}//${hostname}${port ? ':' + port : ''}`;
    try {
      const sub = await subscription(false, seats, boards, subscriptionType, purchasing[0].customerDetails.id, URL);
      if (sub.status === 200 || sub.status === 201) {
        window.location.href = sub.data.url;
      }
    } catch (err) {
      //
    }
  };

  const manageSubscriptionService = async (e) => {
    e.preventDefault();
    try {
      const sub = await manageSubscription(purchasing[0].customerDetails.id);
      if (sub.status === 200 || sub.status === 201) {
        window.location.href = sub.data.url;
      }
    } catch (err) {
      //
    }
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
    async function getUserInvoices() {
      const transactions = await userInvoices(purchasing[0].customerDetails.id);
      if (transactions.data.data.length > 0) {
        let transactionsData = [];
        transactions.data.data.forEach((transaction) => {

          let amount = '' + transaction.amount;
          let left = amount.substring(0, amount.length - 2);
          let right = amount.substring(amount.length - 2);
          transactionsData.push(
            {
              date: new Date(transaction.created * 1000).toLocaleDateString(),
              type: transaction.description,
              amount: `$${left}.${right}`,
            },
          );
        });
        setTransactions(transactionsData);
      }

      const paymentInfo = await getPaymentInfo(purchasing[0].customerDetails.id).then((data) => {
        if (data.data.cardInfo) {
          let { cardInfo } = data.data;
          let { brand } = cardInfo;
          if (brand === 'visa') {
            setCard(visa);
          } else if (brand === 'mastercard') {
            setCard(mastercard);
          } else if (brand === 'american express') {
            setCard(american_express);
          } else if (brand === 'discover') {
            setCard(discover);
          }
          if (cardInfo !== {}) {
            let cardString = `**** **** **** ${cardInfo.last4} | EXP: ${cardInfo.exp_month}/${cardInfo.exp_year}`;

          }
          let cardString = `**** **** **** ****`;
s
          setCardInfo(cardString);
        }

        if (data.data.nextBillingDate) {
          let { nextBillingDate } = data.data;
          let nextInvoiceDate = new Date(nextBillingDate * 1000);
          const month = nextInvoiceDate.toLocaleString('default', { month: 'long' });
          let nextInvoiceString = `Your next billing date is ${month}  ${nextInvoiceDate.getDate()}, ${nextInvoiceDate.getUTCFullYear()} `;
          setNextInvoice(nextInvoiceString);
        }


      }).catch(() => {

      });
    }
    getUserInvoices();
  }, []);

  return (
    <div className={classes.mainCard}>
      <h1>
        {' '}
        {/* {paymentPlanType} */}
        {purchasing && purchasing.planDetails && purchasing.planDetails.clients &&
                            purchasing.planDetails.clients[0] && purchasing.planDetails.clients[0].quantity
                            ?
                            'You subscribed to Paid Plan'
                            :
                            'You subscribed to Free Plan'
                          }
        {' '}
      </h1>
      <form>
        <div className="mt-5 mb-5">
          <div className="row">
            <div className={classes.creditCard}>
              <div className="row">
                <div className="col-10">
                  {card
                    ? (
                      <div
                        className="row"
                        style={{
                          display: 'inline',
                          alignItems: 'center',
                        }}
                      >
                        <img src={card} style={{ width: 'inherit', verticalAlign: 'middle', display: 'inline' }} alt="card" />
                        <span style={{ verticalAlign: 'middle', display: 'inline', fontSize: '20px' }}>{cardInfo}</span>
                      </div>
                    )
                    : <div> No payment method on file</div>}

                  {nextInvoice !== ''
                    ? (
                      <div>
                        {nextInvoice}
                        {' '}
                      </div>
                    )
                    : <div> No Upcoming invoices </div>}
                </div>
                <div className="col-2">
                  <button className={classes.buttonStyle} onClick={manageSubscriptionService} style={{ color: '#220FFE' }}>
                    Manage Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-5">
          <div className="row">
            <div className={classes.teamPlanCard}>
              <h2>Upgrade Client Counts and Editor Seats.</h2>
              <p>You can add more client counts and editor seats to your account</p>
              <div className={classes.teamPlan}>
                <p>How many client counts and editor seats do you want billed in your account?</p>
                <FormControl>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Annually"
                      control={<Radio checked={yearly} size="small" />}
                      label={(
                        <Typography sx={{ fontSize: 12 }}>
                          Billed annually (Save 17%)
                        </Typography>
                      )}
                    />
                    <FormControlLabel
                      value="Monthly"
                      control={<Radio checked={monthly} size="small" />}
                      label={(
                        <Typography sx={{ fontSize: 12 }}>
                          Billed Monthly
                        </Typography>
                      )}
                    />
                  </RadioGroup>
                </FormControl>
                <div className="mt-5">
                  <div className="row">
                    <div className="col-5">
                      <Box sx={{ maxWidth: 200 }}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-label">Editor Seats</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={seats}
                            label="seats"
                            onChange={handleChange}
                          >
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={13}>13</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={17}>17</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={19}>19</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-label">Client Counts</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={boards}
                            label="boards"
                            onChange={handleChangeBoards}
                          >
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={13}>13</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={17}>17</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={19}>19</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className="col-7" style={{ lineHeight: '50px' }}>
                      <p>{message}</p>
                      <p>{messageBoards}</p>

                      {' '}
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary btn-padd"
                onClick={PurchaseSubscription}
                style={{
                  marginTop: '30px', width: '100%', height: '46px', background: 'black',
                }}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="row mt-5 pt-5">
        <h2> Payment History </h2>
        <div className={classes.table}>
          {transactions.length > 0
            ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell size="small">Transaction date</TableCell>
                      <TableCell size="small">Type</TableCell>
                      <TableCell size="small">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" size="small">
                          {row.date}
                        </TableCell>
                        <TableCell size="small">{row.type}</TableCell>
                        <TableCell size="small">{row.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
            : (
              <div>
                You have no payment history
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default Subscription;
