/* eslint-disable */
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { AuthContext } from '../../context/context';


export default function HiringEdit(props) {
  const useStyles = makeStyles(() => ({
    paper: { minWidth: '400px' },
  }));
  const classes = useStyles();
  const {
    state: {
      switchUser, currentScenario, projects, selectedProject, selectedScenario, revenues,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [hiringGrowth, setHiringGrowth] = React.useState(0);

  const updateGAChurnValues = async () => {

    // let scenarioId = projects[selectedProject].scenarios[selectedScenario]._id;
    // let update = await updateGAChurn(scenarioId, grateYearly, grateQuarterly, grateMonthly, crateYearly, crateQuarterly, crateMonthly);
    // 
    // const revenues = await getRevenue(switchUser.userName, currentScenario);
    // dispatch({
    //   type: 'SET_REVENUE',
    //   payload: revenues,
    // });
    // const inputs = await getInputs(switchUser.userName, currentScenario);
    // 
    // dispatch({
    //   type: 'SET_INPUTS',
    //   payload: inputs,
    // });

    props.handleCloseBackDrop();
  };




  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.backDropOpen}
        onClose={props.handleCloseBackDrop}
      >
        <Dialog classes={{ paper: classes.paper }} onClose={props.handleCloseBackDrop} open={props.backDropOpen}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent style={{ padding: '75px 75px 75px 75px' }}>
              <h2 style={{
                paddingBottom: '15px', width: '100%', color: '#55616E',
              }}
              >
                {' '}
                Scenario Name -
                {projects[selectedProject].scenarios[selectedScenario].scenarioName}
              </h2>
              <h3 style={{
                paddingBottom: '15px', width: '100%', color: '#55616E',
              }}
              >
                {' '}
                <TrendingUpIcon />
                {' '}
                Projected Salary Increase Variables
                {' '}
              </h3>
              <div className="row">
                <TextField
                  suffix="%"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  style={{ paddingBottom: '15px', width: '100%' }}
                  onChange={() => setHiringGrowth(event.target.value, 'Yearly')}
                  label="Yearly Percent Growth"
                  value={hiringGrowth}
                  variant="standard"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </div>
              <Button
                style={{
                  paddingTop: '10px', paddingBottom: '10px', marginTop: '50px', width: '100%', background: '#79A1DD',
                }}
                onClick={() => updateGAChurnValues()}
                variant="contained"
              >
                Save
              </Button>
            </CardContent>
          </Card>
        </Dialog>
      </Backdrop>
    </>
  );
}
