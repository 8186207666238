export const formatMessageContent = (content) => {
// Example: Replace numeric values with bold numbers, commas, and two decimal places
  content = content.replace(/(\d+(\.\d+)?)/g, (match) => {
    const number = parseFloat(match).toLocaleString(undefined);
    return `<b>${number}</b>`;
  });

  // Example: Replace dollar amounts with formatted dollar amounts
  content = content.replace(/\$\s*(\d+(\.\d+)?)/g, (match) => {
    const number = parseFloat(match.replace('$', '').trim()).toFixed(2);
    return `<b>$$${number}</b>`;
  });

  // Example: Convert asterisks to bullet points
  content = content.replace(/\*\s/g, '<ul><li>');
  content += '</li></ul>';

  return content;
};
