/* eslint-disable */

import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import InputBase from '@material-ui/core/InputBase';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { makeStyles, withStyles } from '@material-ui/styles';
import TextField from '@mui/material/TextField';
import React, { useState, useContext, useEffect } from 'react';
import axios from '../../context/axios';
import Alerts from '../Misc/Alerts';
import { AuthContext } from '../../context/context';

import { getTeams } from '../../context/team-service';
import InviteView from './InviteView';
import Profile from '../../assets/profile.png';
import { Icon } from "semantic-ui-react";

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
  },
  buttonStyle: {
    backgroundColor: 'transparent', border: 'none', whiteSpace: 'normal', overflow: 'visible', textAlign: 'left',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
  },
}))(MuiDialogContent);

const MStyles = makeStyles({
  btn: {
    color: '#aaa',
    margin: '0 0 0 0',

    '& svg': {
      fontSize: '2rem',
    },
  },
  formdiv: {
    display: 'flex',
    justifyContent: 'center',
  },

  nestdiv: {
    border: '2px solid #5abcdc',
    borderRadius: '10px',
    padding: '.5rem .5rem',
    '& input': {
      margin: '0 5rem 0 1rem',
    },
  },
  invite_btn: {
    width: '15%',
    backgroundColor: '#220FFE',
    fontWeight: 'bold',
    fontFamily: 'cerebri sans',
    padding: '.5rem 3.5rem',
    borderRadius: '1rem',
    fontSize: '1rem',
    margin: '0 1rem',
  },

  datadiv: {
    margin: '2.5rem 1rem',
  },
  buttonStyle: {
    backgroundColor: 'transparent', border: 'none', whiteSpace: 'normal', overflow: 'visible', textAlign: 'left', color: '#220FFE',
  },
});

const ShareStyles = makeStyles({
  sharedata_container: {
    padding: '0 2rem',
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
      margin: '0 3rem 0 1rem',
    },
  },
});

const ShareDiv = (props) => {
  const classes = ShareStyles();
  return (
    <>
      <div style={{ margin: '1rem 0' }}>
        <div className={classes.sharedata_container}>
          <InputBase fullWidth value={props.data.email} />

          <Select
            value={props.data.access}
            name={props.data.email}
            onChange={props.modifyUser}
            disableUnderline
          >
            <MenuItem value="CAN_MODIFY">Can Edit</MenuItem>
            <ListSubheader>Can edit but not share with others.</ListSubheader>
            <MenuItem value="CAN_VIEW">Can View</MenuItem>
            <ListSubheader>Cannot edit or share with others.</ListSubheader>
          </Select>
          <div>
            <Button onClick={() => props.deleteUser(props.data._id)}>
              <DeleteForeverOutlinedIcon />
            </Button>
          </div>
        </div>
        <Divider />
      </div>
    </>
  );
};

const InviteBoard = (props) => {
  const {
    state: {
      user, allBoards, selectedBoard
    },
    dispatch,
  } = React.useContext(AuthContext);

  const { state } = React.useContext(AuthContext);
  const { companyName } = state;
  const classes = MStyles();
  const [open, setOpen] = React.useState(false);

  const [successmsg, setsuccessMsg] = React.useState('');
  const [errormsg, seterrorMsg] = React.useState('');
  const [opensuccessmsg, setopensuccessmsg] = React.useState(false);
  const [openerrormsg, setopenerrormsg] = React.useState(false);
  const [openMsg, setOpenMsg] = React.useState(false);
  const [prog, setProg] = React.useState(true);

  const [totalAvailable, setTotalAvailable] = useState(0)

  const [reload, setReload] = useState(true);
  const [emails, setemails] = useState([]);
  const [access, setaccess] = useState('CAN_VIEW');
  const [enteredData, setEnteredData] = useState('');
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [inviterefresh, setinviterefresh] = React.useState(false);
  const fetchTeam = async () => {
    // const teamMembersResponse = await getTeams();
    // setTeamMembers(teamMembersResponse.data.data[0].teamMembers);
    try {
      const teamMembersResponse = await getTeams();
  
      if (teamMembersResponse?.data?.data?.[0]) {
        const { teamMembers } = teamMembersResponse.data.data[0];
        setTeamMembers(teamMembers || []);
      } else {
        setTeamMembers([]);
      }
    } catch (error) {
      console.error('Failed to fetch teams:', error);
      // Optionally, set error state here
    }
  }

  React.useEffect(async () => {
    fetchTeam();
    // console.log(teamMembers);
  }, []);

  // useEffect(async () => {
  //   const url = '/inviteUserBoards';

  //   axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
  //   await axios
  //     .post(url)
  //     .then((res) => {


  //       // setInvitesData([...res.data.data[0].invited]);
  //     })
  //     .catch((err) => {

  //     });
  // }, [reload]);

  const handleClickOpen = () => {
    setsuccessMsg('');
    seterrorMsg('');
    //setOpenMsg(false);
    setopenerrormsg(false);
    setopensuccessmsg(false);
    setOpen(true);
    setemails([]);
    setaccess('CAN_VIEW');
  };
  const handleClose = () => {
    setsuccessMsg('');
    seterrorMsg('');
    //setOpenMsg(false);
    setopenerrormsg(false);
    setopensuccessmsg(false);
    setOpen(false);
    if(props.page && props.page==="Client_Setting" && !props.refreshflag) {props.setrefreshflag(true);}
  };

  const removeEmail = (removeindex) => {
    setemails(emails.filter((_, index) => index !== removeindex));
  }

  const enteringData = (data) => {
    setEnteredData(data.target.value);
  };
  
  const accesschange = (e) => {
    setaccess(e.target.value);
  };

  const handleAddEmail = (e) => {
    if(e.key==='Enter' || e.key===',' || e.key===' '){
      e.preventDefault();
      if(enteredData.trim()){
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!emailPattern.test(enteredData.trim())){
          seterrorMsg(enteredData.trim()+": please provide correct email address.");
          setopenerrormsg(true);
        }else{
          setemails([...emails, enteredData.trim()]);
          setopenerrormsg(false);
          seterrorMsg('');
          setEnteredData('');
        }
      }
    }
  };

  const inviteUser = async () => {
    setProg(false);
    setsuccessMsg('');
    seterrorMsg('');
    const url = '/inviteUserBoards';
    axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;

    emails.map(async (email, i)=>{
      await axios
        .post(url, { boardUserId: props.boardUserId,
          clientId: allBoards.boards[selectedBoard]._id,
          boardId: props.boardId,
          email: email,
          firstName: user.firstName,
          lastName: user.lastName,
          boardName: allBoards.boards[selectedBoard].boardName,
          companyName: state.companyName,
          access: access })
        .then((res) => {
          setsuccessMsg((prevMsg) => prevMsg+"\n"+email+": "+res.data.message);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            seterrorMsg((prevMsg) => prevMsg+"\n"+email+": "+err.response.data.message);
          } else {
            seterrorMsg((prevMsg) => prevMsg+"\n"+email+": "+err.message);
          }
        });
    });

    if(enteredData.trim()){
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailPattern.test(enteredData.trim())){
        seterrorMsg((prevMsg) => prevMsg+"\n"+enteredData.trim()+": please provide correct email address.");
      }else{
        const email = enteredData.trim();
        await axios
          .post(url, { boardUserId: props.boardUserId,
            clientId: allBoards.boards[selectedBoard]._id,
            boardId: props.boardId,
            email: email,
            firstName: user.firstName,
            lastName: user.lastName,
            boardName: allBoards.boards[selectedBoard].boardName,
            companyName: state.companyName,
            access: access })
          .then((res) => {
            setsuccessMsg((prevMsg) => prevMsg+"\n"+email+": "+res.data.message);
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              seterrorMsg((prevMsg) => prevMsg+"\n"+email+": "+err.response.data.message);
            } else {
              seterrorMsg((prevMsg) => prevMsg+"\n"+email+": "+err.message);
            }
          });
        setEnteredData('');
      }
    }
    
    //setOpenMsg(true);
    setopensuccessmsg(true);
    setopenerrormsg(true);
    setProg(true);
    setinviterefresh(true);

    setemails([]);
    setaccess('CAN_VIEW');
  };

  const RenderTeam = (teamMembers) => (
    <div>
      {teamMembers.map((members, i) => (
        <div
          className="row"
          style={{
            margin: 0,
          }}
        >
          <div className="col-1">
            <img
              style={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                marginRight: '2.5rem',
              }}
              src={members && members.profile ? `${members.profile}` : Profile}
            />
          </div>
          <div className="col-8" style={{ marginLeft: '30px' }}>
            <button
              onClick={() => { setemails([...emails, members.email]); }}
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
                margin: 0,
                color: 'inherit',
                font: 'inherit',
                textAlign: 'left',
                cursor: 'pointer',
              }}
            >
              <div className="">
                {members.firstName && members.lastName && (
                  <div className="row">
                    {members.firstName + ' ' + members.lastName}
                  </div>
                )}
                <div className="row">
                  {members.email}
                </div>
              </div>
            </button>
            {/* <div
              className="text-lowercase"
            >
              {members.firstName && members.lastName && (
                <div className="row">
                  {members.firstName + ' ' + members.lastName}
                </div>
              )}
              <div className="row">
                {members.email}
              </div>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {props.page && props.page === "Client_Performance" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className={`ui icon button ${
              props.disabled && "disabled"
            } fourth-step`}
            onClick={handleClickOpen}
            style={{ background: "rgb(255, 255, 255)", borderRadius: 0 }}
          >
            <Icon name="share alternate" />
            <span className="ml-2">Share</span>
          </button>
        </div>
      )}
      {props.page && props.page === "Client_Setting" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className={`ui icon button ${props.disabled && "disabled"}`}
            onClick={handleClickOpen}
            style={{ background: "rgb(255, 255, 255)", borderRadius: 0 }}
          >
            Share With Another User
          </button>
        </div>
      )}

      <Dialog
        variant="standard"
        onClose={handleClose}
        open={open}
        maxWidth="xl"
        minWidth="xl"
        PaperProps={{ style: { width: "600px" } }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Share Client
        </DialogTitle>
        {successmsg && successmsg.length > 0 && (
          <Alerts
            msg={successmsg}
            type={"success"}
            openMsg={opensuccessmsg}
            setOpenMsg={setopensuccessmsg}
          />
        )}
        {errormsg && errormsg.length > 0 && (
          <Alerts
            msg={errormsg}
            type={"error"}
            openMsg={openerrormsg}
            setOpenMsg={setopenerrormsg}
          />
        )}

        <DialogContent dividers>
          <div
            label="Email"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                flex: 1,
                width: "70%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "5px",
              }}
            >
              {emails.map((email, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    borderRadius: "16px",
                    backgroundColor: "#add8e6",
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                >
                  <span>{email}</span>
                  <span
                    onClick={() => removeEmail(index)}
                    style={{
                      marginLeft: "8px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      color: "#ff0000",
                    }}
                  >
                    &times;
                  </span>
                </div>
              ))}
              <input
                type="text"
                value={enteredData}
                onChange={enteringData}
                onKeyDown={handleAddEmail}
                placeholder="Enter emails"
                style={{
                  border: "none",
                  outline: "none",
                  flex: "1",
                  minWidth: "200px",
                }}
              />
            </div>
            <Select
              style={{ marginTop: "10px", marginLeft: "20px" }}
              variant="standard"
              value={access}
              label="Access"
              onChange={accesschange}
              disableUnderline
            >
              {/* <MenuItem value="CAN_MODIFY" disabled={totalAvailable > 2} >Can Edit</MenuItem> */}
              {totalAvailable > 0 && (
                <MenuItem value="CAN_MODIFY">Can Edit</MenuItem>
              )}
              {totalAvailable > 0 && (
                <ListSubheader>
                  Can edit but not share with others
                </ListSubheader>
              )}
              <MenuItem value="CAN_VIEW">Can View</MenuItem>
              <ListSubheader>Cannot edit or share with others.</ListSubheader>
            </Select>
            <button
              className="btn"
              onClick={inviteUser}
              style={{
                backgroundColor: "black",
                color: "white",
              }}
            >
              Invite
            </button>
          </div>
          {/* <TextField
            variant="standard"
            placeholder="Enter Email"
            label="Email"
            value={''}
            name="email"
            onChange={enteringData}
            style={{ width: "70%" }}
          /> */}
        </DialogContent>
        {teamMembers && teamMembers.length > 0 && (
          <DialogContent dividers>
            <div>
              <h3>Team Members</h3>
              {RenderTeam(teamMembers)}
            </div>
          </DialogContent>
        )}
        <DialogContent dividers>
          <InviteView
            setTotalAvailable={setTotalAvailable}
            boardId={props.boardId}
            disabled={!props.disabled}
            inviterefresh={inviterefresh}
            setinviterefresh={setinviterefresh}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InviteBoard;
