/* eslint-disable */
import { Card } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Chart } from "react-google-charts";
import { Navigate } from 'react-router-dom';
import { DatesRangeInput } from "semantic-ui-calendar-react-17";
import { AuthContext } from '../../context/context';
import { getBalanceSheet, getCashFlow, getIncomeStatement, validateToken } from '../../context/intuit-service';
import Alerts from '../../views/Misc/Alerts';
import { getDateRange } from '../ClientPerformance/helpers';
import IntegrationsButton from '../Projects/IntegrationsButton';
import { CardContentNoPadding } from '../Stepper/CustomStyles';
import { calculatePercentage, combineArrays, createDateRangeStrings, parseDate, processArray } from './Helpers/QuickbooksParser';
import { options } from './Helpers/Sankey';
import LoadingTable from './LoadingTable';
import TopFiveExpenses from './TopFiveExpenses';
const Reports = () => {
  const {
    state: {
      integrations, integrationInfo, selectedIntegration,
    }, dispatch
  } = React.useContext(AuthContext);
  ////
  const [balanceSheet, setBalanceSheet] = React.useState([null]);
  const [cashFlow, setCashFlow] = React.useState(null);
  const [incomeStatement, setIncomeStatement] = React.useState(null);

  const [balanceSheet2, setBalanceSheet2] = React.useState([]);
  const [cashFlow2, setCashFlow2] = React.useState([]);
  const [incomeStatement2, setIncomeStatement2] = React.useState([]);

  const [data, setData] = React.useState([]);
  const [sankeyData, setSankeyData] = React.useState([]);
  const [data2, setData2] = React.useState([]);
  const [sankeyData2, setSankeyData2] = React.useState([]);

  ///
  const [balanceSheetTable, setBalanceSheetTable] = React.useState(null);
  const [cashFlowTable, setCashFlowTable] = React.useState(null);
  const [incomeStatementTable, setIncomeStatementTable] = React.useState(null);
  const [balanceSheetTable2, setBalanceSheetTable2] = React.useState(null);
  const [cashFlowTable2, setCashFlowTable2] = React.useState(null);
  const [incomeStatementTable2, setIncomeStatementTable2] = React.useState(null);

  const [tableHeaders, setTableHeaders] = React.useState([]);
  const [tableHeaders2, setTableHeaders2] = React.useState([]);

  const [dataTable, setDataTable] = React.useState([]);
  const [dataTableCompare, setDataTableCompare] = React.useState([]);

  const [date, setDate] = React.useState(getDateRange());
  const [date2, setDate2] = React.useState(getDateRange());

  const [isChecked, setIsChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [toggleValue, setToggleValue] = React.useState("$");
  const [currentView, setCurrentView] = React.useState("Income Statement");
  const [loadingIncome, setLoadingIncome] = React.useState(true);
  const [loadingBalance, setLoadingBalance] = React.useState(true);
  const [loadingCashflow, setLoadingCashflow] = React.useState(true);

  const handleCheckboxChange = (event) => { setIsChecked(event.target.checked); }
  const handleDateChange = (event, { name, value }) => {
    setDate(value);
  };
  const handleDateChange2 = (event, { name, value }) => {
    setDate2(value);
  };

  function toggleSankeyView(value) {
    if (value === "%") {
      let sankeyDataValue = calculatePercentage(data);
      let sankeyDataValue2 = calculatePercentage(data2);
      setToggleValue("%");
      setSankeyData(sankeyDataValue);
      setSankeyData2(sankeyDataValue2);
    } else {
      setToggleValue("$");
      setSankeyData(data);
      setSankeyData2(data2);
    }
  }

  React.useEffect(async () => {
    if (integrations && integrations.quickbooks && integrations.quickbooks.access
      && integrations.quickbooks.access.token && integrations.quickbooks.access.token.access_token) {
      await getLiveData();
    }
  }, [integrations])

  const [msg, setMsg] = React.useState("")
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);

  React.useEffect(async () => {
    if (integrationInfo) {
      setLoading(true);
      let data = {
        realmId: integrationInfo.integrations[selectedIntegration].realmId,
        refresh_token: integrationInfo.integrations[selectedIntegration].refresh_token,
        access_token: ""
      }
      if (integrations.quickbooks.access.token) {
        data.access_token = integrations.quickbooks.access.token.access_token
      }
      await validateToken(data).then((validatedToken) => {
        if (validatedToken.status === 201) {
          let token = validatedToken.data.token;
          let access = {
            code: "",
            realmId: integrationInfo.integrations[selectedIntegration].realmId,
            state: "",
            token: token,
          }
          dispatch({
            type: 'SET_QUICKBOOK_AUTH',
            payload: access,
          });
        }
        setMsg("");
        setType("")
        setOpenMsg(false)
      }).catch(() => {
        setLoading(false);
        setMsg("Please Re-Sync your integrations");
        setType("error")
        setOpenMsg(true)
        setLoading(false)
      })

    }
  }, [selectedIntegration]);

  function setCurrentViewPage(value) {
    setCurrentView(value);
    setToggleValue("$")
    if (value === "Income Statement") {
      setData(incomeStatement);
      setSankeyData(incomeStatement);
      setData2(incomeStatement2);
      setSankeyData2(incomeStatement2);
      setDataTable(incomeStatementTable)
      setDataTableCompare(incomeStatementTable2)
    } else if (value === "Balance Sheet") {
      setData(balanceSheet);
      setSankeyData(balanceSheet);
      setData2(balanceSheet2);
      setSankeyData2(balanceSheet2);
      setDataTable(balanceSheetTable)
      setDataTableCompare(balanceSheetTable2)
    } else if (value === "Cash Flow") {
      setData(cashFlow);
      setSankeyData(cashFlow);
      setData2(cashFlow2);
      setSankeyData2(cashFlow2);
      setDataTable(cashFlowTable)
      setDataTableCompare(cashFlowTable2)
    }
  }

  async function getLiveData() {
    try {
      setToggleValue("$");
      let query = parseDate(date);
      let data = {
        realmId: integrations.quickbooks.access.realmId,
        access_token: integrations.quickbooks.access.token.access_token,
        refresh_token: integrations.quickbooks.access.token.refresh_token,
        singleQuery: query,
        query: [query],
        compare: true
      }
      let ranges = [parseDate(date), parseDate(date2)];
      if (!isChecked) {
        let [startDateString, endDateString] = date.split(" - ");
        ranges = createDateRangeStrings(startDateString, endDateString);
      }
      let months = [];
      months = ranges.map(dateRangeStr => {
        let [, startDateStr, endDateStr] = dateRangeStr.match(/start_date=(.*)&end_date=(.*)/) || [];
        let startDate = new Date(startDateStr);
        let endDate = new Date(endDateStr);
        return endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
      });
      months.unshift("Title");
      setTableHeaders(months);
      if (isChecked) {
        data.compare = true;
        setLoadingIncome(true)
        setLoadingBalance(true);
        setLoadingCashflow(true)
        data.query = [ranges[0]];
        let IncomeStatement = await getIncomeStatement(data);
        data.query = [ranges[1]];
        let IncomeStatement2 = await getIncomeStatement(data);
        setIncomeStatement(IncomeStatement.data.data);
        setIncomeStatement2(IncomeStatement2.data.data)
        let combinedIncome = processArray(combineArrays(IncomeStatement.data.table, IncomeStatement2.data.table, 1));
        setIncomeStatementTable2(combinedIncome);
        if (currentView === "Income Statement") {
          setDataTableCompare(combinedIncome)
          setLoadingIncome(false)
        }
        ////
        data.query = [ranges[0]];
        let BalanceSheet = await getBalanceSheet(data);
        data.query = [ranges[1]];
        let BalanceSheet2 = await getBalanceSheet(data);
        setBalanceSheet(BalanceSheet.data.data);
        setBalanceSheet2(BalanceSheet2.data.data)
        let combinedBalance = processArray(combineArrays(BalanceSheet.data.table, BalanceSheet2.data.table, 1));
        setBalanceSheetTable2(combinedBalance);
        if (currentView === "Balance Sheet") {
          setDataTableCompare(combinedBalance)
          setLoadingBalance(false);
        }
        //
        data.query = [ranges[0]];
        let CashFlow = await getCashFlow(data);
        data.query = [ranges[1]];
        let CashFlow2 = await getCashFlow(data);
        setCashFlow(CashFlow.data.data);
        setCashFlow2(CashFlow2.data.data)
        let combinedCashflow = processArray(combineArrays(CashFlow.data.table, CashFlow2.data.table, 1));
        setCashFlowTable2(combinedCashflow);
        if (currentView === "Cash Flow") {
          setDataTableCompare(combinedCashflow)
          setLoadingCashflow(false)
        }
        setTableHeaders2(["Title", date, date2]);
      } else {
        data.query = ranges;
        setLoading(true);
        setLoadingIncome(true)
        setLoadingBalance(true);
        setLoadingCashflow(true)
        try {
          const [incomeStatementResponse, balanceSheetResponse, cashFlowResponse] = await Promise.all([
            getIncomeStatement(data),
            getBalanceSheet(data),
            getCashFlow(data)
          ]);

          const IncomeStatement = incomeStatementResponse.data;
          const BalanceSheet = balanceSheetResponse.data;
          const CashFlow = cashFlowResponse.data;

          setIncomeStatement(IncomeStatement.data);
          setIncomeStatementTable(IncomeStatement.table);

          setBalanceSheet(BalanceSheet.data);
          setBalanceSheetTable(BalanceSheet.table);

          setCashFlow(CashFlow.data);
          setCashFlowTable(CashFlow.table);

          if (currentView === "Income Statement") {
            setData(IncomeStatement.data);
            setSankeyData(IncomeStatement.data);
            setDataTable(IncomeStatement.table);
            setLoadingIncome(false);

          } else if (currentView === "Balance Sheet") {
            setData(BalanceSheet.data);
            setSankeyData(BalanceSheet.data);
            setDataTable(BalanceSheet.table);
            setLoadingBalance(false);

          } else if (currentView === "Cash Flow") {
            setData(CashFlow.data);
            setSankeyData(CashFlow.data);
            setDataTable(CashFlow.table);
            setLoadingCashflow(false);

          }

          setLoading(false);
        } catch (error) {
          console.error("Error while fetching data:", error);
          setLoading(false);
          setLoadingIncome(false)
          setLoadingBalance(false);
          setLoadingCashflow(false)
        }
      }
    } catch {
      setLoading(false);
      setLoadingIncome(false)
      setLoadingBalance(false);
      setLoadingCashflow(false)
    }
    setLoadingIncome(false)
    setLoadingBalance(false);
    setLoadingCashflow(false)
    setLoading(false);
  }


  return (
    <>
      {Object.keys(integrationInfo).length === 0 || integrationInfo.integrations.length < 1 ?
        <Navigate replace to='/company' /> :
        <div className="pl-5 pr-5 pt-3 pb-3">
          <div className="row">
            <Alerts msg={msg} type={type} openMsg={openMsg} setOpenMsg={setOpenMsg} button={true} />
            <div className="col-sm-2" style={{ minWidth: "235px" }}>
              <div>
                {integrationInfo &&
                  <Card
                    style={{
                      borderRadius: 15, margin: "0 auto", padding: "10px 10px 10px 10px",
                      borderBottom: "0 none !important",
                      boxShadow: "none"
                    }}>
                    <CardContentNoPadding>
                      <Typography sx={{ fontSize: 15 }} color="text.primary" gutterBottom>
                        {integrationInfo.integrations[selectedIntegration] && integrationInfo.integrations[selectedIntegration].companyName &&
                          integrationInfo.integrations[selectedIntegration].companyName}
                      </Typography>
                      <hr />
                      <Typography sx={{ fontSize: 15 }} color="text.primary" gutterBottom>
                        {integrationInfo.integrations[selectedIntegration] && integrationInfo.integrations[selectedIntegration].integrationType &&
                          integrationInfo.integrations[selectedIntegration].integrationType}
                      </Typography>
                      <IntegrationsButton />
                    </CardContentNoPadding>
                  </Card>
                }
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Card
                  style={{
                    borderRadius: 15, margin: "0 auto", padding: "10px 10px 10px 10px",
                    borderBottom: "0 none !important",
                    boxShadow: "none"
                  }}>
                  <CardContentNoPadding>
                    <Typography sx={{ fontSize: 15 }} color="text.primary" gutterBottom>
                      Top 5 Metrics
                    </Typography>
                    <Typography sx={{ fontSize: 10 }} color="text.primary" gutterBottom>
                      by Category
                    </Typography>
                    {dataTable && <TopFiveExpenses data={dataTable} loading={loading} />}
                    <div style={{ paddingLeft: "5%", paddingRight: "5%" }}></div>
                  </CardContentNoPadding>
                </Card>
              </div>
            </div>
            <div className="col-sm-10">
              <div className="row">
                <div className="ui secondary pointing menu" style={{ width: "370px" }}>
                  <a className={currentView === "Income Statement" ? "active item" : "item"} onClick={() => setCurrentViewPage("Income Statement")}>
                    Income Statement
                  </a>
                  <a className={currentView === "Balance Sheet" ? "active item" : "item"} onClick={() => setCurrentViewPage("Balance Sheet")}>
                    Balance Sheet
                  </a>
                  <a className={currentView === "Cash Flow" ? "active item" : "item"} onClick={() => setCurrentViewPage("Cash Flow")}>
                    Cash Flow
                  </a>
                </div>
              </div>
              <h2 style={{ color: 'rgba(0, 0, 0)', paddingLeft: "10px" }}>Condensed Consolidated Statements of Operations</h2>
              <div className="row mb-4" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr' }}>
                <div class="ui toggle checkbox mt-3">
                  <input type="checkbox" name="public" checked={isChecked} onChange={handleCheckboxChange} />
                  <label>Compare With</label>
                </div>
                <DatesRangeInput name="datesRange" placeholder="From - To" iconPosition="left" value={date} dateFormat="YYYY-MM-DD" onChange={handleDateChange} style={{ width: "250px" }}
                />
                {isChecked &&
                  <DatesRangeInput name="datesRange" placeholder="From - To" iconPosition="left" value={date2} dateFormat="YYYY-MM-DD" onChange={handleDateChange2} style={{ width: "250px" }}
                  />
                }
                <button className="ui primary button mr-3" style={{ backgroundColor: "Black", width: "100px", height: "35px" }} onClick={() => getLiveData()}>
                  Apply
                </button>
              </div>
              <div>
                {loading ?
                  // Show loading Sankey-like chart while data is loading
                  <div className="loading-sankey-chart">
                    <div className="loading-node" />
                    <div className="loading-node" />
                    <div className="loading-node" />
                    <div className="loading-link" />
                    <div className="loading-link" />
                    <div className="loading-link" />
                  </div>
                  :
                  <>
                    {data && data.length < 2 && data2 && data2.length < 2 ?
                      <div style={{ width: "100%", height: "300px", background: 'white', border: '1px solid #d3d3d3', borderRadius: '15px', padding: '30px 50px 0px 50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{ textAlign: "center" }}>Select a larger date range</p>
                      </div>


                      :
                      <>
                        {!isChecked ?
                          <div style={{
                            background: 'white', width: '100%', height: '85%', border: ' 1px transparent #000000', borderRadius: '15px', padding: '30px 50px 30px 50px',
                          }}>
                            <div class="ui buttons pb-3">
                              <div class="ui button" style={{ color: (toggleValue === "$") ? "white" : "black", backgroundColor: (toggleValue === "$") ? "#220FFE" : "" }}
                                onClick={() => toggleSankeyView("$")}>$</div>
                              <div class="ui button" style={{ color: (toggleValue === "%") ? "white" : "black", backgroundColor: (toggleValue === "%") ? "#220FFE" : "" }}
                                onClick={() => toggleSankeyView("%")}>%</div>
                            </div>
                            <div>
                              <div className='row pt-3'
                              >
                                <h1>
                                  <>
                                    {sankeyData && sankeyData.length > 2 &&
                                      <Chart chartType="Sankey" width="100%" height="600px" data={sankeyData} options={options} />
                                    }
                                  </>
                                </h1>
                              </div>
                            </div>
                          </div>
                          :
                          <>
                            <div style={{ background: 'white', width: '100%', height: '85%', border: ' 1px transparent #000000', borderRadius: '15px', padding: '30px 50px 30px 50px', }}>
                              <div class="ui buttons pb-3">
                                <div class="ui button" style={{ color: (toggleValue === "$") ? "white" : "black", backgroundColor: (toggleValue === "$") ? "#220FFE" : "" }}
                                  onClick={() => toggleSankeyView("$")}>$</div>
                                <div class="ui button" style={{ color: (toggleValue === "%") ? "white" : "black", backgroundColor: (toggleValue === "%") ? "#220FFE" : "" }}
                                  onClick={() => toggleSankeyView("%")}>%</div>
                              </div>
                              <div>
                                <div className='row pt-3'>
                                  <div className='col-sm-6 pt-3'>
                                    <>
                                      {sankeyData && sankeyData.length > 2 &&
                                        <Chart chartType="Sankey" width="100%" height="600px" data={sankeyData} options={options} />
                                      }
                                    </>
                                  </div>
                                  <div className='col-sm-6 pt-3'
                                  >
                                    {sankeyData2 && sankeyData2.length > 2 &&
                                      <>
                                        <Chart chartType="Sankey" width="100%" height="600px" data={sankeyData2} options={options} />
                                      </>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          <div>
            <div className="col-sm-12">
              <div className="pt-3 pl-3 pb-3 pr-3" style={{ background: 'white', height: '100%', width: '100%', border: ' 1px transparent #000000', borderRadius: '15px', marginTop: '30px' }}>
                {!loadingIncome && dataTable && dataTable.length < 2 ?
                  <div style={{ width: "100%", height: "200px", background: 'white', border: '1px solid #d3d3d3', borderRadius: '15px', padding: '30px 50px 0px 50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ textAlign: "center" }}>Select a larger date range</p>
                  </div> :
                  <>
                    <div className="pt-3 pl-3 pb-3 pr-3" style={{ background: 'white', height: '100%', width: '100%', border: ' 1px transparent #000000', borderRadius: '15px', marginTop: '30px', overflowX: "scroll" }}>

                      {currentView === "Income Statement" && (
                        <LoadingTable
                          loading={loadingIncome}
                          currentView={currentView}
                          data={dataTable}
                          headers={tableHeaders}
                          isChecked={isChecked}
                          compareData={dataTableCompare} // Replace with your actual compare data
                          compareHeaders={tableHeaders2}
                        />
                      )}

                      {currentView === "Balance Sheet" && (
                        <LoadingTable
                          loading={loadingBalance}
                          currentView={currentView}
                          data={dataTable}
                          headers={tableHeaders}
                          isChecked={isChecked}
                          compareData={dataTableCompare} // Replace with your actual compare data
                          compareHeaders={tableHeaders2}
                        />
                      )}

                      {currentView === "Cash Flow" && (
                        <LoadingTable
                          loading={loadingCashflow}
                          currentView={currentView}
                          data={dataTable}
                          headers={tableHeaders}
                          isChecked={isChecked}
                          compareData={dataTableCompare} // Replace with your actual compare data
                          compareHeaders={tableHeaders2}
                        />
                      )}
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Reports;
