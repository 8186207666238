/* eslint-disable */
import React from 'react';
import FreshBooks from '../../assets/freshbooks.png';
import QuickBooks from '../../assets/qb-logo.svg';
import Xero from '../../assets/xero.png';
import Spinner from '../../components/Workstation/Helpers/Spinner';
import { AuthContext } from '../../context/context';
import { getIntegrationInfo } from '../../context/intuit-service';
import { useStyles } from './styles.js';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { deleteIntegrationInfo } from '../../context/intuit-service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ConnectedIntegrations(props) {
  const {
    state,
    dispatch, integrationInfo
  } = React.useContext(AuthContext);
  const classes = useStyles();
  const [msg, setMsg] = React.useState('');
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);
  const [prog, setProg] = React.useState(true);
  const [connectedBooks, setConnectedBooks] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [deledtedOpen, setDeletedOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDeletedOpen = () => setDeletedOpen(true);
  const handleDeletedClose = () => setDeletedOpen(false);


  const getIcon = {
    "Quickbooks": QuickBooks,
    "Xero": Xero,
    "Freshbooks": FreshBooks,
  }

  React.useEffect(async () => {
    setLoading(true);
    let books = await getIntegrationInfo();
    if (books) {
      if (books.data === integrationInfo) {

      } else {
        dispatch({
          type: 'SET_INTEGRATIONINFO',
          payload: books.data.integrations,
        });
      }
    }
    if (Object.keys(books.data.integrations).length !== 0) {
      setConnectedBooks(books.data.integrations.integrations);
    }
    setLoading(false);
  }, [integrationInfo]);

  const deleteIntegration = async (value) => {
    const { protocol, hostname, port } = window.location;
    const URL = `${protocol}//${hostname}${port ? ':' + port : ''}`;
    let data = {
      integrationId: value.integrationId,
      URL: URL
    }
    let books = await deleteIntegrationInfo(data);
    if (books) {
      if (books.data === integrationInfo) {
      } else {

        setConnectedBooks(books.data.result.integrations);
        dispatch({
          type: 'SET_INTEGRATIONINFO',
          payload: books.data.result,
        });
      }
    }
    dispatch({
      type: 'SET_SELECTEDINTEGRATION',
      payload: 0,
    });

    handleClose();
    handleDeletedOpen();
    window.open('https://www.datatrixs.com/quickbooks-disconnected', '_blank');


  }

  return (
    <>
      {loading ?
        <Spinner height={"100px"} />
        :
        <>
          {connectedBooks.map((books) => {
            return (
              <>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Are you sure you would like to disconnect this integration?    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div class="ui right red button"
                        onClick={() => deleteIntegration(books)}>Yes</div>

                      <div class="ui right button"
                        onClick={() => handleClose()}>No</div>
                    </Typography>
                  </Box>
                </Modal>

                {/* <div class={ modal ? "ui segment active modal" : "ui segment modal" } style={{ position: "relative", width: "200px", height: "200x" }}>
                  <div className="ui segment" style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                    aaaaa
                  </div>
                </div> */}


                <div className="ui segments">

                  <div className="ui segment">
                    <p style={{ fontWeight: "bold" }} ><img src={getIcon[books.integrationType]} className="pr-5" /></p>
                  </div>
                  <div className="ui segment">
                    <div className="row">
                      <div className="col-sm-3">
                        <p style={{ fontWeight: "bold" }}>Account Info</p>
                      </div>
                      <div className="col-sm-3">
                        <p>{books.email} </p>
                      </div>
                      <div className="col-sm-3">
                        <p>{new Date(books.date).toUTCString().substring(0, 12)} </p>
                      </div>
                      <div className="col-sm-3">
                      </div>
                    </div>
                  </div>
                  <div className="ui segment">
                    <div class="ui right red button"
                      onClick={() => handleOpen()}>Disconnect</div>

                  </div>
                  <p></p>
                </div>
              </>
            )
          })}

          <Modal
            open={deledtedOpen}
            onClose={handleDeletedClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Successfully invalidated your QuickBooks Credentials
              </Typography>
            </Box>
          </Modal>
        </>

      }
    </>
  );
}

export default ConnectedIntegrations;
