import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: '#F1F4F7',
    height: '100%',
  },
  Logo: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  topBar: {
    position: 'absolute',
    left: '0%',
    right: '0%',
    top: '0%',
    bottom: '0%',
    paddingTop: '20px',
    background: '#F1F4F7',
  },

  card: {
    position: 'absolute',
    width: '422px',
    height: '611px',
    top: '100px',
    background: '#FFFFFF',
    orderRadius: '6px',
  },

  container: {
    margin: '0',
    position: 'absolute',
    top: '400px',
    width: '422px',
    height: '611px',
    padding: '50px 50px 50px 50px',
    left: '50%',
    background: '#FFFFFF',
    MsTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
  },

  heading: {
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '46px',
    lineHeight: '58px',
    alignItems: 'center',
    textAlign: 'center',
    color: '#55616E',
  },

  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    alignItems: 'center',
    textAlign: 'center',
    color: '#55616E',
  },

  LandingCard: {
    position: 'absolute',
    left: '0%',
    right: '0%',
    top: '0%',
    bottom: '0%',
    width: '90%',
    height: '80%',
    marginLeft: '50px',
    marginTop: '100px',
    background: 'white',
    borderRadius: '6px',
  },

  LandingContainer: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    MsTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
  },

  google: {
    color: '#55616E',
    borderRadius: '6px',
    background: '#F1F4F7',
    height: '40px',
    marginTop: '20px',
    marginBottom: '20px',
  },

  line: {
    borderLeft: '1px solid #79A1DD',
    height: '40px',
  },
  hr: {
    clear: 'both',
    margin: 'var(--dwg-spacing__unit--2, 16px) 0',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
  },
}));

export { useStyles };
