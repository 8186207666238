/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { Box, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { AuthContext } from "../../context/context";

const File = ({ id, file, clickedFile, fileClicked }) => {
  const {
    state: {
      user,
      switchUser,
      isAuthenticated,
      access_token,
      purchasing,
      projects,
      selectedBoard,
      allBoards,
      selectedTab,
      gptData,
      data_change,
      uploadedFiles,
    },
    dispatch,
  } = React.useContext(AuthContext);

  // const [fileClicked, setFileClicked] = useState(null);
  const [fileHover, setFileHover] = useState(null);

  useEffect(() => {
    console.log(file);
    console.log(fileClicked);
  }, []);

  const deleteFile = (id) => {
    const newFiles = uploadedFiles.filter((file, i) => {
      return i !== id;
    });
    dispatch({
      type: 'SET_UPLOADED_FILES',
      payload: newFiles
    });
  }

  // const clickedFile = (id) => {
  //   console.log("clicked");
  //   if (fileClicked === id) {
  //     setFileClicked(null);
  //   }
  //   else {
  //     setFileClicked(id);
  //   }
  // }


  return (
    <>
      <ListItem
        id={id}
        style={{
          margin: "10px 0px",
          backgroundColor: (fileClicked === id) && "#F0F0F0",
          borderRadius: '10px',
          cursor: fileHover === id ? 'pointer' : 'default'
        }}
        onClick={() => clickedFile(id)}
        onMouseEnter={() => setFileHover(id)}
        onMouseLeave={() => setFileHover(null)}
      >
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={file?.name ? file?.name : "Untitled"}
          secondary={null}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => deleteFile(id)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

export default File;
