/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileImage from "../../assets/profile.png";
import axios from "../../context/axios";
import { AuthContext } from "../../context/context";
import { useStyles } from "./styles.js";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";


function Profile() {
  const {
    // state: { user },
    state,
    dispatch,
  } = React.useContext(AuthContext);

  const classes = useStyles();

  const [deleteModal, setDeleteModal] = useState(false);
  // const [confirmDelete, setConfirmDelete] = useState(0);

  const { user } = state;
  const [userSetting, setUserSetting] = React.useState({
    firstName: user ? user.firstName : "",
    lastName: user ? user.lastName : "",
    email: user ? user.email : "",
    phone: user ? user.phone : "",
    currency: user ? user.currency : "",
  });
  const history = useNavigate();
  const [alertClass, setAlertClass] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [err, setErr] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [file, setFile] = React.useState("");
  const [fileUrl, setFileUrl] = React.useState("");

  const handleSetting = (e) => {
    const { name, value } = e.target;
    setUserSetting((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setFileUrl(reader.result);
        setFile(file);
      };
    }
  };

  const DeleteAccount = () => {
    setDeleteModal(true);
  };

  const changeProfile = async () => {
    setLoader(true);
    const formData = new FormData();
    try {
      formData.append("image", file, file.name);

      try {
        const profile = await axios.post("/profile", formData);
        if (profile.status === 200) {
          const user = await axios.get("/user");
          if (user.status === 200) {
            setErr("");
            setAlertClass("show");
            setFile("");
            dispatch({
              type: "SET_USER",
              payload: user.data.user,
            });
            setMsg(profile.data.message);
            setLoader(false);
          }
        }
      } catch (e) {
        setAlertClass("show");
        setMsg("");
        if (e.response && e.response.data) {
          if (e.response.data.error) {
            setErr(e.response.data.error.message);
          } else {
            setErr(e.response.data.message);
          }
        } else {
          setErr(e.message);
        }
        setLoader(false);
      }
    } catch (e) {}
  };
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      changeProfile();
      const update = await axios.patch("/setting", userSetting);
      if (update.status === 200) {
        const user = await axios.get("/user");
        if (user.status === 200) {
          setErr("");
          setAlertClass("show");
          setUserSetting({
            firstName: user !== null ? user.data.user.firstName : "",
            lastName: user !== null ? user.data.user.lastName : "",
            email: user !== null ? user.data.user.email : "",
            phone: user !== null ? user.data.user.phone : "",
            currency: user !== null ? user.data.user.currency : "",
          });
          dispatch({
            type: "SET_USER",
            payload: user.data.user,
          });
          setMsg(update.data.message);
          setLoader(false);
        }
      }
    } catch (e) {
      setAlertClass("show");
      setMsg("");
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else {
          setErr(e.response.data.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader(false);
    }
  };
  const handleCloseAlert = () => {
    setAlertClass("hide");
    setErr("");
    setMsg("");
  };
  const cancelProfileChange = () => {
    setFileUrl("");
  };

  const handleDeleteCancel = () => {
    setDeleteModal(false);
  }

  const handleDeleteConfirm = async () => {
    setDeleteModal(false);
     try {
        await axios.post("/deleteAccount");

        dispatch({
          type: "LOGOUT",
        });
        history.push("/login");
      } catch (e) {
        setAlertClass("show");
        setMsg("");
        if (e.response && e.response.data) {
          if (e.response.data.error) {
            setErr(e.response.data.error.message);
          } else {
            setErr(e.response.data.message);
          }
        } else {
          setErr(e.message);
        }
        setLoader(false);
      }

  }

  return (
    <div className="col-8">
      <div className={classes.mainCard}>
        {msg && (
          <div
            className={`alert alert-success alert-dismissible fade ${alertClass}`}
            role="alert"
          >
            <strong>{msg}</strong>
            <button
              onClick={handleCloseAlert}
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        )}
        {err && (
          <div
            className={`alert alert-danger alert-dismissible fade ${alertClass}`}
            role="alert"
          >
            <strong>{err}</strong>
            <button
              onClick={handleCloseAlert}
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        )}
        <div className="row">
          <div className="col">
            <div className="row align-items-center">
              <div className="col-auto">
                <div
                  title="Change Profile"
                  className="file-type-container avatar avatar-container"
                >
                  <div onClick={cancelProfileChange} className="avatar-cross">
                    <i style={{ fontSize: "16px" }} className="fe fe-x" />
                  </div>
                  <span className="btn-type-file">
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => onChangeFile(e)}
                    />
                    <span className="btn-file-icon">
                      <i className="fe fe-camera" />
                    </span>
                  </span>
                  <img
                    src={
                      fileUrl !== "" && fileUrl !== null
                        ? fileUrl
                        : user && user.profile && fileUrl === ""
                        ? `${user.profile}`
                        : ProfileImage
                    }
                    className="avatar-img rounded-circle"
                    alt="profile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mt-5 mb-5" />
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label className="form-label">
                  First name
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={userSetting.firstName}
                  onChange={handleSetting}
                  required
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label className="form-label">
                  Last name
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={userSetting.lastName}
                  onChange={handleSetting}
                  required
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">
                  Email address
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </label>
                <input
                  type="email"
                  disabled
                  className="form-control"
                  name="email"
                  value={userSetting.email}
                  onChange={handleSetting}
                  required
                  placeholder="Emails"
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label className="form-label">
                  Phone<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control mb-3"
                  name="phone"
                  value={userSetting.phone}
                  onChange={handleSetting}
                  required
                  placeholder="Phone"
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label className="form-label">
                  Currency
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </label>
                <select
                  type="text"
                  className="form-control mb-3"
                  name="currency"
                  value={userSetting.currency}
                  onChange={handleSetting}
                  required
                  placeholder="Phone"
                >
                  <option value="" disabled selected>
                    Choose currency
                  </option>
                  <option value="$" selected="selected">
                    United States Dollars
                  </option>
                  <option value="€">Euro</option>
                  <option value="£">British pound</option>
                  <option value="$">Australian dollar</option>
                  <option value="$">Canadian dollar</option>
                  <option value="CHF">Swiss franc</option>
                  <option value="¥">Chinese yuan</option>
                  <option value="₹">Indian rupee</option>
                  <option value="¥">Japanese yen</option>
                  <option value="$">Singapore dollar</option>
                </select>
              </div>
            </div>
          </div>

          <button
            disabled={loader}
            className="btn btn-custom btn-padd"
            style={{ backgroundColor: "black" }}
          >
            {loader && (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
            {!loader && "Save changes"}
          </button>
          <button className="btn btn-danger btn-padd" onClick={DeleteAccount}>
            Delete Account
          </button>
          <Modal open={deleteModal} onClose={handleDeleteCancel}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "400px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "0 0 10px rgba(0,0,0,0.3)",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Are you sure you want to delete your account?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#000", color: "#fff" }}
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
        </form>
      </div>
    </div>
  );
}

export default Profile;
