import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { AuthContext } from '../../context/context';
import { getProjects } from '../../context/fetch-service';
import { addProjects } from '../../context/project-service';

export default function NewProject(props) {
  const useStyles = makeStyles(() => ({
    paper: { minWidth: '400px' },
  }));
  const classes = useStyles();
  const {
    state: {
      user, switchUser, isAuthenticated, access_token, purchasing, projects,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [name, setName] = React.useState('Project Name');
  const [scenarioName, setScenarioName] = React.useState('Scenario Name');

  const createNewProject = async () => {
    const addProject = await addProjects({ sharedId: switchUser.userName, projectName: name, scenarioName });
    getProjects(switchUser.userName)
      .then((projects) => {
        if (projects.length === 0) {
          dispatch({
            type: 'SET_PROJECTS',
            payload: [],
          });
        } else {

          dispatch({
            type: 'SET_PROJECTS',
            payload: projects,
          });
        }
      })
      .catch((err) => {

      });
    props.handleCloseBackDrop();
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.backDropOpen}
        onClose={props.handleCloseBackDrop}
      >
        <Dialog classes={{ paper: classes.paper }} onClose={props.handleCloseBackDrop} open={props.backDropOpen}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent style={{ padding: '50px 50px 50px 50px' }}>
              <h1 style={{ paddingBottom: '15px', width: '100%' }}>  Project Name </h1>
              <TextField
                style={{ paddingBottom: '15px', width: '100%' }}
                onChange={() => setName(event.target.value)}
                required
                label="Required"
                value={name}
                variant="standard"
              />
              <TextField
                style={{ paddingBottom: '10px', width: '100%' }}
                onChange={() => setScenarioName(event.target.value)}
                variant="standard"
                required
                label="Required"
                value={scenarioName}
              />
              <Button style={{ paddingTop: '15px', width: '100%', backgroundColor: '#220FFE' }} onClick={() => createNewProject()} variant="contained">Create</Button>
            </CardContent>
          </Card>

        </Dialog>
      </Backdrop>
    </>
  );
}
