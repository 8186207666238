/* eslint-disable */

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';
import { AuthContext } from '../../context/context';
import { addNewBoard } from '../../context/intuit-service';
import { extractId } from './helpers';
import Draggable from "react-draggable";

const AddTextModal = ({ addTextModalOpen, handleAddTextCancel, edit, board }) => {

    const {
        state: {
            user, switchUser, isAuthenticated, access_token, purchasing, projects, selectedBoard, allBoards, selectedTab,
        },
        dispatch,
    } = React.useContext(AuthContext);
    const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;
    const [title, setTitle] = useState(board ? board.title : '');
    const [text, setText] = useState(board && board.boardData && board.boardData[0] ? board.boardData[0].text : 'Write about your data');
    const [saving, setSaving] = useState(false);

    const addText = async () => {
        setSaving(true);

        let boardInfo = {
            clientId: allBoards.boards[selectedBoard]._id,
            boardId: allBoards._id,
            tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id, // Include tabId in the payload
            data: {
                index: allBoards.boards[selectedBoard].tabs[selectedTab].boardData ? allBoards.boards[selectedBoard].tabs[selectedTab].boardData.length : 0,
                boardView: "Text",
                boardType: "Text",
                title: title,
                boardData: {
                    text: text
                },
            }
        };

        if (allBoards.boards[selectedBoard].access) {
            boardInfo.boardId = extractId(allBoards.boards[selectedBoard].access)
        }


        let addNewBoardResponse;
        if (edit) {
            boardInfo.data._id = board._id;
            addNewBoardResponse = await addNewBoard(boardInfo);
            
        } else {
            addNewBoardResponse = await addNewBoard(boardInfo);
        }
        console.log(addNewBoardResponse);
        // let temp = allBoards;
        // temp.boards.forEach(board2 => {
        //     const matchingIndex = data.boards.findIndex(board1 => board1._id === board2._id);
        //     if (matchingIndex !== -1) {
        //         data.boards[matchingIndex] = board2;
        //     }
        // });
        // dispatch({
        //     type: 'SET_ALLBOARDS',
        //     payload: temp
        // });
        dispatch({
            type: 'SET_ALLBOARDS',
            payload: addNewBoardResponse.data.data // directly set the new boards
        });
        setSaving(false);
        handleAddTextCancel();
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const editorRef = React.useRef(null);

    // Rest of your JSX code for the modal...

    return (
      <Modal open={addTextModalOpen} onClose={handleAddTextCancel}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0 0 10px rgba(0,0,0,0.3)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Title
          </Typography>
          <Input
            style={{ marginBottom: "15px", width: "100%" }}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            autoFocus
            sx={{ fontSize: 18, color: "black" }}
            placeholder={"Add title"}
          />
            <div>
              <Editor
                apiKey="cksenl0f6na40azujwxt43puxg1f8mfzjdxffwbok9fhpzba"
                value={text}
                onEditorChange={(content) => setText(content)}
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  height: 600,
                  branding: false,
                  selector: "textarea#open-source-plugins",
                  plugins:
                    "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
                  editimage_cors_hosts: ["picsum.photos"],
                  menubar: "file edit view insert format tools table help",
                  toolbar:
                    "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                  // toolbar_sticky: true,
                  // toolbar_sticky_offset: isSmallScreen ? 102 : 108,
                  autosave_ask_before_unload: true,
                  autosave_interval: "30s",
                  autosave_prefix: "{path}{query}-{id}-",
                  autosave_restore_when_empty: false,
                  autosave_retention: "2m",
                  image_advtab: true,
                  link_list: [
                    { title: "My page 1", value: "https://www.tiny.cloud" },
                    { title: "My page 2", value: "http://www.moxiecode.com" },
                  ],
                  image_list: [
                    { title: "My page 1", value: "https://www.tiny.cloud" },
                    { title: "My page 2", value: "http://www.moxiecode.com" },
                  ],
                  image_class_list: [
                    { title: "None", value: "" },
                    { title: "Some class", value: "class-name" },
                  ],
                  importcss_append: true,
                  file_picker_callback: (callback, value, meta) => {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === "file") {
                      callback("https://www.google.com/logos/google.jpg", {
                        text: "My text",
                      });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === "image") {
                      callback("https://www.google.com/logos/google.jpg", {
                        alt: "My alt text",
                      });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === "media") {
                      callback("movie.mp4", {
                        source2: "alt.ogg",
                        poster: "https://www.google.com/logos/google.jpg",
                      });
                    }
                  },
                  templates: [
                    {
                      title: "New Table",
                      description: "creates a new table",
                      content:
                        '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                    },
                    {
                      title: "Starting my story",
                      description: "A cure for writers block",
                      content: "Once upon a time...",
                    },
                    {
                      title: "New list with dates",
                      description: "New List with dates",
                      content:
                        '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                    },
                  ],
                  template_cdate_format:
                    "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                  template_mdate_format:
                    "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                  image_caption: true,
                  quickbars_selection_toolbar:
                    "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                  noneditable_class: "mceNonEditable",
                  toolbar_mode: "sliding",
                  contextmenu: "link image table",
                  skin: useDarkMode ? "oxide-dark" : "oxide",
                  content_css: useDarkMode ? "dark" : "default",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
                }}
              />
            </div>
          <Button
            onClick={addText}
            style={{
              paddingTop: "15px",
              width: "100%",
              backgroundColor: "#000000",
              color: "white",
            }}
            variant="contained"
          >
            {"Save"}
          </Button>
        </div>
      </Modal>
    );
};

export default AddTextModal;
