/* eslint-disable */
import BoltIcon from "@mui/icons-material/Bolt";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/context";
import { addNewBoard } from "../../context/intuit-service";
import { extractId } from "../ClientPerformance/helpers";
import "./DtGpt.css";
import { sendMessageToGpt } from "./Gpt";
import { formatMessageContent } from "./AiFormatMessage";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { CircularProgress } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import File from './File';
// csv parser
import Papa from 'papaparse';
// excel parser
// import xlsx from 'node-xlsx';
import * as XLSX from "xlsx";




function DtGpt({ data }) {
  const {
    state: {
      user,
      switchUser,
      isAuthenticated,
      access_token,
      purchasing,
      projects,
      selectedBoard,
      allBoards,
      selectedTab,
      gptData,
      data_change,
      uploadedFiles,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [assistantData, setAssistantData] = useState(null);

  useEffect(() => {
    if (assistantData) {
      dispatch({
        type: "SET_GPT_DATA",
        payload: assistantData,
      });
      return;
    }
    console.log(data);
    dispatch({
      type: "SET_GPT_DATA",
      payload: data,
    });
  }, [data && data.length > 0, assistantData]);

  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [scrollContainer, setScrollContainer] = useState(null); // State to hold the chat content container
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [ai_loaded, setAI_Loaded] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [clickedFile, setClickedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (loading) {
      console.log("loading");
    }
    else {
      console.log("finished");
    }
  }, [loading])

  useEffect(() => {
    console.log(messages);
  }, [messages]);

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      setStep(1);
      setUploadedFile(null);
      setUploadedFileName(null);
    }
  }, [uploadedFiles])

  useEffect(() => {
    console.log(conversationHistory);
  }, [conversationHistory]);

  const handleOptionSelect = async (option) => {
    setLoading(true);
    setStep(2);
    if (option === "P&L") {
      await connectQuickbooks(data, "Income Statement, Balance Sheet, CashFlow");
    } else if (option === "Balance") {
      await connectQuickbooks(data, "Income Statement, Balance Sheet, CashFlow");
    } else if (option === "CashFlow") {
      await connectQuickbooks(data, "Income Statement, Balance Sheet, CashFlow");
    }
    setSelectedOption(option);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const copyToClipboard = async () => {
    // Get the last message from the messages array
    const lastMessage = messages[messages.length - 1].text;

    // Copy the last message to the clipboard
    await navigator.clipboard.writeText(lastMessage);
  };

  const addToBoard = async () => {
    // Get the last message from the messages array
    const lastMessage = messages[messages.length - 1].content;
    const lastUserPrompt = messages[messages.length - 2].content;

    let boardInfo = {
      clientId: allBoards.boards[selectedBoard]._id,
      boardId: allBoards._id,
      tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id, // Include tabId in the payload
      data: {
        index: allBoards.boards[selectedBoard].tabs[selectedTab].boardData
          ? allBoards.boards[selectedBoard].tabs[selectedTab].boardData.length
          : 0,
        boardView: "Text",
        boardType: "Text",
        title: lastUserPrompt,
        boardData: {
          text: lastMessage,
        },
      },
    };

    if (allBoards.boards[selectedBoard].access) {
      boardInfo.boardId = extractId(allBoards.boards[selectedBoard].access);
    }
    let addNewBoardResponse = await addNewBoard(boardInfo);

    dispatch({
      type: "SET_ALLBOARDS",
      payload: addNewBoardResponse.data.data,
    });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log(file);

    if (file) {
      // Check if the file has a .csv, .xlsx, or .xls extension
      if (
        file.name.endsWith(".csv")
      ) {
        setUploadedFile(file);
        setUploadedFileName(file.name);
      } else {
        alert("Please upload a .csv");
      }
    }
  };

  const connectQuickbooks = async (data, dataType) => {
    // function delay(ms) {
    //   return new Promise((resolve) => setTimeout(resolve, ms));
    // }
    // await delay(10000);
    let jsonData = JSON.stringify(data);
    console.log(jsonData);
    // dispatch({
    //   type: "SET_GPT_DATA",
    //   payload: JSON.parse(jsonData),
    // });
    // If there is not data in this message after "->", respond with "please resync your integrations".
    const prompt =
      `Here is a stringified json dataset - read and remember that data. You are an assistant to help automate CPA's tasks. 
      Take a deep breath, and extract all the financial numbers to become an expert on my data. 
      Also, do not give me any explanations or formulas, simply give me the information I ask for without speaking.
      Return responses in html format. Use bullet points, sections, and paragraphs compared to tabular format. Do not apologize, do not preface, be concise. Simply give me the information exactly how I ask for. 
      If there is no data after "->" tell the user this. Please reference this data for every query.
      This data is about ${dataType} from intuit quickbooks ->` + jsonData;
    console.log(jsonData);

    const loading2 = {
      content: "Setting up your AI Assistant...",
      role: "assistant",
    };
    setMessages([loading2]);

    const systemMessage = { content: prompt, role: "system", id: Date.now() };
    // make it so gpt api talks first
    // const talkFirst = { content: "Hello, I'm your AI Assistant! Ask me anything about your data!", role: "assistant" };
    // const loadingMessage = { content: 'Typing...', role: 'assistant', id: Date.now() };

    try {
      // Add user's message to the conversation history

      const updatedConversationHistory = [
        ...conversationHistory,
        systemMessage,
        {
          content:
            "Hello I am your AI Assistant! Ask me anything about your data!",
          role: "assistant",
        },
      ];

      // Pass the entire conversation history as the first argument
      // first message needs to be a system prompt, because it's just the quickbooks data

      // wait for gpt to load the data into system before allowing user to prompt
      const aiResponse = await sendMessageToGpt(updatedConversationHistory, "");
      console.log(aiResponse.status);
      setAI_Loaded(true);
      // console.log(aiResponse)

      setMessages([
        ...messages,
        {
          content:
            "Hello I am your AI Assistant! Ask me anything about your data!",
          role: "assistant",
        },
      ]);

      // console.log(updatedMessages);

      // Update state with the new conversation history and messages
      setConversationHistory(updatedConversationHistory);
      // setMessages(updatedMessages);
      // console.log(messages);
    } catch (error) {
      console.error("Error handling AI response:", error);
    }
  };

  useEffect(() => {
    resyncIntegrations();
  }, [data_change]);

  const resyncIntegrations = () => {
    setModalOpen(false);
    setUserInput("");
    setSelectedOption(null);
    setStep(1);
    setMessages([]);
    setUploadedFile(null);
    setUploadedFileName(null);
    setConversationHistory([]);
  };

  const handleShowFiles = () => {
    dispatch({
      type: "SET_UPLOADED_FILES",
      payload: [...uploadedFiles, uploadedFile],
    });
    setShowFiles(true);
    setStep(1.5);
  };

  const handleSendMessage = async () => {
    if (userInput.trim() === "") return;

    const userMessage = { content: userInput, role: "user", id: Date.now() };
    const loadingMessage = {
      content: "Typing...",
      role: "assistant",
      id: Date.now(),
    };

    // Add the loading message to the messages array
    setMessages([...messages, userMessage, loadingMessage]);

    try {
      // Pass the entire conversation history as the first argument
      setUserInput("");
      console.log(conversationHistory);
      const aiResponse = await sendMessageToGpt(
        conversationHistory,
        userMessage
      );

      // Add user's message to the conversation history
      const updatedConversationHistory = [
        ...conversationHistory,
        userMessage,
        { content: aiResponse, role: "assistant" },
      ];

      // Add AI's response to the conversation history
      const updatedMessages = [
        ...messages,
        userMessage,
        { content: aiResponse, role: "assistant" },
      ];

      // Update state with the new conversation history and messages
      setConversationHistory(updatedConversationHistory);
      setMessages(updatedMessages);
      console.log(messages);
    } catch (error) {
      console.error("Error handling AI response:", error);
      setUserInput("");
    }

    setUserInput("");
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setUserInput("");
    setSelectedOption(null);
    setStep(1);
    setMessages([]);
    setUploadedFile(null);
    setUploadedFileName(null);
    setConversationHistory([]);
  };

  const handleBackClick = () => {
    setStep(1);
    setUploadedFile(null);
    setUploadedFileName(null);
  }

  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      const handleKeyDown = (e) => {
        if (e.key === "Enter" && userInput.trim() !== "") {
          handleSendMessage();
        }
      };

      inputElement.addEventListener("keydown", handleKeyDown);

      return () => {
        inputElement.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [userInput]);

  useEffect(() => {
    // Scroll to the bottom of the chat content when new messages are added
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, [messages]);

  const openPanel = () => {
    // Add logic to open the panel
    setPanelOpen(true);
  };

  const closePanel = () => {
    // Add logic to close the panel
    setPanelOpen(false);
  };

  useEffect(() => {
    if (ai_loaded && inputRef.current) {
      const input = inputRef.current;
      input.focus();
    }
  }, [ai_loaded]);

  function parseCSVAsync(file, config = {}) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        ...config,
        complete: (results) => {
          const filteredData = results.data.filter((row) =>
            row.some((cell) => cell !== "")
          );
          resolve({ ...results, data: filteredData });
        },
        error: (error) => reject(error),
      });
    });
  }

  // replace gpt data with this data, essentially, after converting the excel, csv, etc. to json
  const selectClicked = async () => {
    setLoading(true);
    setStep(2);
    const fileId = clickedFile;
    console.log(uploadedFiles[fileId]);
    console.log(fileId);
    if (uploadedFiles[fileId].name.includes(".xlsx")) {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        console.log(worksheet);
        const parsedXLSX = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: null,
          header: 1
        });

        // Object.keys(worksheet).forEach((cell) => {
        //   if (worksheet[cell].f) {
        //     console.log(`Cell ${cell} has formula: ${worksheet[cell].f}`);
        //   }
        // });

        for (let key of Object.keys(worksheet)) {
          if (worksheet[key].f) {
            console.log(worksheet[key].f);
          }
        }

        console.log(parsedXLSX);
      };
      reader.readAsArrayBuffer(uploadedFiles[fileId]);
      return;
    }
    switch(uploadedFiles[fileId].type) {
      case 'text/csv':
        try {
          const parsedCSV = await parseCSVAsync(uploadedFiles[fileId]);
          console.log(parsedCSV?.data);
          setAssistantData(parsedCSV?.data);
          await connectQuickbooks(parsedCSV?.data, "Income Statement, Balance Sheet, CashFlow");
          break;
        }
        catch (error) {
          // set an error message that the data is unreadable and go back to step 1, delete the file as well
          console.error(error);
        }
      default:
        console.error("Unable to parse specific file; file type is not supported.");
    }

    // if (uploadedFiles[fileId] && uploadedFiles[fileId].type === 'text/csv') {
    //   const parsedCSV = await parseCSVAsync(uploadedFiles[fileId]);
    //   console.log(parsedCSV);
    // }
    setLoading(false);
  }

  const handleClickedFile = (id) => {
    console.log("clicked");
    if (clickedFile === id) {
      setClickedFile(null);
    }
    else {
      setClickedFile(id);
    }
  }

  return (
    <>
      <button
        className="ui icon second-step"
        onClick={openPanel}
        style={{
          width: "140px",
          paddingRight: 15,
          color: "white",
          background: "linear-gradient(to top right, #01e98f, #0173bf)",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        <BoltIcon fontSize="large" />
        {"    "} AI Assistant
      </button>

      <div className="App">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            right: 0, // Position the panel to the right
            top: 0,
            bottom: 0,
            zIndex: 9999,
            width: "500px", // Adjust the width as needed
            height: "100%",
            maxHeight: "100%",
            overflow: "hidden",
            overflowY: "auto",
            backgroundColor: "#ffffff", // Add your desired background color
            boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow if needed
            transition: "transform 0.3s ease-in-out", // Add transition for smooth open/close
            transform: panelOpen ? "translateX(0)" : "translateX(100%)", // Slide in from the right
          }}
          role="sidebar"
        >
          {step === 1 && (
            <>
              <div
                className="row mb-3"
                style={{
                  display: "grid",
                  gridTemplateColumns: "4fr 1fr",
                  width: "100%",
                  maxHeight: "50px",
                }}
              >
                <div>
                  <DialogTitle style={{ textAlign: "left" }}>
                    Select or Upload a data set
                  </DialogTitle>
                </div>
                <div className="mt-3">
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={closePanel}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </div>
              </div>
              <DialogContent
                className="chat-container"
                style={{ height: "85%" }}
              >
                <div className="ui buttons w-100">
                  <button
                    className={`ui button secondary m-2 ${
                      selectedOption === "P&L" ? "active" : ""
                    }`}
                    onClick={() => handleOptionSelect("P&L")}
                  >
                    Use Data from Quickbooks
                  </button>
                  {/* <button
                    className={`ui button secondary m-2 ${selectedOption === 'Balance' ? 'active' : ''}`}
                    onClick={() => handleOptionSelect('Balance')}
                  >
                    Balance
                  </button>
                  <button
                    className={`ui button secondary m-2 ${selectedOption === 'Cash Flow' ? 'active' : ''}`}
                    onClick={() => handleOptionSelect('Cash Flow')}
                  >
                    Cash Flow
                  </button> */}
                </div>
                {/* Drag and Drop Upload Container */}
                <div
                  className="ui raised segment upload-container"
                  style={{
                    height: "600px",
                    border: "2px dashed black", // Black dashed line border
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const file = e.dataTransfer.files[0];
                    handleFileUpload({ target: { files: [file] } });
                  }}
                >
                  <p>
                    Drag and drop a .csv file here or click to
                    upload
                  </p>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept=".csv" // Accept only .csv, .xlsx, and .xls" files
                  />
                  {uploadedFileName && <p>Uploaded File: {uploadedFileName}</p>}
                  <button
                    className="ui button m-1"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    Upload
                  </button>
                  {uploadedFileName && (
                    <button
                      className="ui button m-1"
                      onClick={() => handleShowFiles()}
                    >
                      Next
                    </button>
                  )}
                </div>
              </DialogContent>
            </>
          )}
          {step === 1.5 && showFiles && (
            <>
              <div
                style={{
                  height: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="row mb-3"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    width: "100%",
                    maxHeight: "50px",
                  }}
                >
                  <div>
                    <DialogTitle
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      Select a file
                    </DialogTitle>
                  </div>
                  <div className="mt-3 ml-3">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={closePanel}
                    >
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </div>
                </div>
                <DialogContent style={{ height: "89%" }}>
                  <div>
                    <Grid item xs={12} md={12}>
                      <div>
                        <List dense={true}>
                          {uploadedFiles &&
                            uploadedFiles.map((file, i) => {
                              return (
                                <File
                                  key={i}
                                  id={i}
                                  file={file}
                                  selectClicked={(fileId) =>
                                    selectClicked(fileId)
                                  }
                                  clickedFile={(id) => handleClickedFile(id)}
                                  fileClicked={clickedFile}
                                />
                              );
                            })}
                        </List>
                      </div>
                    </Grid>
                  </div>
                </DialogContent>
                <div
                  className="ui buttons w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={`ui button secondary m-2`}
                    style={{
                      display: "block",
                      width: "90%",
                      borderRadius: "5px",
                    }}
                    onClick={selectClicked}
                  >
                    Select
                  </button>
                  <button
                    onClick={handleBackClick}
                    className="ui button m-2"
                    style={{ width: "90%", borderRadius: "5px" }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div
                className="row mb-3"
                style={{
                  display: "grid",
                  gridTemplateColumns: "4fr 1fr",
                  width: "100%",
                  maxHeight: "50px",
                }}
              >
                <div>
                  <DialogTitle
                    style={{
                      textAlign: "left",
                      color: "#00396d",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    <BoltIcon fontSize="large" />
                    {"    "} AI Assistant
                  </DialogTitle>
                </div>
                <div className="mt-3" style={{}}>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={closePanel}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </div>
              </div>
              {loading ? (
                <>
                  <DialogContent
                    className="chat-container pb-2"
                    style={{ height: "85%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <CircularProgress size={32} color="inherit" />
                  </DialogContent>
                </>
              ) : (
                <>
                  <DialogContent
                    className="chat-container pb-2"
                    style={{ height: "85%" }}
                  >
                    <div
                      className="chat-content"
                      style={{ width: "100%" }}
                      ref={(element) => setScrollContainer(element)} // Set the ref to the chat content container
                    >
                      {messages.map((message, index) => (
                        <>
                          <div
                            key={index}
                            className={`message ${
                              message.role === "user"
                                ? "user-message"
                                : "ai-message"
                            }`}
                            style={{
                              float: `${
                                message.role === "user" ? "right" : "left"
                              }`,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: formatMessageContent(message.content),
                            }} // Use a function to format message content
                          ></div>
                          <div style={{ clear: "both" }}></div>
                        </>
                      ))}
                      {messages.length >= 2 && (
                        <div
                          className="ui menu pb-3"
                          style={{
                            position: "absolute",
                            bottom: "55px", // Adjust the distance from the bottom
                            left: "50%", // Center horizontally
                            transform: "translateX(-50%)", // Center using transform
                            display: "flex", // Enable flex behavior
                            justifyContent: "center", // Center horizontally within the container
                            alignItems: "center", // Center vertically within the container
                            width: "100%", // Take up the full width of the container
                          }}
                        >
                          <button
                            className="ui button"
                            style={{ width: "40%" }}
                            onClick={copyToClipboard}
                          >
                            Copy to Clipboard
                          </button>
                          <button
                            className="ui button"
                            style={{ width: "40%" }}
                            onClick={addToBoard}
                          >
                            Add to Client's Board
                          </button>
                        </div>
                      )}
                    </div>
                  </DialogContent>
                  <div className="chat-input" style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Ask AI a question about your financials..."
                      disabled={ai_loaded ? false : true}
                      value={userInput}
                      onChange={handleInputChange}
                      ref={inputRef} // Add the ref to the input field
                      style={{
                        flex: "1", // Allow the input to grow and take up available space
                        padding: "10px", // Adjust padding as needed
                        border: "none",
                        borderBottom: "1px solid #ddd",
                        borderRadius: "0",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                      }}
                    />
                    <button
                      className="ui button"
                      onClick={handleSendMessage}
                      style={{
                        padding: "0.5em", // Adjust the padding as needed
                        background: "none",
                        border: "none",
                        color: "#2185d0", // Match the chat box color
                        width: "40px",
                      }}
                    >
                      <i className="paper plane outline icon"></i>{" "}
                      {/* Semantic UI send arrow icon */}
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </Box>
      </div>
    </>
  );
}

export default DtGpt;
