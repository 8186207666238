/*eslint-disable*/

import React, { useEffect } from 'react'
import ChartComponent from '../ChartComponent'

const GraphAssistantBoard = (props) => {

    React.useEffect(() => {
        console.log(props.board.boardData[0].config);
    }, [props]);

  return (
    <>
        {props.board && props.board.boardData && props.board.boardData[0] && props.board.boardData[0].config && 
        <ChartComponent config={props.board.boardData[0].config} chartId={props.id} setRef={props.setRef} />}
    </>
  );
}

export default GraphAssistantBoard
