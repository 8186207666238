/* eslint-disable */

import React, { useState } from 'react';
import { AuthContext } from '../../../context/context';

import { Chart } from "react-google-charts";
import { setListItemsParsed } from '../helpers';
export const options = {
    title: "",
    legend: {
        position: 'top'
    },
    chartArea: { width: '70%', right: 50 },
    annotations: {
        alwaysOutside: true,
        textStyle: {
            fontSize: 12,
            auraColor: 'none',
            color: '#555'
        }
    },
    boxStyle: {
        stroke: '#ccc',
        strokeWidth: 1,
        gradient: {
            color1: '#f3e5f5',
            color2: '#f3e5f5',
            x1: '0%', y1: '0%',
            x2: '100%', y2: '100%'
        }
    },
    bars: 'horizontal', // Required for Material Bar Charts.
    hAxis: { format: 'currency' },
    height: 400,
    chartArea: {
        left: 100, // 50 pixels from the left edge of the chart
        top: 50, // 20 pixels from the top of the chart
        bottom: 50, // 20 pixels from the top of the chart
        right: 50, // 20 pixels from the top of the chart

        width: '100%', // 70% of the chart width
        height: '100%', // 80% of the chart height
    },
};

const BarGraphBoard = (props) => {
    const {
        state: {
            integrations, integrationInfo, selectedIntegration, selectedBoard
        }, dispatch
    } = React.useContext(AuthContext);
    const [parsedDataTablePie, setParsedDataTablePie] = useState([])

    React.useEffect(() => {
        setListItems(props.data, props.board.boardData, props.board.boardFormulas)
    }, [props.board, props.data])

    const setListItems = (allData, data, formulas) => {
        console.log({
            allData: allData,
            data: data,
            formulas: formulas
        })
        let result = setListItemsParsed(allData, data, formulas)
        setParsedDataTablePie(result);
    }
    return (
        <>
            {parsedDataTablePie &&

                <Chart
                    chartType="BarChart"
                    data={parsedDataTablePie}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                />
            }
        </>
    );
};

export default BarGraphBoard;
