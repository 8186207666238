/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Popup } from "semantic-ui-react";
import Alerts from "../../views/Misc/Alerts";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';

const EditFormula = (props) => {


    const [formulaName, setFormulaName] = React.useState(props.name);
    const [formula, setFormula] = React.useState(props.formula);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleFormulaNameChange(e) {
        setFormulaName(e.target.value);
    }

    function handleFormulaChange(e) {
        setFormula(e.target.value);
    }

    function editToFormulas() {
        let data = {
            name: formulaName,
            formula: formula
        }
        props.editToListFormulas(data, props.name);
    }

    const removeListFormulas = (i) => {
        let newList = [...props.selectedFormulas];
        let removeVal = props.selectedFormulas[i].name;

        if (i > -1) {
            newList.splice(i, 1);
        }
        props.setSelectedFormulas(newList)
        props.setListItemsFormulasParsed(newList, removeVal);
    }

    const handleOpenClose = () => {
        setOpen(!open);
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <> 
            <Button
                variant="text"
                style={{
                    whiteSace: 'nowrap',
                    fontSize: '10px',
                    paddingTop: '10px',
                    padding: '0px 0px 0px 0px',
                }}
                onClick={handleClick}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <Popup
                        trigger={
                            <MenuItem>
                                <ListItemIcon>
                                    <EditOutlinedIcon fontSize="medium" />
                                </ListItemIcon>
                                <ListItemText primary="Edit Formula" />
                            </MenuItem>}
                        flowing on="click"
                        position="bottom left">
                        <div className="ui container" style={{ marginLeft: "300px", width: "300px", height: "300px" }}>
                            <div className=" ui item">
                                <Alerts msg={props.msg} type={props.type} openMsg={props.openMsg} setOpenMsg={props.setOpenMsg} />

                                <p style={{ fontWeight: "bold" }}> Formula Name</p>
                                <div class="ui input" style={{ width: "100%" }}>
                                    <input type="text" placeholder="Search..." defaultValue={formulaName} onChange={(e) => handleFormulaNameChange(e)} />
                                </div>
                                <p class="mt-5 " style={{ fontWeight: "bold" }}> Formula </p>
                                <div class="ui input" style={{ width: "100%" }}>
                                    <input type="text" placeholder={"(A/B)/100..."} defaultValue={formula} style={{ height: "100px", width: "100%" }} onChange={(e) => handleFormulaChange(e)} />
                                </div>
                            </div>
                            <div className="ui item mt-3">
                                <button className="ui primary right floated button mr-3" style={{ backgroundColor: "Black", width: "150px", height: "35px" }} onClick={editToFormulas}>
                                    Save Formula
                                </button>
                            </div>
                        </div>
                    </Popup>
                    <MenuItem onClick={() => removeListFormulas(props.index)}>
                        <ListItemIcon>
                            <DeleteOutlineOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Delete Formula" />
                    </MenuItem>
            </Menu>
        </>
    )
};

export default EditFormula;