/*eslint-disable*/
import React, {useEffect, useState, useRef} from 'react'
import { AuthContext } from '../../context/context'
import { Dialog, DialogContent, DialogTitle, Modal, Box, Typography, Card, CardContent,DialogActions, Button, Tooltip} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from '@material-ui/core'
import setUpAssistant from './AssistantHelper'
import { makeStyles } from "@material-ui/styles";
import ChartComponent from './ChartComponent'
import './Assistant.css'
import { addNewBoard, cleanGpt } from '../../context/intuit-service';
import { Icon } from 'semantic-ui-react';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from '@material-ui/core/Chip';
import { dotStream } from 'ldrs'

dotStream.register()

 const useStyles = makeStyles(() => ({
   paper: {
     width: "100%",
     padding: "15px",
     maxWidth: 1300,
     maxHeight: 700,
     height: 'auto',
     margin: "30px auto",
   },   
 }));


const Assistant = ({ assistantModalOpen, assistantModalClose}) => {
    
    const classes = useStyles();

    // set index of hovered option
    const [hover, setHover] = useState(null);
    const [hoverButton, setHoverButton] = useState(false);

    const {
        state: {
            gptData, allBoards, switchUser, selectedBoard, selectedTab, date_change, chartIds
        },
        dispatch
    } = React.useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    // const [backdropOpen, setBackdropOpen] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [prevInput, setPrevInput] = useState("");
    const [showComponent, setShowComponent] = useState(false);
    // because the entire loading and result components are within Assistant, even when we fail to generate on second screen, the config of the previous graph will persist
    const [config, setConfig] = useState(null);
    const [error, setError] = useState("");
    const [open, setOpen] = useState(true);
    const [title, setTitle] = useState("");
    const inputRef = useRef(null);

    const [examples, setExamples] = useState({
      0: "What's the gross profit for Q1, Q2, and Q3 2024 in a line graph?",
      1: "What's the net profit margin for Q1, Q2, Q3, in a bar graph?",
      2: "What's the net income from January to March in a bar graph?",
      3: "What's the net income margin from January to March in a bar graph?",
      4: "What's the total expenses for Q1, Q2, and Q3 in a line graph?",
      5: "What's the total expenses for Q1, Q2, and Q3 in a pie chart?",
      6: "What's the gross profit margin for Q1, Q2, and Q3 in a bar graph?",
      7: "What's my current quick ratio as a bar graph?",
    });


    const errorHandling = (msg) => {
      setError(msg);
    }
    

    const handleSubmit = async () => {
      if (!userInput) {
        return;
      }
      // reset error message
      setError("");
      // make a request to backend to obtain parsed script of chart
      setLoading(true);
      let annotation = await setUpAssistant(
        gptData,
        userInput,
        date_change,
        false,
        null,
        errorHandling,
        (titleName) => setTitle(titleName)
      );
      if (!annotation) {
        setLoading(false);
        setShowComponent(false);
        return;
      }
      console.log(annotation.data);
      setConfig(annotation.data);
      // after setting up assistant, show response
      setLoading(false);
      setShowComponent(true);
      setPrevInput(userInput);
      setUserInput("");
    }

    const handleRegenerate = async () => {
      try {
        await handleSubmit()
      }
      catch (error) {
        console.error(error);
      }
    }

    const handleCloseBackdrop = () => {
      setBackdropOpen(false);
    }

    const addToBoard = async () => {
      console.log(allBoards);
      console.log(switchUser);
      console.log(config);
      const index = allBoards.boards[selectedBoard].tabs[selectedTab].boardData.length;
      const data = {
        boardType: "graph",
        title: title ? title : config.data.datasets[0].title,
        boardView: "graph",
        index: index,
        boardData: [
          {
            config: config,
            query: prevInput
          },
        ],
      };
      // boardId represents all the boards (all the clients we have), client id is the specific board or client we're on
      const boardSchema = {
        boardId: allBoards._id,
        clientId: allBoards.boards[selectedBoard]._id,
        boardName: allBoards.boardName,
        tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id,
        data: data,
      }

      const newClients = await addNewBoard(boardSchema);
      console.log(newClients);

      dispatch({
        type: 'SET_ALLBOARDS',
        payload: newClients.data.data
      });

      console.log(newClients.data.data);

      // const message = await cleanGpt();
      // console.log(message);
      assistantModalClose();
    }

    useEffect(() => {
      console.log(allBoards);
      console.log(switchUser);

      const handleKeyDown = async (event) => {
        if (userInput && event.key === "Enter") {
          await handleSubmit();
        }
      }

      window.addEventListener('keydown', handleKeyDown);

    }, [allBoards])

    useEffect(() => {
      if (assistantModalOpen) {
        const timer = setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0); // Delay to ensure the input is rendered

        return () => clearTimeout(timer); // Clean up the timeout if the component unmounts
      }
    }, [assistantModalOpen])

    useEffect(() => {
      console.log(config);
      if (config && config.options && config.options.plugins && config.options.plugins.title && config.options.plugins.title.text) {
        setTitle(config.options.plugins.title.text);
      }
      else if (config && config.data && config.data.datasets && config.data.datasets[0] && config.data.datasets[0].label) {
        setTitle(config.data.datasets[0].label);
      }
      else {
        setTitle("Finished Graph");
      }
    }, [config])

    const handleClose = async () => {
      // const message = await cleanGpt();
      // console.log(message);
      assistantModalClose();
    }

    const handleClick = (index) => {
      console.log(index);
      setUserInput(examples[index]);
    }

    const handleHover = (index) => {
      setHover(index);
    }

    const handleBackClick = () => {
      setShowComponent(false);
    }

    const handleHoverButton = () => {
      setHoverButton(true);
    }

    const handleHoverOffButton = () => {
      setHoverButton(false);
    }


    return (
      <>
        {loading ? (
          <>
            <Modal open={loading}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                }}
              >
                {/* Default values shown */}
                {/* <l-hatch
                  size="28"
                  stroke="4"
                  speed="3.5" 
                  color="black" 
                ></l-hatch> */}

                <Typography
                  variant="h6"
                  component="h2"
                  style={{ marginRight: "10px", fontWeight: "bold" }}
                >
                  Generating...
                </Typography>
                {/* Default values shown */}
                <l-dot-stream
                  size="60"
                  speed="5.3"
                  color="black"
                ></l-dot-stream>

                {/* <CircularProgress size={24} /> */}
              </Box>
            </Modal>
          </>
        ) : (
          <>
            {showComponent ? (
              <>
                <Dialog
                  open={assistantModalOpen}
                  onClose={handleClose}
                  classes={{ paper: classes.paper }}
                >
                  <DialogTitle>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h1 style={{ display: "inline" }}>{title}</h1>
                      <Tooltip title="close" placement="left-start" arrow>
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={handleClose}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "-20px",
                          }}
                        >
                          <CloseIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    <ChartComponent config={config} chartId={"chart"} />
                  </DialogContent>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      className="input_class"
                      type="text"
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      style={{
                        margin: "10px 16px 10px 16px",
                        width: "70%",
                        borderColor: "#ccc",
                        backgroundColor: "#F5F5F5",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={handleSubmit}
                        onMouseEnter={handleHoverButton}
                        onMouseLeave={handleHoverOffButton}
                        style={{
                          padding: "10px",
                          width: "100%",
                          backgroundColor: "black",
                          color: "white",
                          textAlign: "center",
                          filter: hoverButton ? "brightness(1.5)" : "",
                          transition: "ease-in-out",
                          transitionDuration: "0.2s",
                        }}
                        disabled={userInput ? false : true}
                        className="ui primary button"
                        variant="contained"
                      >
                        <InsightsOutlinedIcon
                          size="small"
                          style={{ marginRight: "5px" }}
                        />
                        Generate
                      </Button>
                    </Box>
                    <DialogActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          onClick={addToBoard}
                          onMouseEnter={handleHoverButton}
                          onMouseLeave={handleHoverOffButton}
                          className="ui primary button"
                          variant="contained"
                          style={{
                            color: "black",
                            transition: "background-color 0.2s ease-in-out",
                            backgroundColor: hoverButton ? "#f0f0f0" : "white",
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "12px",
                            width: "100%",
                          }}
                        >
                          Add to Client Board
                          <Icon
                            name="arrow right"
                            style={{ marginLeft: "5px" }}
                          />
                        </Button>
                      </Box>
                    </DialogActions>
                  </div>
                </Dialog>
              </>
            ) : (
              <>
                <Dialog
                  open={assistantModalOpen}
                  onClose={handleClose}
                  classes={{ paper: classes.paper }}
                >
                  <DialogTitle style={{ padding: "0px 0px" }}>
                    <Box
                      sx={{
                        // display: "grid",
                        // gridTemplateColumns: '3f 1f',
                        // width: '800px'
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <h2
                        style={{
                          display: "inline",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InsightsOutlinedIcon
                          size="large"
                          style={{ marginRight: "5px" }}
                          sx={{ fontSize: 40 }}
                        />
                        Mira &nbsp;
                        <Chip size="small" label="Beta" />
                      </h2>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Tooltip title="Close" placement="left-start" arrow>
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleClose}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-20px",
                            }}
                          >
                            <CloseIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </Box>
                  </DialogTitle>
                  <Box
                    sx={{
                      // display: "flex", // Aligns the child boxes horizontally
                      // flexWrap: "wrap", // Allows wrapping to the next line if necessary
                      // justifyContent: "flex-start", // Aligns items to the start of the flex container
                      // gap: "10px", // Adds space between each card
                      // marginX: "5px",
                      // justifyContent: "space-between",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexGrow: 1,
                      width: "100%",
                      gap: "10px",
                      flexWrap: "wrap",
                      alignItems: "center",
                      margin: "0 auto",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: hover === "0" ? "pointer" : "auto",
                        // flex: "1 1 calc(25% - 50px)", // Allows for responsive resizing (adjust the percentage as needed)
                        boxSizing: "border-box",
                      }}
                      onClick={() => handleClick("0")}
                      onMouseEnter={() => handleHover("0")}
                      onMouseLeave={() => handleHover("")}
                    >
                      <Card
                        sx={{
                          width: "100%", // Ensures the card takes up the full width of its container
                          padding: "20px",
                          marginBottom: "10px",
                        }}
                        style={{
                          height: "211px",
                          width: "148.75px",
                          border: "1px solid rgb(222, 222, 223)",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor:
                            hover === "0" ? "#f4f4f4" : "transparent",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                hover === "0"
                                  ? "rgba(0, 0, 0, 0.87)"
                                  : "rgba(78, 76, 76, 0.87)",
                              fontWeight: "700",
                            }}
                          >
                            What's the{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              gross profit
                            </span>{" "}
                            for{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              Q1, Q2, and Q3
                            </span>{" "}
                            in a{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              line graph
                            </span>
                            ?
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>

                    <Box
                      sx={{
                        cursor: hover === "1" ? "pointer" : "auto",
                        // flex: "1 1 calc(25% - 50px)",
                        boxSizing: "border-box",
                      }}
                      onClick={() => handleClick("1")}
                      onMouseEnter={() => handleHover("1")}
                      onMouseLeave={() => handleHover("")}
                    >
                      <Card
                        sx={{ width: "100%", padding: "20px" }}
                        style={{
                          height: "211px",
                          width: "148.75px",
                          border: "1px solid rgb(222, 222, 223)",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor:
                            hover === "1" ? "#f4f4f4" : "transparent",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                hover === "1"
                                  ? "rgba(0, 0, 0, 0.87)"
                                  : "rgba(78, 76, 76, 0.87)",
                              fontWeight: "700",
                            }}
                          >
                            What's the{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              net profit margin
                            </span>{" "}
                            for{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              Q1, Q2, and Q3
                            </span>{" "}
                            in a{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              bar graph
                            </span>
                            ?
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>

                    <Box
                      sx={{
                        cursor: hover === "2" ? "pointer" : "auto",
                        // flex: "1 1 calc(25% - 50px)",
                        boxSizing: "border-box",
                      }}
                      onClick={() => handleClick("2")}
                      onMouseEnter={() => handleHover("2")}
                      onMouseLeave={() => handleHover("")}
                    >
                      <Card
                        sx={{ width: "100%", padding: "20px" }}
                        style={{
                          height: "211px",
                          width: "148.75px",
                          border: "1px solid rgb(222, 222, 223)",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor:
                            hover === "2" ? "#f4f4f4" : "transparent",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                hover === "2"
                                  ? "rgba(0, 0, 0, 0.87)"
                                  : "rgba(78, 76, 76, 0.87)",
                              fontWeight: "700",
                            }}
                          >
                            What's the{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              net income
                            </span>{" "}
                            from{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              January to March
                            </span>{" "}
                            in a{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              bar graph
                            </span>
                            ?
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>

                    <Box
                      sx={{
                        cursor: hover === "3" ? "pointer" : "auto",
                        // flex: "1 1 calc(25% - 50px)",
                        boxSizing: "border-box",
                      }}
                      onClick={() => handleClick("3")}
                      onMouseEnter={() => handleHover("3")}
                      onMouseLeave={() => handleHover("")}
                    >
                      <Card
                        sx={{ width: "100%", padding: "20px" }}
                        style={{
                          height: "211px",
                          width: "148.75px",
                          border: "1px solid rgb(222, 222, 223)",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor:
                            hover === "3" ? "#f4f4f4" : "transparent",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                hover === "3"
                                  ? "rgba(0, 0, 0, 0.87)"
                                  : "rgba(78, 76, 76, 0.87)",
                              fontWeight: "700",
                            }}
                          >
                            What's the{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              net income margin
                            </span>{" "}
                            from{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              January to March
                            </span>{" "}
                            in an{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              bar graph
                            </span>
                            ?
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      sx={{
                        cursor: hover === "4" ? "pointer" : "auto",
                        // flex: "1 1 calc(25% - 50px)",
                        boxSizing: "border-box",
                      }}
                      onClick={() => handleClick("4")}
                      onMouseEnter={() => handleHover("4")}
                      onMouseLeave={() => handleHover("")}
                    >
                      <Card
                        sx={{ width: "100%", padding: "20px" }}
                        style={{
                          height: "211px",
                          width: "148.75px",
                          border: "1px solid rgb(222, 222, 223)",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor:
                            hover === "4" ? "#f4f4f4" : "transparent",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                hover === "4"
                                  ? "rgba(0, 0, 0, 0.87)"
                                  : "rgba(78, 76, 76, 0.87)",
                              fontWeight: "700",
                            }}
                          >
                            What are the{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              total expenses
                            </span>{" "}
                            for{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              Q1, Q2, and Q3
                            </span>{" "}
                            in an{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              line graph
                            </span>
                            ?
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      sx={{
                        cursor: hover === "5" ? "pointer" : "auto",
                        // flex: "1 1 calc(25% - 50px)",
                        boxSizing: "border-box",
                      }}
                      onClick={() => handleClick("5")}
                      onMouseEnter={() => handleHover("5")}
                      onMouseLeave={() => handleHover("")}
                    >
                      <Card
                        sx={{ width: "100%", padding: "20px" }}
                        style={{
                          height: "211px",
                          width: "148.75px",
                          border: "1px solid rgb(222, 222, 223)",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor:
                            hover === "5" ? "#f4f4f4" : "transparent",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                hover === "5"
                                  ? "rgba(0, 0, 0, 0.87)"
                                  : "rgba(78, 76, 76, 0.87)",
                              fontWeight: "700",
                            }}
                          >
                            What are the{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              total expenses
                            </span>{" "}
                            for{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              Q1, Q2, and Q3
                            </span>{" "}
                            in a{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              {" "}
                              pie chart
                            </span>
                            ?
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      sx={{
                        cursor: hover === "6" ? "pointer" : "auto",
                        // flex: "1 1 calc(25% - 50px)",
                        boxSizing: "border-box",
                      }}
                      onClick={() => handleClick("6")}
                      onMouseEnter={() => handleHover("6")}
                      onMouseLeave={() => handleHover("")}
                    >
                      <Card
                        sx={{ width: "100%", padding: "20px" }}
                        style={{
                          height: "211px",
                          width: "148.75px",
                          border: "1px solid rgb(222, 222, 223)",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor:
                            hover === "6" ? "#f4f4f4" : "transparent",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                hover === "6"
                                  ? "rgba(0, 0, 0, 0.87)"
                                  : "rgba(78, 76, 76, 0.87)",
                              fontWeight: "700",
                            }}
                          >
                            What's the{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              gross profit margin
                            </span>{" "}
                            for{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              Q1, Q2, and Q3
                            </span>{" "}
                            in a{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              bar graph
                            </span>
                            ?
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      sx={{
                        cursor: hover === "7" ? "pointer" : "auto",
                        // flex: "1 1 calc(25% - 50px)",
                        boxSizing: "border-box",
                      }}
                      onClick={() => handleClick("7")}
                      onMouseEnter={() => handleHover("7")}
                      onMouseLeave={() => handleHover("")}
                    >
                      <Card
                        sx={{ width: "100%", padding: "20px" }}
                        style={{
                          height: "211px",
                          width: "148.75px",
                          border: "1px solid rgb(222, 222, 223)",
                          borderRadius: "20px",
                          boxShadow: "none",
                          backgroundColor:
                            hover === "7" ? "#f4f4f4" : "transparent",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            style={{
                              color:
                                hover === "7"
                                  ? "rgba(0, 0, 0, 0.87)"
                                  : "rgba(78, 76, 76, 0.87)",
                              fontWeight: "700",
                            }}
                          >
                            What's the{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              current quick ratio
                            </span>{" "}
                            as a{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                              }}
                            >
                              {" "}
                              bar graph
                            </span>
                            ?
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                  <Box sx={{ marginX: "15px", marginTop: "10px" }}>
                    {error.length > 0 && (
                      <Collapse in={open}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => setOpen(false)}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {error}
                        </Alert>
                      </Collapse>
                    )}
                  </Box>
                  <Box style={{ display: "flex" }}>
                    <DialogContent
                      style={{
                        display: "flex",
                        alignItems: "flex-end", // This makes the items take up the full width of the container
                        justifyContent: "flex-start", // Items will start from the top of the container
                        overflow: "visible",
                        padding: "12px",
                      }}
                    >
                      <input
                        className="input_class"
                        required
                        ref={inputRef}
                        type="text"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        style={{
                          marginTop: "10px",
                          borderColor: "#ccc",
                          backgroundColor: "#F5F5F5",
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={handleSubmit}
                          onMouseEnter={handleHoverButton}
                          onMouseLeave={handleHoverOffButton}
                          style={{
                            padding: "10px",
                            marginTop: "10px",
                            width: "100%",
                            backgroundColor: "black",
                            color: "white",
                            textAlign: "center",
                            filter: hoverButton ? "brightness(1.5)" : "",
                            transition: "ease-in-out",
                            transitionDuration: "0.2s",
                          }}
                          disabled={userInput ? false : true}
                          className="ui primary button"
                          variant="contained"
                        >
                          <InsightsOutlinedIcon
                            size="small"
                            style={{ marginRight: "5px" }}
                          />
                          Generate
                        </Button>
                      </Box>
                    </DialogActions>
                  </Box>
                  <Typography
                    gutterBottom
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "12px",
                        translate: "18px",
                      }}
                    >
                      AI-generated content may be inaccurate or misleading.
                      Always check for accuracy.
                    </p>
                  </Typography>
                </Dialog>
              </>
            )}
          </>
        )}
      </>
    );
}

export default Assistant;


