/* eslint-disable */
import { addCompanyName, myCompany } from "../../src/context/company-service";
import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import React, { useEffect, useState, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import LOGO from '../assets/Dx.png';
import LOGO from '../assets/Dx_logo.png';

// import LOGO from '../assets/dx.svg';
import Profile from '../assets/profile.png';
import { AuthContext } from '../context/context';
import { getTeams } from '../context/team-service';
import CompanyCard from './Stepper/CompanyCard';
import { getCompany } from "../context/fetch-service";
import AppTour from "./AppTour";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    left: '0px !important',
    height: '50px',
    marginLeft: '0px',
    background: '#fff',
    minWidth: "720px"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    color: 'black',
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: 'hidden',
    backgroundColor: 'white',
    color: 'black',
  },
  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    // padding: '10px',
    paddingTop: '50px',
    overflow: 'hidden',
    minWidth: '1000px',
  },
  TopNav: {
    width: '100%',
    marginLeft: '0rem',
    marginRight: '0rem',
    height: '20px',
  },

  Logo: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  MENU: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    color: 'black',
  },
  ProfileIcon: {
    cursor: 'pointer',
    '& img': {
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '50%',
      marginRight: '1.5rem',
      marginTop: "15px"
    },
  },
  lineVertical: {
    opacity: '0.1',
    borderLeft: '1px solid black',
    height: '2.5rem',
    marginLeft: '5px',
    marginRight: '15px',
    marginTop: "10px"
  },
  btn: {
    color: 'black',
    margin: '0 .7rem 0 0',

    '& svg': {
      fontSize: '2rem',
    },
  },
  company: {
    width: '131px',
    marginTop: '15.49px',
    fontWeight: '800',
    whiteSpace: 'wrap',

  },
  companyArrow: {
    width: '21px',
    marginTop: '15.49px',
    fontWeight: '800',
    whiteSpace: 'wrap',

  }

}));

function MenuBar(props) {
  const history = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const {
    state: {
      user, purchasing, teams, companyName
    },
    dispatch,
  } = React.useContext(AuthContext);

  const handleLogout = () => {
    dispatch({
      type: 'LOGOUT',
    });
    localStorage.clear();
    history.push('/login');
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      className={classes.ProfileIcon}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));

  const [accountActive, setAccountActive] = useState(false)


  // const [myTeams, setMyTeams] = React.useState([]);

  const fetchData = async () => {
    const teamMembersResponse = await getTeams();
    // setMyTeams(teamMembersResponse.data.data[0].myTeams ?? []);


    dispatch({
      type: 'SET_TEAMS',
      payload: teamMembersResponse.data.data[0],
    });
  };


  useEffect(async () => {
    if (purchasing[0] && purchasing[0].subscriptionDetails) {
      const endDateTime = purchasing[0].subscriptionDetails.current_period_end;
      const endDate = new Date(endDateTime * 1000);

      const currentDate = new Date();
      if (endDate > currentDate) {
        setAccountActive(true);
      }
    }
    await fetchData();
  }, [purchasing]);


  useEffect(async () => {

    if (teams.myTeams && teams.myTeams.length > 0) {
      setAccountActive(true);
    }
  }, [teams]);

  // useEffect(() => {
  //   getCompanyName();
  // }, [])

  // const getCompanyName = async () => {
  //   const companyDetails = await myCompany();
  //   let name = companyDetails.data.mycompany.businessName;
  //   dispatch({
  //     type: 'SET_COMPANY_NAME',
  //     payload: name
  //   })
  // }


  return (
    <>
      <div>
        <div className='ui menu' style={{
          borderBottom: "0 none !important",
          boxShadow: "none",
          position: "fixed",
          zIndex: "1000",
          width: "100%",
          backgroundColor: "#ffffff"
      
        }}>
          <Box display="flex" flexGrow={3}>
            <div className={[classes.toolbar, classes.Logo].join(' ')}>
              <Link to="/clientperformance">
                <img src={LOGO} alt="Datatrixs-Logo" style={{ width: '40.20px', height: '23px', marginTop: "15.49px"}} />
              </Link>
            </div>
            {companyName && (
              <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div className="ui inline dropdown" style={{ fontWeight: "revert", display: 'flex' }}>
                  <div className={classes.company} >
                    {companyName}
                  </div>
                  <div className={classes.companyArrow}>
                    <ExpandMoreIcon />
                  </div>
                  {/* <div className={classes.companyArrow}>
                  </div> */}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ marginTop: "5px" }} >
                <Dropdown.Item as={Link} to="/company">
                  Update company name
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            )}
            <button className="ui button tiny mr-3 m-3" style={{
              backgroundColor: (location.pathname === "/clientperformance") ? "#FFFFFF" : "white",
              borderBottom: (location.pathname === "/clientperformance") ? "solid 1px red" : "white",
              borderRadius: (location.pathname === "/clientperformance") ? "0" : "0",

            }} >
              <Link to="/clientperformance" style={{ color: "black" }}>Client Performance
              </Link>
            </button>
            <button className="ui button tiny mr-3 m-3" style={{
              backgroundColor: (location.pathname === "/reports") ? "#FFFFFF" : "white",
              borderBottom: (location.pathname === "/reports") ? "solid 1px red" : "white",
              borderRadius: (location.pathname === "/reports") ? "0" : "0",
            }} >
              <Link to="/reports" style={{ color: "black" }}>Reports
              </Link>
            </button>
            {/* <button className="ui button tiny mr-3 mt-3" style={{
              backgroundColor: (location.pathname === "/workstation") ? "#FFFFFF" : "white",
              borderBottom: (location.pathname === "/workstation") ? "solid 1px red" : "white",
              borderRadius: (location.pathname === "/workstation") ? "0" : "0",
            }}>
              <Link to="/workstation" style={{ color: "black" }}>Workstation
              </Link>
            </button> */}
          </Box>
          <Box display="flex" className="fifth-step">
            <AppTour />
          </Box>
          <Box display="flex" style={{ float: 'right' }}>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div className="ui inline dropdown mt-4 pb-2 mr-5" style={{ fontWeight: "revert" }}>
                  <div className="text" style={{ fontWeight: "bold" }} >
                    <img src={user && user.profile ? `${user.profile}` : Profile} alt="user profile circle" />
                    {user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email}
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ marginTop: "0px" }} >
                <Dropdown.Item as={Link} to="/company">
                  Company
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/settings">
                  Settings
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Box>
        </div>

        <main className={classes.content}>{props.children}</main>
      </div>
    </>
  );
}

export default MenuBar;
