import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { FormControl, InputBase, NativeSelect } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import { ButtonGroup, Button } from 'react-bootstrap';

import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import { getInputs } from '../../context/fetch-service';
import { AuthContext } from '../../context/context';
import { getYear, getQuarter, getMonthDetails } from '../../utils/utils';

import MarketingInputs from './MarketingInputs';
import ExpenseInputs from './ExpenseInputs';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f8f9fa',
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function Marketing() {
  const history = useNavigate();
  const classes = useStyles();
  const {
    state: {
      user, purchasing, inputs, data, isAuthenticated, switchUser, currentScenario,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const marketing = inputs.filter((i) => i.title === 'marketing')[0];

  const userSub = purchasing && purchasing.length > 0 ? purchasing.filter((sub) => sub.status === 'active') : [];

  const [chartValue, setChartValue] = React.useState('year');
  const handleChange = (event) => {
    setChartValue(event.target.value);
  };

  const [msg, setMsg] = React.useState('');
  const [err, setErr] = React.useState('');
  const [alertClass, setAlertClass] = React.useState(userSub && userSub.length > 0 && Date.parse(new Date()) < Date.parse(new Date(userSub[0].purchaseDate)) + (userSub[0].planType === 'purchased' ? 30 : 7) * 24 * 60 * 60 * 1000 ? '' : 'show');

  const [csvData, setCsvData] = React.useState('');

  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
    async function fetchRevenue() {
      const inputs = await getInputs(switchUser.userName, currentScenario);
      dispatch({
        type: 'SET_INPUTS',
        payload: inputs,
      });
    }
    fetchRevenue();
  }, [isAuthenticated, history, dispatch]);

  return (
    <div className="row">
      <div className="col-12 col-xl-12">

        <div className="col-8 col-xl-7">
          <h4>Marketing Variables</h4>
          <MarketingInputs marketing={marketing} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />
        </div>
        <div className="col-4 col-xl-5">
          <h4>Major Expense Variables</h4>
          {marketing && marketing._id && <ExpenseInputs marketingId={marketing._id} expenseInputs={marketing.majorExpenseInput} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />}
        </div>
      </div>
    </div>
  );
}

export default Marketing;
