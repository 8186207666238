/* eslint-disable */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import React, {useEffect} from "react";
import { convertToKMB } from "../Helpers/DataHelper";

export default function ReportsTable(props) {
    const columns = props.headers;
    const rows = props.data

    const [colDeltaYearly, setColDeltaYearly] = React.useState(true);
    const [colDeltaCategory, setColDeltaCategory] = React.useState(true);
    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        console.log(columns);
        console.log(rows);
        console.log(colDeltaYearly);
        console.log(colDeltaCategory)
    }, [columns, rows, colDeltaYearly, colDeltaCategory]);

    function percentChange(oldValue, newValue) {
        if (oldValue === 0 || newValue === 0) {
            if (oldValue > newValue) {
                return "-100%"
            } else if (newValue > oldValue) {
                return "+100%"
            } else {
                return "0%";
            }
        }
        if (oldValue === newValue) {
            return "0%";
        }
        var change = (newValue - oldValue) / oldValue * 100;
        if (change < 0) {
            return change.toFixed(2) + "%";
        } else {
            return "+" + change.toFixed(2) + "%";
        }
    }
    const percentChangeColor = (oldValue, newValue) => {
        if (oldValue === 0 || newValue === 0) {
            if (oldValue > newValue) {
                return "-"
            } else if (newValue > oldValue) {
                return "+"
            } else {
                return "";
            }
        }
        if (oldValue === newValue) {
            return "";
        }
        var change = (newValue - oldValue) / oldValue * 100;
        if (change < 0) {
            return "-";
        } else {
            return "+";
        }
    }

    function calculatePercentage(oldValue, newValue) {
        if (oldValue === undefined || newValue === undefined || oldValue === 0 || newValue === 0) {
            return "0%";
        } else {
            let change = (oldValue / newValue) * 100;
            return change.toFixed(2) + "%";
        }
    }

    function setToggle(value) {
        if (value === "Category") {
            setColDeltaCategory(true);
            setColDeltaYearly(false);
        } else if (value === "Monthly") {
            setColDeltaCategory(false);
            setColDeltaYearly(true);
        } else if (value === "Both") {
            setColDeltaCategory(true);
            setColDeltaYearly(true);
        } else {
            setColDeltaCategory(false);
            setColDeltaYearly(false);
        }
    }

    return (
        <div>
            {props.toggleOff ? <></> :

                <div class="ui buttons pb-3">
                    <div class="ui button" style={{ color: (!colDeltaYearly && colDeltaCategory) ? "white" : "black", backgroundColor: (!colDeltaYearly && colDeltaCategory) ? "#220FFE" : "" }} onClick={() => setToggle("Category")}>&#916; Category</div>
                    <div class="ui button" style={{ color: (colDeltaYearly && !colDeltaCategory) ? "white" : "black", backgroundColor: (colDeltaYearly && !colDeltaCategory) ? "#220FFE" : "" }} onClick={() => setToggle("Monthly")}>&#916; Monthly</div>
                    <div class="ui button" style={{ color: (colDeltaYearly && colDeltaCategory) ? "white" : "black", backgroundColor: (colDeltaYearly && colDeltaCategory) ? "#220FFE" : "" }} onClick={() => setToggle("Both")}>&#916; Both</div>
                    <div class="ui button" style={{ color: (!colDeltaYearly && !colDeltaCategory) ? "white" : "black", backgroundColor: (!colDeltaYearly && !colDeltaCategory) ? "#220FFE" : "" }} onClick={() => setToggle("None")}>&#916; None</div>
                </div>
            }
            {columns && rows &&
                <div class="scrolling content">
                    <table className="ui selectable celled table">
                        <thead>
                            <tr>
                                {columns.map((column, i) => (
                                    <>
                                        {i > 1 && colDeltaCategory &&
                                            <th key={i + "header"} >&#916;/C</th>
                                        }
                                        {i > 2 && colDeltaYearly &&
                                            <th key={i + "monthly"} >&#916;/M</th>
                                        }
                                        <th style={{ whiteSpace: 'nowrap' }}
                                            key={i} >{column}</th>
                                    </>

                                ))}
                                {columns.length === 1 &&
                                    <th key={new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}>{new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</th>

                                }
                                {colDeltaCategory &&
                                    <th key={"header"} >&#916;/C</th>
                                }
                                {colDeltaYearly && columns.length > 2 &&
                                    <th key={"monthly"} >&#916;/M</th>
                                }
                                {columns.length > 2 &&
                                    <>
                                        <th key={"Total"} >Total</th>
                                        {colDeltaCategory &&
                                            <th key={"header"} >&#916;/C</th>
                                        }
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {rows && rows.map((row, i) => {
                                return (
                                    <>
                                        <Row toggleValue = {props.toggleValue} rows={rows} row={row} colDeltaCategory={colDeltaCategory} colDeltaYearly={colDeltaYearly} i={i} />
                                        {/* <tr key={i} className={row.header ? "" : ""} style={{ fontWeight: row.header ? "bold" : "" }}>
                                        <td className={row.header ? "" : "pl-5"}>
                                            {row.name}</td>
                                        {row.amount.map((value, i) => {
                                            return (
                                                <>
                                                    <td style={{ color: (value < 0) ? "red" : "" }}>${value}</td>
                                                    {colDeltaCategory &&
                                                        <td style={{ color: "black" }}>
                                                            {calculatePercentage(value, rows[0].amount[i])}
                                                        </td>
                                                    }
                                                    {colDeltaYearly && i > 0 &&
                                                        <td style={{ color: (percentChangeColor(row.amount[i - 1], value,) === "-") ? "red" : "green" }}>
                                                            {percentChange(row.amount[i - 1], value)}
                                                        </td>
                                                    }
                                                </>

                                            )
                                        })}
                                        {row.amount.length > 1 &&
                                            <>
                                                <td>{convertToKMB(row.totalAmount)}</td>
                                                {colDeltaCategory &&
                                                    <td style={{ color: "black" }}>
                                                        {calculatePercentage(row.totalAmount, rows[0].totalAmount)}
                                                    </td>
                                                }
                                            </>
                                        }
                                    </tr> */}
                                        {/* <Collapse in={open} timeout="auto" unmountOnExit>

                                        <tr>
                                            A

                                        </tr>
                                    </Collapse> */}
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

function Row(props) {
    const { toggleValue, rows, row, colDeltaCategory, colDeltaYearly, i } = props;
    useEffect(() => {
        console.log(rows);
    }, [])
    const [open, setOpen] = React.useState(true);
    function percentChange(oldValue, newValue) {
        if (oldValue === 0 || newValue === 0) {
            if (oldValue > newValue) {
                return "-100%"
            } else if (newValue > oldValue) {
                return "+100%"
            } else {
                return "0%";
            }
        }
        if (oldValue === newValue) {
            return "0%";
        }
        var change = (newValue - oldValue) / oldValue * 100;
        if (change < 0) {
            return change.toFixed(2) + "%";
        } else {
            return "+" + change.toFixed(2) + "%";
        }
    }
    const percentChangeColor = (oldValue, newValue) => {
        if (oldValue === 0 || newValue === 0) {
            if (oldValue > newValue) {
                return "-"
            } else if (newValue > oldValue) {
                return "+"
            } else {
                return "";
            }
        }
        if (oldValue === newValue) {
            return "";
        }
        var change = (newValue - oldValue) / oldValue * 100;
        if (change < 0) {
            return "-";
        } else {
            return "+";
        }
    }

    function calculatePercentage(oldValue, newValue) {
        if (oldValue === undefined || newValue === undefined || oldValue === 0 || newValue === 0) {
            return "0%";
        } else {
            let change = (oldValue / newValue) * 100;
            return change.toFixed(2) + "%";
        }
    }
    return (
        <>
            <>
                <tr key={i} className={row.header ? "" : ""} style={{
                    fontWeight: row.header ? "bold" : ""
                }}>
                    <td className={row.header ? "" : "pl-5"}>
                        {row.items && row.items.length >= 1 ?
                            <>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </>
                            :
                            <>
                                {row.items && row.items.length === 0 && row.header &&

                                    <>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpen(!open)}
                                            disabled={true}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </>

                                }

                            </>

                        }

                        {row.name}</td>
                    {row.amount.map((value, i) => {
                        console.log(value);
                        console.log(toggleValue);
                        return (
                          <>
                            <td style={{ color: value < 0 ? "red" : "" }}>
                              {(toggleValue === "Income Statement" ||
                                toggleValue === "Balance Sheet" ||
                                  toggleValue === "Cash Flow"|| toggleValue === "$") &&
                                  `$${value}`}
                                  {toggleValue === '%' && `${value}%`}
                            </td>
                            {colDeltaCategory && (
                              <td style={{ color: "black" }}>
                                {calculatePercentage(value, rows[0].amount[i])}
                              </td>
                            )}
                            {colDeltaYearly && i > 0 && (
                              <td
                                style={{
                                  color:
                                    percentChangeColor(
                                      row.amount[i - 1],
                                      value
                                    ) === "-"
                                      ? "red"
                                      : "green",
                                }}
                              >
                                {percentChange(row.amount[i - 1], value)}
                              </td>
                            )}
                          </>
                        );
                    })}
                    {row.amount.length > 1 && <>
                        <td>{convertToKMB(row.totalAmount)}</td>
                        {colDeltaCategory &&
                            <td style={{ color: "black" }}>
                                {calculatePercentage(row.totalAmount, rows[0].totalAmount)}
                            </td>}</>
                    }
                </tr>
                {row.items &&
                    <>
                        {row.items.map((row, i) => {
                            return (
                                // <Collapse in={open} timeout="auto" unmountOnExit>

                                <tr hidden={open} key={i} className={row.header ? "" : ""} style={{ width: "100%", fontWeight: row.header ? "bold" : "" }}>
                                    <td className={row.header ? "" : "pl-6"}>{row.name}</td>
                                    {row.amount.map((value, i) => {
                                        return (
                                            <>
                                                <td style={{ color: (value < 0) ? "red" : "" }}>${value}</td>
                                                {colDeltaCategory &&
                                                    <td style={{ color: "black" }}>
                                                        {calculatePercentage(value, rows[0].amount[i])}
                                                    </td>
                                                }
                                                {colDeltaYearly && i > 0 &&
                                                    <td style={{ color: (percentChangeColor(row.amount[i - 1], value,) === "-") ? "red" : "green" }}>
                                                        {percentChange(row.amount[i - 1], value)}
                                                    </td>
                                                }
                                            </>

                                        )
                                    })}
                                    {row.amount.length > 1 && <>
                                        <td>{convertToKMB(row.totalAmount)}</td>
                                        {colDeltaCategory &&
                                            <td style={{ color: "black" }}>
                                                {calculatePercentage(row.totalAmount, rows[0].totalAmount)}
                                            </td>}</>
                                    }
                                </tr>
                                // </Collapse>

                            )
                        })}
                    </>

                }
            </>

        </>
    );
}
