/* eslint-disable */

import Button from '@mui/material/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { AuthContext } from '../../context/context';
import { deleteBoard } from '../../context/intuit-service';
import NewBoard from './NewBoard';
import DuplicateClient from './DuplicateClient';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { favoriteBoard } from '../../context/intuit-service';
import { extractPermission } from './helpers';


const EditBoardButton = (props) => {
    const {
        state: {
            user, switchUser, isAuthenticated, access_token, purchasing, projects, allBoards, selectedBoard
        },
        dispatch,
    } = React.useContext(AuthContext);

    console.log(props.board);

    const useStyles = makeStyles(() => ({
        paper: { minWidth: '400px' },
    }));
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const [name, setName] = useState('Board Name');
    const [backDropOpen, setBackDropOpen] = useState(false);
    const handleCloseBackDrop = () => {
        setBackDropOpen(false);
    };
    const handleToggleBackDrop = () => {
        setBackDropOpen(!backDropOpen);
    };

    const handleClick = (event) => {
        console.log('hello');
        setAnchorEl(event.currentTarget);
        props.setEditClicked(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = (event) => {
        console.log('clicked');
        setEditModalOpen(true);
        handleClose();
    };

    const disabled = (allBoards && allBoards.boards &&
        allBoards.boards[selectedBoard] &&
        allBoards.boards[selectedBoard].owner &&
        allBoards.userId)
        ? (allBoards.boards[selectedBoard].owner !== allBoards.userId &&
          (allBoards.boards[selectedBoard].access && extractPermission(allBoards.boards[selectedBoard].access) !== "CAN_MODIFY"))
        : false;
      const handleDateChangeFilter = (name) => {
        let dateValue = handleDateChangeFilterHelper(name)
        setDate(dateValue);
      };

      const favoriteBoardToggle = async (boards) => {
        let favorite = "false"
        if (boards.boards[selectedBoard].favorite === "false") {
          favorite = "true"
        }
        let data = { sharedId: boards.boards[selectedBoard].owner ?? null, boardId: boards._id, favorite: favorite, clientId: allBoards.boards[selectedBoard]._id, boardIdReplace: boards.boards[selectedBoard]._id }
        const updateBoard = await favoriteBoard(data);
        boards.boards[selectedBoard].favorite = favorite;
        dispatch({
          type: 'SET_ALLBOARDS',
          payload: boards
        });
      };
    

    const handleDeleteClick = () => {
        // console.log(props);
        setDeleteModalOpen(true);
        handleClose();
    };

    // const handleNameChange = (event) => {
    //   setNewName(event.target.value);
    //   let data = {
    //     newName: '',
    //     name: '',
    //   }
    // };

    const handleDeleteConfirm = async () => {
        console.log('passed');
        let data = { 
            sharedId: switchUser.userName, 
            // boa rdName: name, 
            boardId: props.boardId, 
            clientId: props.board._id 
        }

        // if (props.board) {
        //     data.boardIdReplace = props.board._id;
        // }
        // delete the board from allBoards
        let filteredBoards = allBoards.boards.filter(board => board._id !== props.board._id);
        console.log(filteredBoards);
        let newBoards = { ...allBoards, boards: filteredBoards }; // Make sure to create a new object to avoid direct state mutation
        console.log(newBoards);
    
        try {
            const delBoard = await deleteBoard(data);
            console.log(delBoard);
            if (delBoard.status === 200) {
                dispatch({
                    type: 'SET_SELECTEDBOARD',
                    payload: 0
                });
                // dispatch({
                //     type: 'SET_ALLBOARDS',
                //     payload: newBoards
                // });
                props.getBoards();
                // we can maybe show something here as it's deleting cause when we delete we make a request
            }
        } catch (error) {
            console.error("Failed to delete the board:", error);
            setMsg("Error deleting board. Please try again."); 
            setType("error"); 
            setOpenMsg(true); 
        }
        setDeleteModalOpen(false);
    };
    

    const handleDeleteCancel = () => {
        setDeleteModalOpen(false);
    };

    const duplicateClient = async () => {
        let boardInfo = {
            boardId: allBoards._id,
            _id: allBoards.boards[selectedBoard]._id,
            duplicateId: props.board._id,
        };
    
        // Check if props.fullBoard.access exists and extract its ID
        if (props.fullBoard && props.fullBoard.access) {
            boardInfo.boardId = extractId(props.fullBoard.access);
        }
    
        try {
            let duplicateBoardResponse = await duplicateBoardItem(boardInfo);
            // Assuming dispatch is properly defined
            dispatch({
                type: 'SET_ALLBOARDS',
                payload: duplicateBoardResponse.data.data
            });
        } catch (error) {
            console.error("Failed to duplicate the board:", error);
            // Update the state to reflect the error
            if (setMsg && setType && setOpenMsg) { // Ensure setMsg, setType, and setOpenMsg are defined
                setMsg("Failed to duplicate board. Please try again.");
                setType("error");
                setOpenMsg(true);
            }
        }
    };
    


    return (
        <div>
            <Button
                variant="text"
                style={{
                    whiteSace: 'nowrap',
                    fontSize: '10px',
                    paddingTop: '10px',
                    padding: '0px 0px 0px 0px',
                }}
                onClick={handleClick}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/* {allBoards &&
                    allBoards.boards &&
                    allBoards.boards.length !== 0 &&
                    selectedBoard < allBoards.boards.length && ( */}
                        <MenuItem
                            className={`${disabled} `}
                            onClick={() => favoriteBoardToggle(allBoards)}
                        >
                            {allBoards.boards[selectedBoard].favorite &&
                                allBoards.boards[selectedBoard].favorite ===
                                "true" ? (
                                <>
                                <ListItemIcon>                                
                                <ArrowCircleDownIcon
                                    fontSize="medium"
                                    // style={{ color: "rgba(0,0,0,.6)" }}
                                />
                                </ListItemIcon>
                                <ListItemText primary="Deprioritize Client" />
                                </>
                            ) : (
                                <>
                                <ListItemIcon>                                
                                  <ArrowCircleUpIcon 
                                      fontSize="medium"
                                  />
                                </ListItemIcon>
                                <ListItemText primary="Prioritize Client" />
                                </>
                            )}
                        </MenuItem>
                    {/* )} */}
                <NewBoard edit={true} board={props.board} boardId={props.boardId} getBoards={props.getBoards} />
                <DuplicateClient edit={false} board={props.board} boardId={props.boardId} getBoards={props.getBoards} />
                <MenuItem onClick={handleDeleteClick}>
                    <ListItemIcon>
                        <DeleteOutlineOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                </MenuItem>
            </Menu>
            <Modal open={deleteModalOpen} onClose={handleDeleteCancel}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '400px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.3)'
                }}>
                    <Typography variant="h6" gutterBottom>
                        Are you sure you want to delete this?
                    </Typography>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button variant="contained" color="primary" style={{ backgroundColor: '#000', color: '#fff' }} onClick={handleDeleteConfirm}>
                            Delete
                        </Button>
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default EditBoardButton;
