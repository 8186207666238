/* eslint-disable */
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// import * as React from 'react';
import React, { useContext, useEffect } from 'react';
import { addCompanyName, myCompany } from '../../context/company-service';
import { CardContentNoPadding } from './CustomStyles';
import StepperStyles from './Styles';
import { AuthContext } from '../../context/context';

export default function CompanyCard(props) {
    // const [companyName, setCompanyName] = React.useState("");

    // function setCompany(e) {
    //     setCompanyName(e.target.value);
    // }

    // async function saveCompanyName() {
    //     let data = {
    //         companyName: companyName
    //     }
    //     let addName = await addCompanyName(data);
    //     props.handleNext();
    // }

    // React.useEffect(async () => {
    //     let companyDetails = await myCompany();
    //     if (companyDetails.data.mycompany.businessName) {
    //         setCompanyName(companyDetails.data.mycompany.businessName);
    //     } else {

    //     }
    // }, []);

    const { state, dispatch } = useContext(AuthContext);
    const { companyName } = state;


    function setCompany(e) {
        dispatch({ type: 'SET_COMPANY_NAME', payload: e.target.value });
    }

    async function saveCompanyName() {
        let data = {
            companyName: state.companyName
        };
        let addName = await addCompanyName(data);
        props.handleNext();
    }

    useEffect(() => {
        async function fetchCompanyDetails() {
            let companyDetails = await getCompany();
            if (companyDetails.data.mycompany.businessName) {
                dispatch({ type: 'SET_COMPANY_NAME', payload: companyDetails.data.mycompany.businessName });
            }
        }
        fetchCompanyDetails();
    }, [dispatch]);


    return (
        <div>
            <Card
                style={{
                    borderRadius: 10,
                    margin: "0 auto"
                }}
                sx={{ width: "1000px" }}>
                <CardContentNoPadding>
                    <Typography
                        style={StepperStyles.header}
                        sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
                        1. Name Your Company
                    </Typography>
                    <div style={{
                        paddingLeft: "5%", paddingRight: "5%"
                    }}>
                        <div className="ui sizer vertical segment pl-3">
                            <p>Company Name</p>
                            <div className="ui input">
                                <input
                                    style={{ paddingBottom: '15px', width: '400px' }}
                                    name="company"
                                    onChange={(e) => setCompany(e)}
                                    label="Company Name"
                                    value={companyName}
                                    type="text" placeholder="Search..." />
                            </div>

                            <p className="pt-2">
                                Don’t worry, you’ll be able to change this and add
                                more company later.
                            </p>
                            <button className="ui right floated primary button mb-4 mr-3" style={{ backgroundColor: "black" }} onClick={() => saveCompanyName()} sx={{ mr: 1 }}>
                                Save & Continue
                            </button>
                        </div>
                    </div>
                </CardContentNoPadding>
            </Card>
        </div>
    );
}

