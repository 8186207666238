/* eslint-disable */

import Input from '@material-ui/core/Input';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IntegrationsButtonAddBoard from '../Projects/IntegrationsButtonAddBoard';
import React, { useState } from 'react';
import { Chart } from "react-google-charts";
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { DatesRangeInput } from 'semantic-ui-calendar-react-17';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AuthContext } from '../../context/context';
import { addNewBoard, getBalanceSheet, getCashFlow, getIncomeStatement } from '../../context/intuit-service';
import Alerts from '../../views/Misc/Alerts';
import { combineArrays, createDateRangeStrings, parseDate, processArray } from '../Workstation/Helpers/QuickbooksParser';
import LoadingTable from '../Workstation/LoadingTable';
import BoardFormulas from './BoardFormulas';
import { convertArray, doFormulas, extractId, getDateRange, setListItemsLine } from './helpers';

const AddBoard = () => {
  const {
    state: {
      integrations, integrationInfo, selectedIntegration, selectedBoard, selectedTab, allBoards: boards,
    }, dispatch
  } = React.useContext(AuthContext);

  // const [boards, setBoard] = React.useState([])
  const [date, setDate] = React.useState(getDateRange());
  const [date2, setDate2] = React.useState(getDateRange());
  const [isChecked, setIsChecked] = React.useState(false);
  const handleCheckboxChange = (event) => { setIsChecked(event.target.checked); }
  const handleDateChange = (event, { name, value }) => { setDate(value); };
  const handleDateChange2 = (event, { name, value }) => { setDate2(value); };
  const [toggleValue, setToggleValue] = React.useState("$");
  const [toggleValueType, setToggleValueType] = React.useState("bar");


  React.useEffect(() => {
    let format;
    if (toggleValue === '$') {
      format = 'currency';  // or a more specific currency format string
    } else if (toggleValue === '%') {
      format = '#\'%\'';  
    }
    setCurrentView(format);
    
    // Update the chartOptions state with the new format
    setChartOptions(prevOptions => ({
      ...prevOptions,
      vAxis: { ...prevOptions.vAxis, format },
      hAxis: { ...prevOptions.hAxis, format },
    }));
    setLineOptions(prevOptions => ({
      ...prevOptions,
      vAxis: { ...prevOptions.vAxis, format: format }
    }));
  }, [toggleValue]);

  const [chartOptions, setChartOptions] = useState({
    title: "",
    vAxis: { format: 'currency' },
    hAxis: { format: 'currency' },
  });
  
  const [lineOptions, setLineOptions] = useState({
    title: "",
    legend: {
      position: 'top'
    },
    chartArea: { width: '70%' },
    hAxis: {
      title: 'Years',
      format: ''  // Assuming you want this to be blank or some default format
    },
    vAxis: {
      format: 'percent'  // Default format
    },
    crosshair: { 
      trigger: 'both',
      orientation: 'vertical'
    },
    // explorer: {
    //   zoomDelta: 1.8,
    //   maxZoomOut: 4,
    //   maxZoomIn: 0.5,
    //   keepInBounds: true,
    // },
  });


  const handleTitleClick = () => {
    setEditingTitle(true);
  };

  const handleTitleBlur = () => {
    setEditingTitle(false);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const saveTitle = () => {
    // Implement saving logic here
    setEditingTitle(false);
  };

  

  React.useEffect(async () => {
    await getLiveData();
  }, [])

  const [balanceSheet, setBalanceSheet] = React.useState([null]);
  const [cashFlow, setCashFlow] = React.useState(null);
  const [incomeStatement, setIncomeStatement] = React.useState(null);
  const [balanceSheet2, setBalanceSheet2] = React.useState([]);
  const [cashFlow2, setCashFlow2] = React.useState([]);
  const [incomeStatement2, setIncomeStatement2] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [sankeyData, setSankeyData] = React.useState([]);
  const [data2, setData2] = React.useState([]);
  const [sankeyData2, setSankeyData2] = React.useState([]);
  const [balanceSheetTable, setBalanceSheetTable] = React.useState(null);
  const [cashFlowTable, setCashFlowTable] = React.useState(null);
  const [incomeStatementTable, setIncomeStatementTable] = React.useState(null);
  const [balanceSheetTable2, setBalanceSheetTable2] = React.useState(null);
  const [cashFlowTable2, setCashFlowTable2] = React.useState(null);
  const [incomeStatementTable2, setIncomeStatementTable2] = React.useState(null);
  const [tableHeaders, setTableHeaders] = React.useState([]);
  const [tableHeaders2, setTableHeaders2] = React.useState([]);
  const [dataTable, setDataTable] = React.useState([]);
  const [dataTableCompare, setDataTableCompare] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [x, setX] = React.useState(true);
  
  const [currentView, setCurrentView] = React.useState("Income Statement");
  const [loadingIncome, setLoadingIncome] = React.useState(true);
  const [loadingBalance, setLoadingBalance] = React.useState(true);
  const [loadingCashflow, setLoadingCashflow] = React.useState(true);


 const [mergedDataTables, setMergedDataTables] = React.useState([]);
 const [indexTable, setIndexTable] = React.useState([]);

  function getLiveData() {
    try {
      setToggleValue("$");
      setLoading(true);
      let query = parseDate(date);
      let data = {
        realmId: integrations.quickbooks.access.realmId,
        access_token: integrations.quickbooks.access.token.access_token,
        refresh_token: integrations.quickbooks.access.token.refresh_token,
        singleQuery: query,
        query: [query],
        compare: true,
      };

      let ranges = [parseDate(date), parseDate(date2)];

      if (!isChecked) {
        let [startDateString, endDateString] = date.split(" - ");
        ranges = createDateRangeStrings(startDateString, endDateString);
      }

      let months = [];
      months = ranges.map(dateRangeStr => {
        let [, startDateStr, endDateStr] = dateRangeStr.match(/start_date=(.*)&end_date=(.*)/) || [];
        let startDate = new Date(startDateStr);
        let endDate = new Date(endDateStr);
        return endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
      });
      months.unshift("Title");
      setTableHeaders(months);

      let IncomeStatement, IncomeStatement2, BalanceSheet, BalanceSheet2, CashFlow, CashFlow2;

  
      if (isChecked) {
        data.compare = true;
        setLoadingIncome(true);
        setLoadingBalance(true);
        setLoadingCashflow(true);
        data.query = [ranges[0]];

        getIncomeStatement(data)
          .then((response) => {
            IncomeStatement = response.data.data;
            data.query = [ranges[1]];
            return getIncomeStatement(data);
          })
          .then((response) => {
            IncomeStatement2 = response.data.data;

            let combinedIncome = processArray(combineArrays(IncomeStatement.data.table, IncomeStatement2.data.table, 1));
            setIncomeStatement(IncomeStatement.data.data);
            setIncomeStatement2(IncomeStatement2.data.data);
            setIncomeStatementTable2(combinedIncome);

            if (currentView === "Income Statement") {
              setDataTableCompare(combinedIncome);
              setLoadingIncome(false);
            }

            data.query = [ranges[0]];
            return getBalanceSheet(data);
          })
          .then((response) => {
            data.query = [ranges[1]];
            return getBalanceSheet(data);
          })
          .then((response) => {
            BalanceSheet = response.data.data;
            data.query = [ranges[1]];
            return getBalanceSheet(data);
          })
          .then((response) => {
            BalanceSheet2 = response.data.data;

            let combinedBalance = processArray(combineArrays(BalanceSheet.data.table, BalanceSheet2.data.table, 1));
            setBalanceSheet(BalanceSheet.data.data);
            setBalanceSheet2(BalanceSheet2.data.data);
            setBalanceSheetTable2(combinedBalance);

            if (currentView === "Balance Sheet") {
              setDataTableCompare(combinedBalance);
              setLoadingBalance(false);
            }

            data.query = [ranges[0]];
            return getCashFlow(data);
          })
          .then((response) => {
            data.query = [ranges[1]];
            return getCashFlow(data);
          })
          .then((response) => {
            CashFlow = response.data.data;
            data.query = [ranges[1]];
            return getCashFlow(data);
          })
          .then((response) => {
            CashFlow2 = response.data.data;

            let combinedCashflow = processArray(combineArrays(CashFlow.data.table, CashFlow2.data.table, 1));
            setCashFlow(CashFlow.data.data);
            setCashFlow2(CashFlow2.data.data);
            setCashFlowTable2(combinedCashflow);

            if (currentView === "Cash Flow") {
              setDataTableCompare(combinedCashflow);
              setLoadingCashflow(false);
            }

            setTableHeaders2(["Title", date, date2]);

            setLoadingIncome(false);
            setLoadingBalance(false);
            setLoadingCashflow(false);
            setLoading(false);

            const newArray = [
              ["Category", "Amount"],
            ];
            for (let i = 0; i < dataTable.length; i++) {
              const tempArray = [dataTable[i].name, dataTable[i].totalAmount];
              newArray.push(tempArray);
            }
            setParsedDataTablePie(newArray);

            const lineArray = [
              ["Title", "Amount"],
            ];
            for (let i = 0; i < dataTable.length; i++) {
              const tempArray = [dataTable[i].name, dataTable[i].totalAmount];
              lineArray.push(tempArray);
            }
            // setParsedDataTableLine(lineArray);
          })
          .catch((error) => {
            console.error("Error while fetching data:", error);
            setLoading(false);
            setLoadingIncome(false);
            setLoadingBalance(false);
            setLoadingCashflow(false);
          });
      } else {
        data.query = ranges;
        setLoadingIncome(true);

        async function fetchData() {
          try {
            const [incomeResponse, balanceResponse, cashFlowResponse] = await Promise.all([
              getIncomeStatement(data),
              getBalanceSheet(data),
              getCashFlow(data)
            ]);

            const IncomeStatement = incomeResponse;
            const BalanceSheet = balanceResponse;
            const CashFlow = cashFlowResponse;

            setIncomeStatement(IncomeStatement.data.data);
            setIncomeStatementTable(IncomeStatement.data.table);

            setBalanceSheet(BalanceSheet.data.data);
            setBalanceSheetTable(BalanceSheet.data.table);

            setCashFlow(CashFlow.data.data);
            setCashFlowTable(CashFlow.data.table);

            const newArray = [["Category", "Amount"]];
            for (let i = 0; i < dataTable.length; i++) {
              const tempArray = [dataTable[i].name, dataTable[i].totalAmount];
              newArray.push(tempArray);
            }
            setParsedDataTablePie(newArray);

            const lineArray = [["Title", "Amount"]];
            for (let i = 0; i < dataTable.length; i++) {
              const tempArray = [dataTable[i].name, dataTable[i].totalAmount];
              lineArray.push(tempArray);
            }
            // setParsedDataTableLine(lineArray);
          } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false);
            setLoadingIncome(false);
            setLoadingBalance(false);
            setLoadingCashflow(false);
          }
        }
        fetchData();

      }
    } catch {
      setLoading(false);
      setLoadingIncome(false);
      setLoadingBalance(false);
      setLoadingCashflow(false);
    }
  }

  //counting number of flattened objects
  function countTotalObjects(combinedData) {
    let totalCount = 0;
  
    combinedData.forEach(entry => {
      if (entry.items && entry.items.length > 0) {
        totalCount += entry.items.length;
        totalCount += 1;
      } else {
        totalCount += 1;
      }
    });
  
    return totalCount;
  }

  React.useEffect(() => {

    const combinedData = [];
    const indices = [];
    let len1, len2, len3;
    if (incomeStatementTable) combinedData.push(...incomeStatementTable);
    if (balanceSheetTable) combinedData.push(...balanceSheetTable);
    if (cashFlowTable) combinedData.push(...cashFlowTable);

    if (incomeStatementTable) len1 = countTotalObjects(incomeStatementTable);
    if (balanceSheetTable) len2 = countTotalObjects(balanceSheetTable);
    if (cashFlowTable) len3 = countTotalObjects(cashFlowTable);

    if (combinedData.length > 0) {
      const refactoredData = combinedData.flatMap(entry => [
        {
          ...entry,
          description: entry.name,
          header: true,
        },
        ...(entry.items?.length > 0
          ? entry.items.map(item => ({
              ...item,
              description: entry.name,
              header: true,
          }))
          : [])
      ]);
      setMergedDataTables(refactoredData);
    }

    indices.push([0, len1]);
    indices.push([len1, len1 + len2]);
    indices.push([len1 + len2, len1 + len2 + len3]);
    setIndexTable(indices);

  }, [incomeStatementTable, balanceSheetTable, cashFlowTable]); 

  React.useEffect(() => {

    const newArray = [["Category", "Amount"]];
    for (let i = 0; i < mergedDataTables.length; i++) {
      const tempArray = [mergedDataTables[i].name, mergedDataTables[i].totalAmount];
      newArray.push(tempArray);
    }
    setParsedDataTablePie(newArray);

    const lineArray = [["Title", "Amount"]];
    for (let i = 0; i < dataTable.length; i++) {
      const tempArray = [dataTable[i].name, dataTable[i].totalAmount];
      lineArray.push(tempArray);
    }
    setParsedDataTableLine(lineArray);

    setData(mergedDataTables);
    setSankeyData(mergedDataTables);
    setDataTable(mergedDataTables);
    setParsedDataTable(mergedDataTables);

    setLoading(false);
    setLoadingIncome(false);
    setLoadingBalance(false);
    setLoadingCashflow(false);

  }, [incomeStatementTable, balanceSheetTable, cashFlowTable]); 



  function setCurrentViewPage(value) {
    setCurrentView(value);
    setToggleValue("$")
    // if (value === "Income Statement") {
    //   setData(incomeStatement);
    //   setSankeyData(incomeStatement);
    //   setData2(incomeStatement2);
    //   setSankeyData2(incomeStatement2);
    //   setDataTable(incomeStatementTable)
    //   setDataTableCompare(incomeStatementTable2)

    //   setListItems([]);
    //   setListItemsFormulas([]);
    //   setParsedDataTable(incomeStatementTable);
    //   setParsedDataTablePie([]);
    //   setParsedDataTableLine([])
    // } else if (value === "Balance Sheet") {
    //   setData(balanceSheet);
    //   setSankeyData(balanceSheet);
    //   setData2(balanceSheet2);
    //   setSankeyData2(balanceSheet2);
    //   setDataTable(balanceSheetTable)
    //   setDataTableCompare(balanceSheetTable2)
    //   setListItems([]);
    //   setListItemsFormulas([]);
    //   setParsedDataTable(balanceSheetTable);
    //   setParsedDataTablePie([]);
    //   setParsedDataTableLine([])
    // } else if (value === "Cash Flow") {
    //   setData(cashFlow);
    //   setSankeyData(cashFlow);
    //   setData2(cashFlow2);
    //   setSankeyData2(cashFlow2);
    //   setDataTable(cashFlowTable)
    //   setDataTableCompare(cashFlowTable2)

    //   setListItems([]);
    //   setListItemsFormulas([]);
    //   setParsedDataTable(cashFlowTable);
    //   setParsedDataTablePie([]);
    //   setParsedDataTableLine([])
    // }
  }

  function toggleSign(value) {
    if (value === "%") {
      setToggleValue("%");
    } else {
      setToggleValue("$");
    }
  }

  function toggleSignType(value, data, boardData, listItems, dataTable, parsedDataTableLine, parsedDataTablePie) {
    if (value === "table") {
      setToggleValueType("table");
    } else if (value === "line") {
      setToggleValueType("line");
    } else if (value === "bar") {
      setToggleValueType("bar");
    } else if (value === "pie") {
      setToggleValueType("pie");

      const filteredArr2 = dataTable.filter(item2 => {
        return listItems.some(item1 => item1.name === item2.name);
      });
      const newArray = [
        ["Category", "Amount"],
      ];
      for (let i = 0; i < filteredArr2.length; i++) {
        const tempArray = [filteredArr2[i].name, Math.abs(filteredArr2[i].totalAmount)];
        newArray.push(tempArray);
      }
      setParsedDataTablePie(newArray);
    } else if (value === "hash") {
      setToggleValueType("hash");
    }
  }

  const [listItems, setListItems] = React.useState([]);
  const [listItemsFormulas, setListItemsFormulas] = React.useState([]);

  const [parsedDataTable, setParsedDataTable] = React.useState(dataTable);
  const [parsedDataTablePie, setParsedDataTablePie] = React.useState([]);
  const [parsedDataTableLine, setParsedDataTableLine] = React.useState([]);

  React.useEffect(() => {
    let lineGraph = setListItemsLine(parsedDataTable, listItems, listItemsFormulas, tableHeaders)
    setParsedDataTableLine(lineGraph);

    const newArray = [
      ["Category", "Amount"],
    ];
    for (let i = 0; i < parsedDataTable.length; i++) {
      // const tempArray = [parsedDataTable[i].name, Math.abs(parsedDataTable[i].amount[parsedDataTable[i].amount.length - 1])];
      const tempArray = [parsedDataTable[i].name, Math.abs(parsedDataTable[i].totalAmount)];

      // const tempArray = [filteredArr2[i].name, Math.abs(filteredArr2[i].totalAmount)];
      newArray.push(tempArray);
    }
    setParsedDataTablePie(newArray);

  }, [parsedDataTable, listItems, listItemsFormulas, tableHeaders, dataTable])

  React.useEffect(() => {
    if (parsedDataTable !== null && parsedDataTable.length > 0) {
      setX(false);
    }
  }, [parsedDataTable, ])

  const setListItemsParsed = (data, initDataTable, formulas = listItemsFormulas) => {
    setListItems(data);
    let filteredArr2;

    if (initDataTable) {
      if (data.length <= 0) {
        filteredArr2 = initDataTable
      } else {
        filteredArr2 = initDataTable.filter(item2 => {
          let match = data.find(item1 => item1.name === item2.name && item1.hidden === false);
          return Boolean(match);  // Only include if a match is found and hidden is not true
        });
        let filterredDataTable = initDataTable.filter(item2 => {
          return listItemsFormulas.some(item => item.name === item2.name);
        });

        filteredArr2 = [...filteredArr2, ...filterredDataTable];
      }

    } else {
      if (data.length <= 0) {
        filteredArr2 = dataTable
      } else {
        filteredArr2 = dataTable.filter(item2 => {
          let match = data.find(item1 => item1.name === item2.name && item1.hidden === false);
          return Boolean(match);  // Only include if a match is found and hidden is not true
        });

        let filterredDataTable = parsedDataTable.filter(item2 => {
          return listItemsFormulas.some(item => item.name === item2.name);
        });

        filteredArr2 = [...filteredArr2, ...filterredDataTable];
      }
    }


    setParsedDataTable(filteredArr2);

    /// Pie Chart
    const newArray = [
      ["Category", "Amount"],
    ];
    for (let i = 0; i < filteredArr2.length; i++) {
      const tempArray = [filteredArr2[i].name, Math.abs(filteredArr2[i].totalAmount)];
      // const tempArray = [filteredArr2[i].name, Math.abs(filteredArr2[i].totalAmount)];
      newArray.push(tempArray);
    }
    setParsedDataTablePie(newArray);

    setListItemsFormulasParsed(formulas, null, filteredArr2, newArray, data)
  }

  const setListItemsFormulasParsed = (data, removeValue, table = parsedDataTable, pie = parsedDataTablePie, boardData = listItems) => {
    if (removeValue) {

      let values = removeItemByCategory(parsedDataTablePie, removeValue);
      let valuesParsed = removeItemByCategoryTable(parsedDataTable, removeValue);
      setListItemsFormulas(data);
      setParsedDataTablePie(values);
      setParsedDataTable(valuesParsed);
    } else {
      setListItemsFormulas(data);
      let formulas = doFormulas(boardData, data);
      let dataTableValues = convertArray(formulas);
      const result = formulas.map(item => [item.name, item.totalAmount]);
      const existingCategories = new Set(pie.map(item => item[0]));
      let resultArray = [...pie]; // Create a copy of parsedDataTablePie

      for (const item of result) {
        const category = item[0];
        if (!existingCategories.has(category)) {
          resultArray.push(item);
          // setParsedDataTable([...table, ...dataTableValues])
        } else {
          const existingIndex = resultArray.findIndex((existingItem) => existingItem[0] === category);
          if (existingIndex !== -1) {
            // Replace the existing item with the new item
            resultArray[existingIndex] = item;
            // Alternatively, if you want to replace only specific elements, you can use array destructuring like this:
            // resultArray[existingIndex] = [...item];
          }
        }
      }

      let combinedPie = resultArray;
      setParsedDataTablePie(combinedPie);

      const result2 = dataTableValues.map(item => item);
      let resultArray2 = [...table]; // Create a copy of array1
      for (const item of result2) {


        const category = item.name;
        if (!existingCategories.has(category)) {
          resultArray2.push(item);
        } else {
          const existingIndex = resultArray2.findIndex((existingItem) => existingItem.name === category);
          if (existingIndex !== -1) {
            // Replace the existing item with the new item
            resultArray2[existingIndex] = item;
            // Alternatively, if you want to replace only specific elements, you can use array destructuring like this:
            // resultArray[existingIndex] = [...item];
          }
        }
      }
      setParsedDataTable(resultArray2)

    }
  };


  function removeItemByCategory(array, categoryToRemove) {
    const indexToRemove = array.findIndex(item => item[0] === categoryToRemove);
    let newArray = array;
    if (indexToRemove !== -1) {
      newArray.splice(indexToRemove, 1);
    }
    return newArray;
  }

  function removeItemByCategoryTable(array, categoryToRemove) {


    const indexToRemove = array.findIndex(item => item.name === categoryToRemove);
    let newArray = array;
    if (indexToRemove !== -1) {
      newArray.splice(indexToRemove, 1);
    }
    return newArray;
  }



  const [saving, setSaving] = useState(false)
  const [msg, setMsg] = React.useState("")
  const [type, setType] = React.useState('warning');
  const [openMsg, setOpenMsg] = React.useState(false);

  const saveBoard = async () => {
    setOpenMsg(false)
    setSaving(true);

    let boardInfo = {
      clientId: boards.boards[selectedBoard]._id,
      boardId: boards._id,
      tabId: boards.boards[selectedBoard].tabs[selectedTab]._id, // Include tabId in the payload
      data: {
        index: boards.boards[selectedBoard].tabs[selectedTab].boardData ? boards.boards[selectedBoard].tabs[selectedTab].boardData.length : 0,
        boardView: currentView,
        boardType: toggleValueType,
        boardData: listItems,
        boardFormulas: listItemsFormulas,
        date: date,
        title: title,
      }
    };
    console.log(boardInfo);

    if (boards.boards[selectedBoard].access) {
      boardInfo.boardId = extractId(boards.boards[selectedBoard].access);
    }


    let addNewBoardResponse = await addNewBoard(boardInfo);
    if (addNewBoardResponse.status === 200) {
      setMsg(`Board Saved`)
      setType("success")
      setOpenMsg(true)
    } else {
      setMsg(`Error Saving Board`)
      setType("warning")
      setOpenMsg(true)
    }

    setSaving(false);
  }

  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState("Untitled");

  return (
    <>
      <div className="pl-5 pt-3 pb-3" style={{ height: "100%", minHeight: "1500px" }}>
        <div className="row mr-3">
          <div className="col-sm-12" style={{ minWidth: "235px" }}>
            <div class="ui menu" style={{
              borderBottom: "0 none !important",
              boxShadow: "none"
            }}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <button
                    className="ui primary button"
                    style={{ backgroundColor: 'White', padding: '0px 10px 0px 0px' }}
                    onClick={() => console.log()}
                  >
                    <Link to="/clientperformance">
                      <IconButton>
                        <ArrowBackIcon />
                      </IconButton>
                    </Link>
                  </button>
                  <Typography sx={{ fontSize: 15 }} style={{ paddingTop: "0px" }} color="text.secondary" gutterBottom>
                      <IntegrationsButtonAddBoard />
                  </Typography>
                  {/* <Typography
                    sx={{ fontSize: 18, color: 'black' }}
                    color="text.primary"
                    gutterBottom
                  >
                    {integrationInfo.integrations[selectedIntegration].companyName}
                  </Typography> */}
                  {boards &&
                    boards.boards &&
                    boards.boards.length !== 0 &&
                    boards.boards[selectedBoard].tabs.length!==0 &&
                    selectedTab < boards.boards[selectedBoard].tabs.length && (
                      <>
                        <Typography
                          sx={{ fontSize: 18, color: 'black' }}
                          color="text.primary"
                          gutterBottom
                        >
                          / {boards.boards[selectedBoard].boardName}
                          &nbsp; /
                        </Typography>
                        <div style={{ marginBottom: "10px" }}>
                          {editingTitle ? (
                            <div className="ml-2">
                              &nbsp;
                              <Input
                                value={title}
                                onChange={handleTitleChange}
                                onBlur={handleTitleBlur}
                                autoFocus
                                sx={{ fontSize: 18, color: 'black' }}
                              />
                              <Button onClick={saveTitle} color="primary" style={{ color: "Black" }}>
                                Save
                              </Button>
                            </div>
                          ) : (
                            <Typography
                              sx={{ fontSize: 18, color: 'black' }}
                              color="text.primary"
                              gutterBottom
                              onClick={handleTitleClick}
                            >
                              &nbsp;{title}
                              <i class="ml-3 pencil alternate icon"></i>

                            </Typography>
                          )}
                        </div>
                      </>
                    )}
                </div>

              </div>
            </div>
            {/* <hr style={{ color: "#DEDEDF" }} /> */}
            <div className="row" style={{ display: 'grid', gridTemplateColumns: '1fr 5fr' }}>
              <div className="">
                <BoardFormulas
                  indices={indexTable}
                  dataTable={mergedDataTables}
                  setCurrentViewPage={setCurrentViewPage}
                  setListItemsParsed={setListItemsParsed}
                  setListItemsFormulasParsed={setListItemsFormulasParsed}
                  loadingIncome={loadingIncome}
                  loadingBalance={loadingBalance}
                  loadingCashflow={loadingCashflow}
                />
              </div>
              <div className="">

                <div className="row" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 4fr .5fr .5fr' }}>
                  {/* <div class="ui toggle checkbox mt-3">
                    <input type="checkbox" name="public" checked={isChecked} onChange={handleCheckboxChange} />
                    <label>Compare With</label>
                  </div> */}
                  <DatesRangeInput name="datesRange" placeholder="From - To" iconPosition="left" value={date} dateFormat="YYYY-MM-DD" onChange={handleDateChange} style={{ width: "250px" }}
                  />
                  {/* {isChecked &&
                    <DatesRangeInput name="datesRange" placeholder="From - To" iconPosition="left" value={date2} dateFormat="YYYY-MM-DD" onChange={handleDateChange2} style={{ width: "250px" }}
                    />
                  } */}
                  <button className="ui primary button mr-3" style={{ backgroundColor: "Black", width: "100px", height: "35px" }} onClick={() => getLiveData()}>
                    Apply
                  </button>
                  <div>

                  </div>
                <button class={`ui primary button mr-3 ${loading ? "loading" : ""}`}
                  style={{ backgroundColor: "black", color: "white", width: "100px", height: "35px"  }}
                  disabled={saving}>
                  <Link to="/clientperformance" style={{ textDecoration: "none", color: "white" }}>
                    Back
                  </Link>
                </button>
                <button class={`ui primary button mr-3 ${loading ? "loading" : ""}`}
                  style={{ backgroundColor: "black", color: "white", width: "100px", height: "35px"  }}
                  onClick={saveBoard}

                  disabled={saving}>
                  Save
                </button>
                
                </div>
                <Alerts msg={msg} type={type} openMsg={openMsg} setOpenMsg={setOpenMsg} />

                <hr style={{ color: "#DEDEDF" }} />
                <div className="row mb-3" style={{ display: 'grid', gridTemplateColumns: '4fr 1fr' }}>
                  <div style={{ background: 'white', border: ' 1px transparent #000000', borderRadius: '15px' }}>
                    <div class="ui buttons pb-3">
                      <div class="ui button" style={{ color: (toggleValue === "$") ? "white" : "black", backgroundColor: (toggleValue === "$") ? "#220FFE" : "" }}
                        onClick={() => toggleSign("$")}>$</div>
                      <div class="ui button" style={{ color: (toggleValue === "%") ? "white" : "black", backgroundColor: (toggleValue === "%") ? "#220FFE" : "" }}
                        onClick={() => toggleSign("%")}>%</div>
                    </div>
                  </div>
                  <div style={{
                    background: 'white', width: '100%', height: '85%', border: ' 1px transparent #000000', borderRadius: '15px',
                    maringLeft: "100%"
                  }}>
                    <div class="ui buttons pb-3">
                      <div class="ui button" style={{ color: (toggleValueType === "table") ? "white" : "black", backgroundColor: (toggleValueType === "table") ? "#220FFE" : "" }}
                        onClick={() => toggleSignType("table")}>
                        <i className="table icon"></i>

                      </div>
                      <div class="ui button" style={{ color: (toggleValueType === "pie") ? "white" : "black", backgroundColor: (toggleValueType === "pie") ? "#220FFE" : "" }}
                        onClick={() => toggleSignType("pie")}>
                        <i className="chart pie icon"></i>

                      </div>
                      <div class="ui button" style={{ color: (toggleValueType === "line") ? "white" : "black", backgroundColor: (toggleValueType === "line") ? "#220FFE" : "" }}
                        onClick={() => toggleSignType("line")}>
                        <i className="chart line icon"></i>
                      </div>
                      <div class="ui button" style={{ color: (toggleValueType === "bar") ? "white" : "black", backgroundColor: (toggleValueType === "bar") ? "#220FFE" : "" }}
                        onClick={() => toggleSignType("bar")}>
                        <i className="chart bar icon"></i>
                      </div>
                      <div class="ui button" style={{ color: (toggleValueType === "hash") ? "white" : "black", backgroundColor: (toggleValueType === "hash") ? "#220FFE" : "" }}
                        onClick={() => toggleSignType("hash")}>
                        <i className="hashtag icon"></i>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="row mb-3" style={{ width: "100%", height: "100%" }}>
                  {x ?(
                      // Show loading Sankey-like chart while data is loading
                      <div className="loading-sankey-chart">
                        <div className="loading-node" />
                        <div className="loading-node" />
                        <div className="loading-node" />
                        <div className="loading-link" />
                        <div className="loading-link" />
                        <div className="loading-link" />
                      </div>
                  ):
                      <>
                        {toggleValueType === 'bar' &&
                          <Chart
                            chartType="BarChart"
                            data={parsedDataTablePie}
                            options={chartOptions}
                            width={"100%"}
                            height={"400px"}
                          />
                        }
                        {toggleValueType === 'line' &&
                          <Chart
                            chartType="AreaChart"
                            data={parsedDataTableLine}
                            options={lineOptions}
                            width={"100%"}
                            height={"400px"}
                          />
                        }
                        {toggleValueType === 'pie' &&
                          <Chart
                            chartType="PieChart"
                            data={parsedDataTablePie}
                            options={chartOptions}
                            width={"100%"}
                            height={"400px"}
                          />
                        }
                        {toggleValueType === 'hash' &&
                          <p> Coming Soon! </p>
                        }
                      </>
                    }
                  </div>
                  <>
                    <div className="row" style={{ width: 500, height: "100%", overflowX: "scroll", paddingLeft: 0, }}>

                      <LoadingTable
                        loading={loading}
                        currentView={toggleValue}
                        data={parsedDataTable}
                        headers={tableHeaders}
                        isChecked={isChecked}
                        compareData={dataTableCompare} // Replace with your actual compare data
                        compareHeaders={tableHeaders2}
                      />

                      {/* <ReportsTable data={parsedDataTable} headers={tableHeaders} toggleOff={true} /> */}
                    </div>
                  </>
                  
                </div>
                <button class={`ui button secondary right floated mt-5 mr-4 ${loading ? "loading" : ""}`}
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={saveBoard}

                  disabled={saving}>
                  Save
                </button>
                <button class={`ui button secondary right floated mt-5 mr-4 ${loading ? "loading" : ""}`}
                  style={{ backgroundColor: "black", color: "white" }}
                  disabled={saving}>
                  <Link to="/clientperformance" style={{ textDecoration: "none", color: "white" }}>
                    Back
                  </Link>
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  );
};

export default AddBoard;