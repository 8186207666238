/* eslint-disable */

// This code is for v4 of the openai package: npmjs.com/package/openai
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: "sk-proj-GPCLdXllynODtIlUJDDiT3BlbkFJv5grBkM31saweFM0GDka",
  dangerouslyAllowBrowser: true,
});

export const sendMessageToGpt = async (conversationHistory, userInput) => {
  // Construct the messages array based on conversation history and user input
  const messages = conversationHistory.map((message) => ({
    role: message.role,
    content: message.content,
  }));

  // Add the user's latest message
  if (userInput !== "") {
    messages.push(userInput);
  }

//   const prompt_template = `You will be provided with a json dataset originally from intuit quickbooks.\n
//                             Make sure to read and remember that data. Also, do not give me any explanations or formulas, simply provide the output the user is asking for based on the provided dataset. \n
//                             Return responses in html format. Here is this data ${jsonData}. Do not apologize, do not preface. Simply give me the information exactly how it's asked for. `;

  // every subsequent request needs to be in the form, user then assistant
  // messages has to be: {user, assistant, user, assistant}
  console.log(messages)
  const response = await openai.chat.completions.create({
    model: "gpt-4o",
    messages: messages,
    temperature: 0,
    max_tokens: 2000,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  });

  // Extract the assistant's response from the API response
  console.log(response.choices);
  const assistantResponse = response.choices[0].message.content;

  return assistantResponse;
};


