/* eslint-disable */
import React, { useState } from 'react';
import C2QB from '../../assets/C2QB_green_btn_med_default.png';
import Freshbooks from '../../assets/freshbooks.png';
import QuickBooks from '../../assets/qb-logo.svg';
import Xero from '../../assets/xero.png';
import { AuthContext } from '../../context/context';
import { intuitLogin } from '../../context/intuit-service';
import Alerts from '../Misc/Alerts';
import { useStyles } from './styles.js';
function IntegrationCards() {
  const {
    state,
    dispatch,
  } = React.useContext(AuthContext);
  const classes = useStyles();
  const [msg, setMsg] = React.useState('');
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);

  const goToIntuit = async () => {
    const sub = await intuitLogin();
    if (sub.status === 200 || sub.status === 201) {
      window.location.href = sub.data.url;
    }
    // window.location.href = '/intuitLogin';
  }

  React.useEffect(() => {
  }, []);

  const [intuit, setIntuit] = useState(false);


  return (
    <div>
      <div className="ui search">
        <div className="ui icon input">
          <input className="prompt" type="text" placeholder="Search..." />
          <i className="search icon"></i>
        </div>
        <div className="results"></div>
      </div>
      <div />
      <Alerts msg={msg} type={type} openMsg={openMsg} setOpenMsg={setOpenMsg} />
      <div className="row pt-3 mt-3" style={{ paddingBottom: '25px' }}>
        <div className="ui cards pt-3">
          <div className="col-sm-4">
            <div className="card pl-4 pt-0 pb-0">
              <div className="content">
                <div className="">
                  <img src={QuickBooks}></img>
                </div>
                <div className="description">
                  Accounting & Finance
                </div>
              </div>

              <div className="extra content">
                {intuit ?
                  <div className="ui button p-0 mb-2" onClick={goToIntuit}><img src={C2QB}></img></div>

                  :


                  <div className="ui secondary button mt-2 mb-2" onClick={() => setIntuit(true)}>Add Company</div>


                }
              </div>
            </div>
          </div>
          {/* <div className="col-sm-4">
            <div className="card ml-2 pl-4 pt-3 pb-3">
              <div className="content">
                <div className="header">
                  <p><img src={Xero}></img>&nbsp; Xero </p>
                </div>
                <div className="description">
                  Accounting & Finance                    
                </div>
              </div>
              <div className="extra content">
                <div className="ui secondary disabled button mt-2">Comming soon</div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card ml-2 pl-4 pt-3 pb-3">
              <div className="content">
                <div className="header">
                  <p><img src={Freshbooks} style={{ height: "30px", width: "30px" }}></img>&nbsp; FreshBooks </p>
                </div>
                <div className="description">
                  Accounting & Finance                    </div>
              </div>
              <div className="extra content">
                <div className="ui secondary button disabled mt-2"> Comming soon</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default IntegrationCards;
