/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PieController,
  DoughnutController,
  BarController,
  LineController,
  RadarController,
  PolarAreaController,
  LogarithmicScale
} from "chart.js";
import 'chart.js/auto';
import { AuthContext } from "../../context/context";

const ChartComponent = ({ config, chartId, setRef }) => {
  const chartRef = useRef(null);
  // Register the necessary components for a pie chart
  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    PieController,
    DoughnutController,
    BarController,
    LineController,
    RadarController,
    PolarAreaController,
    LogarithmicScale
  );

  const {state: {
    allCharts, chartIds
  }, dispatch} = React.useContext(AuthContext);

  const callbacks = {
    formatCurrency: function(value) {
      return '$' + value.toLocaleString();
    },
    formatPercentage: function(value) {
      return '%' + value.toLocaleString();
    }
  }

  useEffect(() => {
    console.log("new chart");
    if (
      config.options && config.options.scales &&
      config.options.scales.y && config.options.scales.y.ticks && (config.options.scales.y.ticks.callback == 'formatCurrency' || config.options.scales.y.ticks.callback == 'formatPercentage')
    ) {
      console.log(config.options.scales.y.ticks.callback);
      config.options.scales.y.ticks.callback = callbacks[config.options.scales.y.ticks.callback]
    }
    if (chartRef.current) {
      const chart = new Chart(chartRef.current, config);

      if (setRef) {
        setRef(chartId, chart);
      }

      return () => {
        chart.destroy();
      }
    }
    // store this, so then we can destroy it when deleting a board
    // chartId.current = chart.id;
    // console.log(chartId.current);
    // console.log(chartIds);
    // dispatch({
    //   type: 'SET_CHART_IDS',
    //   payload: [...chartIds, chart.id]
    // })
    // console.log(chartInstances);
    // setCurrCharts(chartInstances);

  }, [config, setRef, chartId]);

  return (
    <div style={{ position: 'relative', width: "100%", height: "100%", overflow: 'hidden'}}>
      <canvas
        ref={chartRef}
        id={chartId}
        style={{ width: "200px", height: "400px" }}
      ></canvas>
      {/* {currCharts &&
        currCharts.map((chart) => {
          if (chart.id === newChart.id) {
            return (
              <canvas
                key={key}
                id={chart.id}
                style={{ width: "200px", height: "300px" }}
              ></canvas>
            );
          }
        })} */}
    </div>
  );
};

export default ChartComponent;
