import React from 'react';
import { Helmet } from 'react-helmet';
import FinproRoutes from './FinproRoutes';
import { AuthContext, initialState, reducer } from './context/context';
import { getCurrentUser } from './service';

require('./RoundedBars');

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  // Function to toggle fullscreen
  const toggleFullScreen = () => {
    dispatch({ type: 'TOGGLE_FULLSCREEN' });
  };

  // if (getCurrentUser() && new Date(getCurrentUser().exp * 1000).getTime() < new Date().getTime()) {
  //   dispatch({
  //     type: 'LOGOUT',
  //   });
  //   window.location.href = '/';
  // }

  // Set a timeout for 15 minutes
  const inactivityTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
  let timeoutId;

  // Function to reset the timeout
  const resetInactivityTimeout = () => {
    clearTimeout(timeoutId); // Clear the existing timeout
    timeoutId = setTimeout(() => {
      // Perform logout action after 15 minutes of inactivity
      dispatch({
        type: 'LOGOUT',
      });
      window.location.href = '/';
    }, inactivityTimeout);
  };

  // Call resetInactivityTimeout whenever there's user activity
  const handleUserActivity = () => {
    resetInactivityTimeout();
  };

  // Attach event listeners to monitor user activity
  document.addEventListener('mousemove', handleUserActivity);
  document.addEventListener('keydown', handleUserActivity);

  // Check for expiration only if the user is logged in
  if (getCurrentUser() && new Date(getCurrentUser().exp * 1000).getTime() < new Date().getTime()) {
    // Perform logout if the token has expired
    dispatch({
      type: 'LOGOUT',
    });
    window.location.href = '/';
  } else {
    // Reset inactivity timeout if the user is still logged in
    resetInactivityTimeout();
  }


  return (
    <div className="app">
      <Helmet>
        <style>{'body { background-color: white;} h1 { color: black; }'}</style>
      </Helmet>
      <AuthContext.Provider
        value={{
          state,
          dispatch,
          toggleFullScreen,
        }}
      >
        <FinproRoutes />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
