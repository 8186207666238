/* eslint-disable */

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Updated import
import LOGO from '../../assets/Dx.png';
import axios from '../../context/axios';
import { useStyles } from './styles.js';

function ResetPassword() {
  const classes = useStyles();
  const navigate = useNavigate(); // Updated hook

  const [alertClass, setAlertClass] = useState('');
  const [msg, setMsg] = useState('');
  const [err, setErr] = useState('');
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(1);
  const [resetForm, setResetForm] = useState({
    username: '',
    code: '',
    newPassword: ''
  });
  const [validPassword, setValidPassword] = useState(false);
  const [showPasswordReqsPanel, setShowPasswordReqsPanel] = useState(false);
  const [passwordReqs, setPasswordReqs] = useState({
    lowerCase: false,
    upperCase: false,
    number: false,
    special: false,
    lengthOf8: false,
  });

  const handleReset = (e) => {
    const { name, value } = e.target;
    if (name === 'newPassword') {
      passwordValidator(value);
    }
    setResetForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (step === 1) {
      try {
        const response = await axios.post('/send-code', { username: resetForm.username });
        setMsg('Verification code sent to your email');
        setErr('');
        setStep(2);
      } catch (e) {
        setAlertClass('show');
        setMsg('');
        if (e.response && e.response.data && e.response.data.message) {
          setErr(e.response.data.message);
        } else {
          setErr('User not found. Please sign up.');
        }
      } finally {
        setLoader(false);
      }
    } else {
      try {
        const response = await axios.post('/reset-password', {
          username: resetForm.username,
          code: resetForm.code,
          newPassword: resetForm.newPassword,
        });
        setMsg('Password reset successful');
        setErr('');
        setStep(3);
      } catch (e) {
        setAlertClass('show');
        setMsg('');
        if (e.response && e.response.data && e.response.data.message) {
          setErr(e.response.data.message);
        } else {
          setErr(e.message);
        }
      } finally {
        setLoader(false);
      }
    }
  };

  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  const passwordValidator = (password) => {
    let lowerCase = false;
    let upperCase = false;
    let number = false;
    let special = false;
    let lengthOf8 = false;
    if (password.match(/[a-z]/)) {
      lowerCase = true;
    }
    if (password.match(/[A-Z]/)) {
      upperCase = true;
    }
    if (password.match(/[0-9]/)) {
      number = true;
    }
    if (password.match(/(?=.*[!@#$%^&*])/)) {
      special = true;
    }
    if (password.length >= 8) {
      lengthOf8 = true;
    }

    setPasswordReqs({
      lowerCase, upperCase, number, special, lengthOf8,
    });
    setValidPassword(lowerCase && upperCase && number && special && lengthOf8);
  };

  const PasswordReqsPanel = (props) => (
    <div style={{
      borderStyle: 'solid', borderColor: '#CAD4D7', borderRadius: '5px', borderWidth: '1px', width: '250px', fontSize: '15px', position: 'fixed', backgroundColor: 'white', zIndex: '10',
    }}
    >
      <div className="row align-items-center justify-content-center">
        <p className="mt-3 ml-5">
          {props.passwordReqs.lowerCase ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One lowercase Character
        </p>
        <p className="ml-5">
          {props.passwordReqs.upperCase ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One uppercase character
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.number ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One number
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.special ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One special character
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.lengthOf8 ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          8 characters minimum
          {' '}
        </p>
      </div>
    </div>
  );

  if (step === 3) {
    return (
      <div className={classes.root}>
        <div className={classes.topBar}>
          <div className="row">
            <div className={[classes.toolbar, classes.Logo].join(' ')}>
              <Link to="/">
                <img src={LOGO} alt="logo-black" style={{ width: '190px', height: '30px', marginLeft: '30px' }} />
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <h2 className="display-4 text-center mb-3" style={{ color: '#55616E' }}>Password Reset Successful</h2>
          <div className="text-center">
            <button onClick={() => navigate('/login')} className="btn btn-lg btn-block btn-custom mb-3" style={{ background: 'black', color: 'white' }}>
              Back to Login
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className="row">
          <div className={[classes.toolbar, classes.Logo].join(' ')}>
            <Link to="/">
              <img src={LOGO} alt="logo-black" style={{ width: '190px', height: '30px', marginLeft: '30px' }} />
            </Link>
          </div>
        </div>
        <div>
          <div className={classes.container}>
            <h2 className="display-4 text-center mb-3" style={{ color: '#55616E' }}>Reset Password</h2>
            <p className="text-center mb-3" style={{ fontSize: 20 }}>
              Remembered your password?
              {' '}
              <Link to="/login">
                Sign in
              </Link>
            </p>

            {msg && (
              <div className={`alert alert-success alert-dismissible fade show`}  role="alert">
                <strong>{msg}</strong>
                <button onClick={handleCloseAlert} type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            )}
            {err && (
              <div className={`alert alert-danger alert-dismissible fade show`}  role="alert">
                <strong>{err}</strong>
                <button onClick={handleCloseAlert} type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            )}

            <form onSubmit={handleSubmit} data-rewardful="true">
              {step === 1 ? (
                <div className="form-group">
                  <input type="text" name="username" value={resetForm.username} onChange={handleReset} className="form-control" required placeholder="Email" />
                </div>
              ) : (
                <>
                  <div className="form-group">
                    <input type="text" name="code" value={resetForm.code} onChange={handleReset} className="form-control" required placeholder="Verification Code" />
                  </div>
                  <div className="form-group">
                    <div className="input-group input-group-merge">
                      <input className="form-control" name="newPassword" onFocus={() => setShowPasswordReqsPanel(true)} onBlur={() => setShowPasswordReqsPanel(false)} value={resetForm.newPassword} onChange={handleReset} required type="password" placeholder="New Password" />
                    </div>
                    {showPasswordReqsPanel && !validPassword && <PasswordReqsPanel passwordReqs={passwordReqs} />}
                  </div>
                </>
              )}

              <button disabled={loader || (step === 2 && !validPassword)} style={{ margin: 0, background: 'black', color: 'white' }} className="btn btn-lg btn-block btn-custom mb-3">
                {loader && (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!loader && (step === 1 ? 'Send Code' : 'Reset Password')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
