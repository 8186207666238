/* eslint-disable */
import { useEffect } from 'react'
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import { AuthContext } from '../../context/context';
import { deleteSubBoard, duplicateBoardItem } from '../../context/intuit-service';
import AddTextModal from './AddTextModal';
import { extractId } from './helpers';

const EditBoardsButton = (props) => {
  const {
    state: {
      user, switchUser, isAuthenticated, access_token, purchasing, projects, allBoards, selectedTab, selectedBoard, chartIds, isFullScreen
    },
    dispatch,
  } = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleClick = (event) => {
    console.log('edit clicked')
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    console.log(props);
  }, [props])

  const handleClose = () => {
    setAnchorEl(null);
    setIsLoading(false);
  };

  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
    handleClose();
  };

  const handleDeleteConfirm = async () => {
    // Handle deletion logic here
    let data = { 
      sharedId: switchUser.userName, 
      // boardName: name,
      boardId: props.boardId,
      clientId: props.fullBoard._id, 
      tabId: props.fullBoard.tabs[selectedTab]._id,
      subBoardId: props.board._id
      
    };
    // if (props.board) {
    //   data.boardIdReplace = props.board._id;
    // }

    if (props.fullBoard.access) {
      data.boardId = extractId(props.fullBoard.access);
    }

    const delSubBoard = await deleteSubBoard(data);
    
    if (delSubBoard.status === 200) {
      let newBoards = allBoards;
      for (let i = 0; i < newBoards.boards.length; i += 1) {
        let client = newBoards.boards[i];
        if (client._id === props.fullBoard._id) {
          for (let j = 0; j < client.tabs[selectedTab].boardData.length; j += 1) {
            let subBoard = client.tabs[selectedTab].boardData[j];
            if (subBoard._id === props.board._id) {
              newBoards.boards[i].tabs[selectedTab].boardData.splice(j, 1);
            }
          }
        }
      }
      console.log(delSubBoard.data.data);
      console.log(newBoards);
      dispatch({
        type: 'SET_ALLBOARDS',
        payload: newBoards
      });
    }

    setDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
  };

  const handleDuplicateClose = () => {
    setDuplicateModalOpen(false);
  }

  const duplicateBoard = async () => {
    // setIsLoading(true); // Start loading indicator
    setDuplicateModalOpen(true);
    handleClose();
    let boardInfo = {
      clientId: allBoards.boards[selectedBoard]._id,
      boardId: allBoards._id,
      tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id, // Include tabId in the payload
      duplicateId: props.board._id,
    };
    if (props.fullBoard.access) {
      boardInfo.boardId = extractId(props.fullBoard.access);
    }
    let duplicateBoardResponse = await duplicateBoardItem(boardInfo);
    dispatch({
      type: 'SET_ALLBOARDS',
      payload: duplicateBoardResponse.data.data
    });
    setDuplicateModalOpen(false);
  };
  
  const [addTextModalOpen, setAddTextModalOpen] = useState(false);
  const handleAddTextCancel = () => {
    setAddTextModalOpen(!addTextModalOpen);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="text"
        className={isFullScreen ? 'hide-in-fullscreen' : ''}
        aria-controls="simple-menu"
        aria-haspopup="true"
        style={{
          whiteSpace: "nowrap",
          fontSize: "10px",
          paddingTop: "10px",
          padding: "0px 0px 0px 0px",
        }}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.board && props.board._id && props.board.boardType !== "Text" && props.board.boardType !== 'graph' && (
          <MenuItem style={{ display: "flex", alignItems: "center" }}>
            <Link
              to={`/EditBoard/${props.board._id}`}
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <EditOutlinedIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </Link>
          </MenuItem>
        )}
        {props.board && props.board._id && props.board.boardType === "Text" && (
          <MenuItem onClick={() => handleAddTextCancel()}>
            <ListItemIcon>
              <EditOutlinedIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        )}
        <MenuItem onClick={() => duplicateBoard()}>
          <ListItemIcon>
            <ContentCopyOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Duplicate" />
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      <Modal open={duplicateModalOpen}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            height: "100px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0 0 10px rgba(0,0,0,0.3)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: "bold", fontFamily: "sans-serif" }}
            >
              Duplicating Board
            </Typography>
            <CircularProgress size={24} style={{ marginLeft: 8 }} />
          </Box>
        </div>
      </Modal>
      <Modal open={deleteModalOpen} onClose={handleDeleteCancel}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0 0 10px rgba(0,0,0,0.3)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Are you sure you want to delete this?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#000", color: "#fff" }}
              onClick={handleDeleteConfirm}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
      <AddTextModal
        board={props.board}
        addTextModalOpen={addTextModalOpen}
        handleAddTextCancel={handleAddTextCancel}
        edit={true}
      />
    </div>
  );
};

export default EditBoardsButton;
