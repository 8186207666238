/* eslint-disable */

import moment from 'moment';
import React from 'react';
import { getDateRange } from '../components/ClientPerformance/helpers';
import {
  getMonthName,
  getQuarterEbit, getQuarterExpenses,
  getYearEbit,
  getYearExpenses,
  setQuarterLabel,
} from '../utils/utils';
import { colors } from './colors';
import {
  LOGIN, LOGOUT,
  SET_ACCESS_TOKEN,
  SET_ALLBOARDS,
  SET_BILLING,
  SET_DATE,
  SET_FULLDATA,
  SET_FULLDATA_TABLE,
  SET_INPUTS,
  SET_INTEGRATIONDATA,
  SET_INTEGRATIONINFO,
  SET_INVOICES,
  SET_PROJECT,
  SET_PURCHASING,
  SET_QUICKBOOK_AUTH, SET_QUICKBOOK_USER,
  SET_REVENUE,
  SET_SCENARIO,
  SET_SCENARIOID,
  SET_SELECTEDBOARD, SET_SELECTEDTAB,
  SET_SELECTEDINTEGRATION,
  SET_TABLE_DATA,
  SET_TEAMS,
  SET_USER,
  SWITCH_USER,
  VIEW_DATA, VIEW_REPORTS,
  SET_CLIENTS_PURCHASED,
  SET_GPT_DATA,
  SET_PRESET_COMPONENT,
  SET_ALL_CHARTS,
  SET_DATES,
  SET_CHART_IDS,
  SET_UPLOADED_FILES,
} from './types';

export const initialState = {
  isAuthenticated: !!localStorage.getItem('finProtoken'),
  user: localStorage.getItem('finProUser') ? JSON.parse(localStorage.getItem('finProUser')) : null,
  token: localStorage.getItem('finProtoken') ? localStorage.getItem('finProtoken') : null,
  access_token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
  billingDetails: {},
  stepperData: {
    bussinessModel: 'SAAS',
    companyStage: {
      revenue: '',
      noOfEmployess: '',
    },
    access_token: null,
  },
  data: {
    labels: [],
    datasets: [],
  },
  integrations: {
    // when we access initial state for context, we also check for if user has been authenticated on quickbooks within their local browser, and then set up state that way
    quickbooks: {
      access: localStorage.getItem('quickbooks_access') ? JSON.parse(localStorage.getItem('quickbooks_access')) : {},
      user: {
        givenName: localStorage.getItem('quicbooks_givenName') ? JSON.parse(localStorage.getItem('quicbooks_givenName')) : '',
        familyName: localStorage.getItem('quicbooks_familyName') ? JSON.parse(localStorage.getItem('quicbooks_familyName')) : '',
        email: localStorage.getItem('quicbooks_email') ? JSON.parse(localStorage.getItem('quicbooks_email')) : '',
      },

    },
  },
  integrationInfo: localStorage.getItem('integration_info') ? JSON.parse(localStorage.getItem('integration_info')) : {},
  integrationData: localStorage.getItem('integration_data') ? JSON.parse(localStorage.getItem('integration_data')) : {},
  allBoards: localStorage.getItem('allBoards') ? JSON.parse(localStorage.getItem('allBoards')) : {},
  selectedIntegration: localStorage.getItem('selectedIntegration') ? JSON.parse(localStorage.getItem('selectedIntegration')) : 0,
  projects: [],
  selectedProject: localStorage.getItem('selectedProject') ? JSON.parse(localStorage.getItem('selectedProject')) : 0,
  selectedScenario: localStorage.getItem('selectedScenario') ? JSON.parse(localStorage.getItem('selectedScenario')) : 0,
  selectedBoard: localStorage.getItem('selectedBoard') ? JSON.parse(localStorage.getItem('selectedBoard')) : 0,
  selectedTab: localStorage.getItem('selectedTab') ? JSON.parse(localStorage.getItem('selectedTab')) : 0,
  currentScenario: localStorage.getItem('currentScenario') ? JSON.parse(localStorage.getItem('currentScenario')) : 0,
  invoices: [],
  revenues: [],
  inputs: [],
  teams: [],
  fullData: localStorage.getItem('fullData') ? JSON.parse(localStorage.getItem('fullData')) : null,
  fullDataTable: localStorage.getItem('fullDataTable') ? JSON.parse(localStorage.getItem('fullDataTable')) : null,
  date: localStorage.getItem('globalDate') ? JSON.parse(localStorage.getItem('globalDate')) : getDateRange(),
  tableData: null,
  switchUser: {
    userName: localStorage.getItem('finProUser') ? JSON.parse(localStorage.getItem('finProUser')).email : null,
    userAccess: 'CAN_MODIFY',
  },
  purchasing: localStorage.getItem('finProPurch') ? JSON.parse(localStorage.getItem('finProPurch')) : [],
  reports: {
    labels: ['2021', '2022', '2023', '2024', '2025'],
    datasets: [
      {
        label: 'Revenue',
        data: [300, 490, 800, 1500, 1800],
        backgroundColor: '#53CA35',
      },
      {
        label: 'Expenses',
        data: [500, 900, 1200, 1100, 1200],
        backgroundColor: '#F14038',
      },
      {
        label: 'Operating Profit',
        data: [-50, -150, -200, 200, 400],
        backgroundColor: '#4E5AC0',
      },
      {
        label: 'EBIT',
        data: [-150, -300, -500, 100, 300],
        backgroundColor: '#9891AF',
      },
    ],
  },
  uploadedFiles: [],
  chart_format: 'currency', 
  companyName: localStorage.getItem('companyName') ? JSON.parse(localStorage.getItem('companyName')) : '',
  clientsPurchased: 0,
  gptData: localStorage.getItem('gptData') ? localStorage.getItem('gptData') : '',
  data_change: 0,
  presetComponent: null,
  date_change: getDateRange(),
  chartIds: [],
  isFullScreen: !!JSON.parse(localStorage.getItem('isFullScreen')) || false,  // Initialize from localStorage
};

export const AuthContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem("finProtoken", action.payload.token);
      localStorage.setItem("finProUser", JSON.stringify(action.payload.user));
      localStorage.setItem(
        "access_token",
        JSON.stringify(action.payload.access_token)
      );
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        access_token: action.payload.access_token,
      };
    case SET_ACCESS_TOKEN:
      localStorage.setItem(
        "access_token",
        JSON.stringify(action.payload.access_token)
      );
      return {
        ...state,
        access_token: action.payload.access_token,
      };
    case LOGOUT:
      localStorage.removeItem("finProtoken");
      localStorage.removeItem("finProUser");
      localStorage.removeItem("finProPurch");
      localStorage.removeItem("access_token");
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };

    case SET_CLIENTS_PURCHASED:
      console.log("passed", action.payload);
      return {
        ...state,
        clientsPurchased: action.payload,
      };

    case SET_GPT_DATA:
      console.log("passed", action.payload);
      localStorage.setItem("gptData", JSON.stringify(action.payload));
      return {
        ...state,
        gptData: action.payload,
      };

    case SET_QUICKBOOK_AUTH:
      localStorage.setItem("quickbooks_access", JSON.stringify(action.payload));

      return {
        ...state,
        integrations: {
          ...state.integrations,
          quickbooks: {
            ...state.integrations.quickbooks,
            access: action.payload,
          },
        },
      };

    case SET_QUICKBOOK_USER:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          quickbooks: {
            ...state.integrations.quickbooks,
            user: action.payload,
          },
        },
      };
    case SET_INTEGRATIONINFO:
      localStorage.setItem("integration_info", JSON.stringify(action.payload));

      return {
        ...state,
        integrationInfo: action.payload,
      };
    case SET_INTEGRATIONDATA:
      localStorage.setItem("integration_data", JSON.stringify(action.payload));
      return {
        ...state,
        integrationData: action.payload,
      };
    case SET_SELECTEDINTEGRATION:
      localStorage.setItem(
        "selectedIntegration",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        selectedIntegration: action.payload,
      };
    case SET_SELECTEDBOARD:
      localStorage.setItem("selectedBoard", JSON.stringify(action.payload));
      return {
        ...state,
        selectedBoard: action.payload,
      };
    case SET_SELECTEDTAB:
      localStorage.setItem("selectedTab", JSON.stringify(action.payload));
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SET_ALLBOARDS:
      localStorage.setItem("allBoards", JSON.stringify(action.payload));
      return { ...state, allBoards: { ...action.payload } };
    case SET_CHART_IDS:
      return {
        ...state,
        chartIds: action.payload,
      };
    case SET_USER:
      localStorage.setItem("finProUser", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };
    case SET_REVENUE:
      return {
        ...state,
        revenues: action.payload,
      };
    case SET_PROJECT:
      localStorage.setItem("selectedProject", JSON.stringify(action.payload));
      return {
        ...state,
        selectedProject: action.payload,
      };
    case SET_SCENARIO:
      localStorage.setItem("currentScenario", JSON.stringify(action.payload));
      return {
        ...state,
        currentScenario: action.payload,
      };
    case SET_SCENARIOID:
      localStorage.setItem("selectedScenario", JSON.stringify(action.payload));
      return {
        ...state,
        selectedScenario: action.payload,
      };
    case SET_INPUTS:
      return {
        ...state,
        inputs: action.payload,
      };
    case SET_BILLING:
      return {
        ...state,
        billingDetails: action.payload,
      };
    case VIEW_DATA:
      const newData = setData(action.payload, state);
      return {
        ...state,
        data: newData,
      };
    case VIEW_REPORTS:
      const newReports = setReports(action.payload, state);
      return {
        ...state,
        reports: newReports,
      };
    case SET_PURCHASING:
      localStorage.setItem("finProPurch", JSON.stringify(action.payload));
      console.log(action.payload);
      return {
        ...state,
        purchasing: action.payload,
      };
    case SET_TEAMS:
      return {
        ...state,
        teams: action.payload,
      };
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case SET_TABLE_DATA:
      const newTableData = setReportTableData(state);
      return {
        ...state,
        tableData: newTableData,
      };
    case SWITCH_USER:
      return {
        ...state,
        switchUser: action.payload,
      };
    case SET_FULLDATA_TABLE:
      localStorage.setItem("fullDataTable", JSON.stringify(action.payload));

      return {
        ...state,
        fullDataTable: action.payload,
      };
    case SET_UPLOADED_FILES:
      return {
        ...state,
        uploadedFiles: [...action.payload]
      };
    case SET_FULLDATA:
      localStorage.setItem("fullData", JSON.stringify(action.payload));

      return {
        ...state,
        fullData: action.payload,
      };
    case SET_BILLING:
      return {
        ...state,
        billingDetails: action.payload,
      };
    case SET_DATES:
      return {
        ...state,
        date_change: action.payload,
      };
    case SET_DATE:
      localStorage.setItem("globalDate", JSON.stringify(action.payload));

      return {
        ...state,
        date: action.payload,
      };
    case SET_PRESET_COMPONENT:
      return {
        ...state,
        presetComponent: action.payload,
      };
    // case 'SET_CHART_FORMAT':
    //   localStorage.setItem('chart_format', JSON.stringify(action.payload));
    //   return {
    //     ...state,
    //     chart_format: action.payload,
    //   };

    // case SWITCH_USER_ACCESS:
    //   return {
    //     ...state,
    //     newUserAccess: action.payload,
    //   };
    case "SET_BUSINESS_MODEL":
      const { type } = action.payload;
      const { value } = action.payload;
      return {
        ...state,
        stepperData: {
          ...state.stepperData,
          [type]: value,
        },
      };
    case "SET_COMPANY_NAME": // Handle setting
      localStorage.setItem("companyName", JSON.stringify(action.payload));
      return {
        ...state,
        companyName: action.payload,
      };
    case "SET_DATA_CHANGE":
      return {
        ...state,
        data_change: action.payload,
      };
    case 'TOGGLE_FULLSCREEN':
      const newFullScreenState = !state.isFullScreen;
      localStorage.setItem('isFullScreen', JSON.stringify(newFullScreenState)); // Store the new state in localStorage
      return {
        ...state,
        isFullScreen: newFullScreenState, // Update the state with the new value
      };
    default:
      return state;
  }
};

function setData(type, state) {
  const { user } = state;
  switch (type) {
    case 'year':
      let yearData;
      const ydata = [...state.revenues.revenuInputs]; // .filter((t) => t.type === 'Yearly');
      yearData = {
        labels: [],
        datasets: [],
      };
      const datalabels = ydata.map((l) => ({ plan: l.name, color: l.color }));
      yearData.labels = [new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2, new Date().getFullYear() + 3, new Date().getFullYear() + 4];
      const datasets = [];
      const data = {};
      for (let i = 0; i < yearData.labels.length; i += 1) {
        ydata.forEach((d, id) => {
          data[id] = [];
          let price = isNaN(parseInt(d.price)) ? 1 : parseInt(d.price);
          let customers = isNaN(parseInt(d.customers)) ? 1 : parseInt(d.customers);
          if (d.type === 'Yearly') {
            data[id].push(parseFloat(price));
          } else if (d.type === 'Quarter') {
            data[id].push(parseFloat(price * customers * 4));
          } else if (d.type === 'Monthly') {
            data[id].push(parseFloat(price * customers * 12));
          } else {
            data[id].push(parseFloat(price));
          }
          for (let j = 0; j < yearData.labels.length; j += 1) {
            data[id].push(parseFloat(data[id][j]) + ((user && user.grate ? user.grate : user.email) / 100 || 50 / 100) * parseFloat(data[id][j]));
          }
        });
      }

      for (let i = 0; i < ydata.length; i += 1) {
        if (data[i].length > 0) {
          datasets.push(data[i]);
        }
      }

      datalabels.forEach((label, id) => {
        yearData.datasets[id] = {};
        if (label.plan === 'Silver Plan' || label.plan === 'Silver') {
          yearData.datasets[id].backgroundColor = /* label.color ? label.color : */ '#53CA35';
        } else if (label.plan === 'Gold Plan' || label.plan === 'Gold') {
          yearData.datasets[id].backgroundColor = /* label.color ? label.color : */ '#F14038';
        } else if (label.plan === 'Platinum Plan' || label.plan === 'Platinum') {
          yearData.datasets[id].backgroundColor = /* label.color ? label.color : */ '#4E5AC0';
        } else if (label.plan === 'Enterprice Plan') {
          yearData.datasets[id].backgroundColor = /* label.color ? label.color : */ '#9891AF';
        } else {
          yearData.datasets[id].backgroundColor = label.color ? label.color : colors[Math.floor(Math.random() * colors.length - 1)];
        }
        yearData.datasets[id].data = datasets[id];
        yearData.datasets[id].label = label.plan;
      });

      return yearData;
    case 'quarter':
      let quarterData;
      const qdata = [...state.revenues.revenuInputs];
      quarterData = { labels: [], datasets: [] };

      const qdatalabels = qdata.map((l) => ({ plan: l.plan, color: l.color }));
      quarterData.labels = ['Q1 2021', 'Q2 2021', 'Q3 2021', 'Q4 2021', 'Q1 2022', 'Q2 2022', 'Q3 2022', 'Q4 2022', 'Q1 2023', 'Q2 2023', 'Q3 2023', 'Q4 2023', 'Q1 2024', 'Q2 2024', 'Q3 2024', 'Q4 2024', 'Q1 2025', 'Q2 2025', 'Q3 2025', 'Q4 2025'];
      const qdatasets = [];
      const dataq = {};
      for (let i = 0; i < quarterData.labels.length; i += 1) {
        qdata.forEach((d, id) => {
          dataq[id] = [];
          if (d.type === 'Yearly') {
            dataq[id].push(parseFloat((d.price * d.customers) / 4));
          } else if (d.type === 'Quarter') {
            dataq[id].push(parseFloat(d.price * d.customers));
          } else if (d.type === 'Monthly') {
            dataq[id].push(parseFloat(d.price * d.customers * 4));
          }
          for (let j = 0; j < quarterData.labels.length; j += 1) {
            dataq[id].push(parseFloat(dataq[id][j]) + ((user && user.grateQuarterly ? user.grateQuarterly : user.email) / 100 || 0 / 100) * parseFloat(dataq[id][j]));
          }
        });

        for (let i = 0; i < qdata.length; i += 1) {
          if (dataq[i].length > 0) {
            qdatasets.push(dataq[i]);
          }
        }
      }

      qdatalabels.forEach((label, id) => {
        quarterData.datasets[id] = {};
        if (label.plan === 'Silver Plan' || label.plan === 'Silver Plan') {
          quarterData.datasets[id].backgroundColor = '#53CA35';
        } else if (label.plan === 'Gold Plan' || label.plan === 'Gold') {
          quarterData.datasets[id].backgroundColor = '#F14038';
        } else if (label.plan === 'Platinum Plan' || label.plan === 'Platinum') {
          quarterData.datasets[id].backgroundColor = '#4E5AC0';
        } else if (label.plan === 'Enterprice Plan' || label.plan === 'Enterprice') {
          quarterData.datasets[id].backgroundColor = '#9891AF';
        } else {
          quarterData.datasets[id].backgroundColor = label.color ? label.color : colors[Math.floor(Math.random() * colors.length - 1)];
        }
        quarterData.datasets[id].data = qdatasets[id];
        quarterData.datasets[id].label = label.plan;
      });
      return quarterData;
    case 'month':
      let monthData;
      const mdata = [...state.revenues.revenuInputs];
      monthData = { labels: [], datasets: [] };

      const mdatalabels = mdata.map((l) => ({ plan: l.plan, color: l.color }));
      const mdatasets = [];
      const datam = {};
      monthData.labels = [
        `${getMonthName(new Date().getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(1, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(2, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(3, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(4, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(5, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(6, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(7, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(8, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(9, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth() + 1)} ${new Date().getFullYear()}`,

        `${getMonthName(new Date().getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(1, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(2, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(3, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(4, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(5, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(6, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(7, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(8, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(9, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth())} ${[new Date().getFullYear() + 1]}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth() + 1)} ${[new Date().getFullYear() + 1]}`,

        `${getMonthName(new Date().getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(1, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(2, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(3, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(4, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(5, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(6, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(7, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(8, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(9, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth())} ${[new Date().getFullYear() + 2]}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth() + 1)} ${[new Date().getFullYear() + 2]}`,

        `${getMonthName(new Date().getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(1, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(2, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(3, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(4, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(5, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(6, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(7, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(8, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(9, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth())} ${[new Date().getFullYear() + 3]}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth() + 1)} ${[new Date().getFullYear() + 3]}`,

        `${getMonthName(new Date().getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(1, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(2, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(3, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(4, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(5, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(6, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(7, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(8, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(9, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth())} ${[new Date().getFullYear() + 4]}`,
        `${getMonthName(new Date(moment(new Date()).add(10, 'M')).getMonth() + 1)} ${[new Date().getFullYear() + 4]}`,
      ];
      for (let i = 0; i < monthData.labels.length; i += 1) {
        mdata.forEach((d, id) => {
          datam[id] = [];
          if (d.type === 'Yearly') {
            datam[id].push(parseFloat((d.price * d.customers) / 12));
          } else if (d.type === 'Quarter') {
            datam[id].push(parseFloat((d.price * d.customers * 4) / 12));
          } else if (d.type === 'Monthly') {
            datam[id].push(parseFloat(d.price * d.customers));
          }
          for (let j = 0; j < monthData.labels.length; j += 1) {
            datam[id].push(parseFloat(datam[id][j]) + ((user && user.grateMonthly ? user.grateMonthly : user.email) / 100 || 0 / 100) * parseFloat(datam[id][j]));
          }
        });
        for (let i = 0; i < mdata.length; i += 1) {
          if (datam[i].length > 0) {
            mdatasets.push(datam[i]);
          }
        }
      }

      mdatalabels.forEach((label, id) => {
        monthData.datasets[id] = {};
        if (label.plan === 'Silver Plan' || label.plan === 'Silver') {
          monthData.datasets[id].backgroundColor = '#53CA35';
        } else if (label.plan === 'Gold Plan' || label.plan === 'Gold') {
          monthData.datasets[id].backgroundColor = '#F14038';
        } else if (label.plan === 'Platinum Plan' || label.plan === 'Platinum') {
          monthData.datasets[id].backgroundColor = '#4E5AC0';
        } else if (label === 'Enterprice Plan' || label.plan === 'Enterprice') {
          monthData.datasets[id].backgroundColor = '#9891AF';
        } else {
          monthData.datasets[id].backgroundColor = label.color ? label.color : colors[Math.floor(Math.random() * colors.length - 1)];
        }
        monthData.datasets[id].data = mdatasets[id];
        monthData.datasets[id].label = label.plan;
      });
      return monthData;
    default:
  }
}

function setReports(type, state) {
  const { user } = state;
  switch (type) {
    case 'P&L by Year':
      let yearData;
      const ydata = [...state.revenues.revenuInputs]; // .filter((t) => t.type === 'Yearly');
      yearData = {
        labels: [],
        datasets: [],
      };
      yearData.labels = [new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2, new Date().getFullYear() + 3, new Date().getFullYear() + 4];
      const datalabels = ['Revenue', 'Expenses', 'Operating Profit', 'EBIT'];

      const datasets = [];
      const data = {};
      const total = [];

      for (let i = 0; i < yearData.labels.length; i += 1) {
        ydata.forEach((d, id) => {
          if (i === 0) {
            data[id] = [];
            if (d.type === 'Yearly') {
              // data[id].push(parseFloat(d.price * d.customers));
              total.push(parseFloat(d.price * d.customers));
            } else if (d.type === 'Quarter') {
              // data[id].push(parseFloat(d.price * d.customers * 4));
              total.push(parseFloat(d.price * d.customers * 4));
            } else if (d.type === 'Monthly') {
              // data[id].push(parseFloat(d.price * d.customers * 12));
              //
              total.push(parseFloat(d.price * d.customers * 12));
            }
          }
        });
      }


      let totalRev = total.reduce((a, b) => a + b, 0);
      for (let j = 0; j < yearData.labels.length; j += 1) {
        if (j === 0) {
          data[0].push(totalRev);
        } else {
          totalRev += ((user && user.grate ? user.grate : user.email) / 100 || 50 / 100) * totalRev;
          data[0].push(totalRev);
        }
      }


      data[1] = [];
      for (let j = 0; j < 5; j += 1) {
        //
        data[1].push(getYearExpenses(state.inputs, j));
      }
      data[2] = [];
      for (let j = 0; j < 5; j += 1) {
        if (data[0][j]) {
          data[2].push(data[0][j] - getYearExpenses(state.inputs, j));
        }
      }
      data[3] = [];
      for (let j = 0; j < 5; j += 1) {
        data[3].push(getYearEbit(state.inputs, j) / 3);
      }
      for (let i = 0; i < ydata.length; i += 1) {
        if (data[i].length > 0) {
          datasets.push(data[i]);
        }
      }

      datalabels.forEach((label, id) => {
        yearData.datasets[id] = {};
        if (label === 'Revenue') {
          yearData.datasets[id].backgroundColor = '#53CA35';
        } else if (label === 'Expenses') {
          yearData.datasets[id].backgroundColor = '#F14038';
        } else if (label === 'Operating Profit') {
          yearData.datasets[id].backgroundColor = '#4E5AC0';
        } else if (label === 'EBIT') {
          yearData.datasets[id].backgroundColor = '#9891AF';
        }

        yearData.datasets[id].data = datasets[id];
        yearData.datasets[id].label = label;
      });
      //
      return yearData;
    case 'P&L by Quarter':
      let quarterData;
      const qdata = [...state.revenues.revenuInputs]; // .filter((t) => t.type === 'Yearly');
      quarterData = {
        labels: [],
        datasets: [],
      };
      const qdatalabels = ['Revenue', 'Expenses', 'Operating Profit', 'EBIT'];
      quarterData.labels = [...setQuarterLabel()];

      //
      const qdatasets = [];
      const dataq = {};
      for (let i = 0; i < quarterData.labels.length; i += 1) {
        qdata.forEach((d, id) => {
          dataq[id] = [];
          if (id === 0) {
            if (d.type === 'Yearly') {
              dataq[id].push(parseFloat((d.price * d.customers) / 4));
            } else if (d.type === 'Quarter') {
              dataq[id].push(parseFloat(d.price * d.customers));
            } else if (d.type === 'Monthly') {
              dataq[id].push(parseFloat(d.price * d.customers * 4));
            }
            for (let j = 0; j < quarterData.labels.length; j += 1) {
              dataq[id].push(parseFloat(dataq[id][j]) + ((user && user.grateQuarterly ? user.grateQuarterly : user.email) / 100 || 0 / 100) * parseFloat(dataq[id][j]));
            }
          }
        });
      }

      dataq[1] = [];
      for (let j = 0; j < quarterData.labels.length; j += 1) {
        dataq[1].push(getQuarterExpenses(state.inputs, j));
      }

      dataq[2] = [];
      for (let j = 0; j < quarterData.labels.length; j += 1) {
        if (dataq[0][j]) {
          dataq[2].push(dataq[0][j] - getQuarterExpenses(state.inputs, j));
        }
      }

      dataq[3] = [];
      for (let j = 0; j < quarterData.labels.length; j += 1) {
        dataq[3].push(getQuarterEbit(state.inputs, j) / 3 / 4);
      }

      for (let i = 0; i < quarterData.labels.length; i += 1) {
        if (dataq[i] && dataq[i].length > 0) {
          qdatasets.push(dataq[i]);
        }
      }

      qdatalabels.forEach((label, id) => {
        quarterData.datasets[id] = {};
        if (label === 'Revenue') {
          quarterData.datasets[id].backgroundColor = '#53CA35';
        } else if (label === 'Expenses') {
          quarterData.datasets[id].backgroundColor = '#F14038';
        } else if (label === 'Operating Profit') {
          quarterData.datasets[id].backgroundColor = '#4E5AC0';
        } else if (label === 'EBIT') {
          quarterData.datasets[id].backgroundColor = '#9891AF';
        }

        quarterData.datasets[id].data = qdatasets[id];
        quarterData.datasets[id].label = label;
      });
      return quarterData;
    default:
  }
}

function setReportTableData(state) {
  const { user } = state;

  if (state.revenues && state.revenues.revenuInputs.length > 0) {
    let quarterData;
    const qdata = [...state.revenues.revenuInputs]; // .filter((t) => t.type === 'Yearly');
    quarterData = {
      labels: [],
    };
    quarterData.labels = [...setQuarterLabel()];

    const dataq = {};
    for (let i = 0; i < quarterData.labels.length; i += 1) {
      dataq.quarterRevenues = [];
      qdata.forEach((d, id) => {
        if (id === 0) {
          if (d.type === 'Yearly') {
            dataq.quarterRevenues.push(parseFloat((d.price * d.customers) / 4));
          } else if (d.type === 'Quarter') {
            dataq.quarterRevenues.push(parseFloat(d.price * d.customers));
          } else if (d.type === 'Monthly') {
            dataq.quarterRevenues.push(parseFloat(d.price * d.customers * 4));
          }
          for (let j = 0; j < quarterData.labels.length; j += 1) {
            dataq.quarterRevenues.push(parseFloat(dataq.quarterRevenues[j]) + ((user && user.grateQuarterly ? user.grateQuarterly : user.email) / 100 || 0 / 100) * parseFloat(dataq.quarterRevenues[j]));
          }
        }
      });
    }

    const startCash = 0;
    dataq.quarterExpense = [];
    for (let j = 0; j < quarterData.labels.length; j += 1) {
      dataq.quarterExpense.push(getQuarterExpenses(state.inputs, j));
    }

    dataq.quarterProfit = [];
    for (let j = 0; j < quarterData.labels.length; j += 1) {
      if (dataq.quarterRevenues[j]) {
        dataq.quarterProfit.push(dataq.quarterRevenues[j] - getQuarterExpenses(state.inputs, j));
      }
    }
    dataq.captalExpense = [];
    for (let j = 0; j < quarterData.labels.length; j += 1) {
      dataq.captalExpense.push(getQuarterEbit(state.inputs, j) / 3 / 4);
    }

    const inv = [];
    for (let i = 0; i < quarterData.labels.length; i += 1) {
      if (quarterData.labels[i]) {
        inv.push({
          year: quarterData.labels[i].split(' ')[1],
          invest: state.revenues.startingCapital
            .filter((sc) => new Date(sc.date).getFullYear().toString() === quarterData.labels[i].split(' ')[1])
            .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0),
        });
      }
    }

    // reduce(function(a,b){return  parseFloat(a) + parseFloat(b.amount) }, 0)
    const investment = inv.reduce((acc, obj) => {
      const key = obj.year;
      acc[key] = obj.invest;
      return acc;
    }, Object.create(null));

    //
    dataq.investment = [];
    for (let i = 0; i < quarterData.labels.length; i += 1) {
      if (i === 0) {
        dataq.investment[i] = investment[new Date().getFullYear()];
      } else if (i === 4) {
        dataq.investment[i] = investment[new Date().getFullYear() + 1];
      } else if (i === 8) {
        dataq.investment[i] = investment[new Date().getFullYear() + 2];
      } else if (i === 12) {
        dataq.investment[i] = investment[new Date().getFullYear() + 3];
      } else {
        dataq.investment[i] = 0;
      }
    }

    dataq.changeInCash = dataq.quarterRevenues.map((qe, idx) => qe + dataq.quarterExpense[idx] + dataq.captalExpense[idx] + dataq.investment[idx]);
    dataq.endingCash = dataq.quarterRevenues.map((qe, idx) => qe + dataq.changeInCash[idx]);

    dataq.beginingCash = [];
    dataq.endingCash = [];

    for (let j = 0; j < quarterData.labels.length + 1; j += 1) {
      if (j === 0) {
        dataq.beginingCash.push(startCash);
        dataq.endingCash.push(startCash + dataq.changeInCash[j] - dataq.quarterExpense[j]);
      } else {
        dataq.beginingCash.push(dataq.endingCash[j - 1]);
        dataq.endingCash.push(dataq.beginingCash[j] + dataq.changeInCash[j] - dataq.quarterExpense[j]);
      }
    }


    return { ...quarterData, ...dataq };
  }
}