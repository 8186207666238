/*eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import addBoards from '../../assets/addBoards.png';
import noContent from '../../assets/undraw_empty_re_opql.svg';
import addContent from '../../assets/undraw_add_content_re_vgqa.svg';
import { AuthContext } from '../../context/context';
import BarGraphBoard from './BoardTypes/BarGraphBoard';
import ParsedDataTable from './BoardTypes/ParsedDataTable';
import PieChartBoard from './BoardTypes/PieChartBoard';
import EditBoardsButton from './EditBoardsButton';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { makeStyles } from '@material-ui/styles';
import 'react-grid-layout/css/styles.css';
import './style.css';
import 'react-resizable/css/styles.css';
import { saveLayout } from '../../context/intuit-service';
import AddViews from './AddViews';
import LineGraphBoard from './BoardTypes/LineGraphBoard';
import TextBoard from './BoardTypes/TextBoard';
import { extractId, extractPermission, formatDateRange, getIconType } from './helpers';
import AddTab from './AddTab';
import GraphAssistantBoard from './BoardTypes/GraphAssistantBoard';

const ResponsiveGridLayout = WidthProvider(Responsive);


const BoardsView = (props) => {
  const useStyles = makeStyles(() => ({
    pageBreak: {
      pageBreakBefore: 'always',
      breakBefore: 'page',
    },
    pdfContent: {
      background: 'white',
      width: '100%',
      height: 'auto',
    },
    '@media print': {
      pageBreak: {
        display: 'block',
        pageBreakBefore: 'always',
        breakBefore: 'page',
      },
    },
  }));
  const classes = useStyles();
  const {
    state: {
      integrations, integrationInfo, selectedIntegration, selectedBoard, allBoards, switchUser, selectedTab, isFullScreen
    }, dispatch
  } = React.useContext(AuthContext);

  const [boardArray, setBoardArray] = useState([]);
  const chartInstances = useRef({});
  
  const addChartRef = (chartId, chartInstance) => {
    chartInstances.current[chartId] = chartInstance;
  }

  // useEffect(() => {
  //   if (props.boards && props.boards.boards && props.boards.boards[selectedBoard] && props.boards.boards[selectedBoard].boardData) {
  //     renderBoards(props.boards.boards[selectedBoard].boardData);
  //   }
  // }, [props]);
  useEffect(() => {
    console.log(allBoards);
    if (
      allBoards && 
      allBoards.boards && 
      allBoards.boards[selectedBoard] && 
      allBoards.boards[selectedBoard].tabs &&
      allBoards.boards[selectedBoard].tabs[selectedTab] &&
      allBoards.boards[selectedBoard].tabs[selectedTab].boardData
    ) {
        renderBoards(allBoards.boards[selectedBoard].tabs[selectedTab].boardData);
        console.log(allBoards.boards[selectedBoard].tabs[selectedTab].boardData);
    }
  }, [allBoards, selectedBoard, selectedTab]);


  const [loaded, setLoaded] = useState(false); // Add loaded state
  const [savedLayout, setSavedLayout] = useState(null);
  useEffect(() => {

    if (allBoards && allBoards.boards && allBoards.boards[selectedBoard] && allBoards.boards[selectedBoard].layout) {
      const savedLayout = allBoards.boards[selectedBoard].layout;
      if (savedLayout) {
        setSavedLayout(savedLayout);
      } else {
        setSavedLayout([])
      }
    }
    setLoaded(true);
  }, [selectedBoard]);
  useEffect(() => {
    if (
      allBoards && 
      allBoards.boards && 
      allBoards.boards[selectedBoard] &&
      allBoards.boards[selectedBoard].tabs &&
      allBoards.boards[selectedBoard].tabs[selectedTab]) {
        const savedLayout = allBoards.boards[selectedBoard].tabs[selectedTab].layout;
        setSavedLayout(savedLayout || []); // directly set layout or an empty array
    }
  }, [allBoards, selectedBoard, selectedTab]);

  const checkAccess = () => {
    // if owner or you can modify then you have access to editing boards
    const access = allBoards.boards[selectedBoard].access && extractPermission(allBoards.boards[selectedBoard].access) === "CAN_MODIFY" || allBoards.boards[selectedBoard].owner === switchUser.userName;

    return access;
  }
  

  const renderBoards = (boards) => {
    console.log(boards);
    let renderArray = [];
    for (let i = 0; i < boards.length; i = i + 1) {
      if (boards[i].boardType === "table") {
        renderArray.push({
          boardData: boards[i],
          board: <ParsedDataTable board={boards[i]} data={props.fullDataTable} tableHeaders={props.tableHeaders} />
        });
      } else if (boards[i].boardType === "pie") {
        renderArray.push({
          boardData: boards[i],
          board: <PieChartBoard board={boards[i]} data={props.fullDataTable} />
        });
      } else if (boards[i].boardType === "bar") {
        renderArray.push({
          boardData: boards[i],
          board: <BarGraphBoard board={boards[i]} data={props.fullDataTable} />
        });
      } else if (boards[i].boardType === "line") {
        renderArray.push({
          boardData: boards[i],
          board: (
            <LineGraphBoard
              board={boards[i]}
              data={props.fullDataTable}
              headers={props.tableHeaders}
            />
          ),
        });
      } else if (boards[i].boardType === "graph") {
        console.log("passed graph assistant");
        renderArray.push({
          boardData: boards[i],
          board: <GraphAssistantBoard board={boards[i]} setRef={addChartRef} id={i} />,
        });
      } else if (boards[i].boardType === "Text" || "text") {
        console.log("passed text");
        renderArray.push({
          boardData: boards[i],
          board: <TextBoard board={boards[i]} />,
        });
      }
    }
    setBoardArray(renderArray);
  }

  const handleDragEnd = (newLayout) => {
    // console.log(newLayout);
    if (loaded) {
    setSavedLayout(newLayout);
    handleSaveLayout(newLayout);
    }
  };

  React.useEffect(() => {
    console.log(boardArray[0]?.boardData.boardType);
  }, [boardArray]);

  const handleSaveLayout = async (layout) => {
    let data = { 
      sharedId: switchUser.userName, 
      boardId: allBoards._id, 
      clientId: allBoards.boards[selectedBoard]._id,
      tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id,      
      layout: layout 
    };
    if (allBoards.boards[selectedBoard].access && extractPermission(allBoards.boards[selectedBoard].access) === "CAN_MODIFY") {
      data.boardId = extractId(allBoards.boards[selectedBoard].access)
    }
    const saveLayoutCall = await saveLayout(data);
    if (saveLayoutCall.status === 200) {
      let newBoards = { ...allBoards };
        
        // Iterate over the boards to find and update the correct tab
        for (let i = 0; i < newBoards.boards.length; i++) {
            if (newBoards.boards[i]._id === allBoards.boards[selectedBoard]._id) {
                for (let j = 0; j < newBoards.boards[i].tabs.length; j++) {
                    if (newBoards.boards[i].tabs[j]._id === allBoards.boards[selectedBoard].tabs[selectedTab]._id) {
                        newBoards.boards[i].tabs[j].layout = layout;
                        break;
                    }
                }
                break;
            }
        }
      dispatch({
        type: 'SET_ALLBOARDS',
        payload: newBoards
      });
    }

  };

  // useEffect(() => {
  //   // This effect will run after the component is initially rendered
  //   setLoaded(true);
  // }, [savedLayout]);

  const tooltip1 = (
    <Tooltip id="tooltip">Drag to Move</Tooltip>
  );

  const [hovered1, setHovered1] = useState(false);

  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  const handleIconHover1 = () => {
    setHovered1(!hovered1);
  };

  return (
    <>
       {props.boards.boards.length === 0 ?
        <div className="pl-5 pr-5 pt-3 pb-3">
          Make a new Client!
        </div>
        :
        <>
          {props.boards && (
            <div className="board-view">
              <AddTab  />
              {props.boards && 
                props.boards.boards &&
                props.boards.boards.length > 0 &&
                props.boards.boards[selectedBoard] &&
                props.boards.boards[selectedBoard].tabs[selectedTab] &&
                props.boards.boards[selectedBoard].tabs[selectedTab].boardData &&
                props.boards.boards[selectedBoard].tabs[selectedTab].boardData.length === 0 ? (
                <>
                  {!props.disabled &&
                    <div className="pt-5 d-flex flex-column align-items-center">
                      {!isFullScreen &&
                        <>
                          <img src={addContent} style={{ width: "15%" }} alt="Add Content" />
                          <h2 className="mt-3">Add a new chart or text to get started.</h2>
                          <AddViews newBoard={true} />
                        </>
                      }
                      {isFullScreen &&
                        <>
                          <img src={noContent} style={{ width: "10%" }} alt="Empty" />
                          <h2 className="mt-3">Empty tab</h2>
                        </>
                      }
                    </div>
                  }
                </>
              ) : (
                <div className="container-fluid" style={{ width: "94%", }}>
                  <div className="row">
                    <div className="col-md-12">
                      <div id="pdfExportContent" className={classes.pdfContent}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {savedLayout !== null &&
                          <ResponsiveGridLayout
                            className=""
                            layouts={{ lg: savedLayout, md: savedLayout, sm: savedLayout }} // Restore the layout from local storage
                            onLayoutChange={handleDragEnd}
                            breakpoints={{ lg: 1000, md: 900, sm: 600, xs: 480, xxs: 0 }}
                            cols={{ lg: 10, md: 10, sm: 10, xs: 10, xxs: 10 }}
                            draggableHandle=".handle"
                            isResizable={checkAccess() ? isHover : false}
                            isBounded={false}
                            useCSSTransforms={true}
                            transformScale={1}
                            autoSize={true}
                            rowHeight={115}
                            margin={[23, 23]}
                          >
                            {boardArray.map((board, i) => {
                              const isTextBoard = board.boardType === 'text';
                              console.log(board);
                              return (
                                <div
                                  key={i}
                                  data-grid={{ x: 0, y: Infinity, w: 12, h: isTextBoard ? 1 : 4, minW: 2, minH: 4 }}
                                >
                                  <div
                                    className="mb-5"
                                    style={{ 
                                      display: "grid", 
                                      gridTemplateColumns: "10fr", 
                                      width: "100%", 
                                      height: isTextBoard ? 'auto' : '100%' 
                                    }}
                                  >
                                    <div
                                      className="ui card pb-2"
                                      style={{
                                        width: '100%', 
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                        height: isTextBoard ? 'auto' : '100%',
                                      }}
                                    >
                                      <div className="header">
                                      {/* this is where the drag happens, because class name is handle, in the ResponsiveGridLayout component, the "draggable-handle" property is equal to this, which allows for dragging */}
                                        <div className='handle'>
                                          { allBoards.boards[selectedBoard].access && extractPermission(allBoards.boards[selectedBoard].access) === "CAN_MODIFY" || allBoards.boards[selectedBoard].owner ===  switchUser.userName && <OverlayTrigger placement="top" overlay={tooltip1}>
                                            <span
                                              className="bi bi-grip-vertical"
                                              onMouseEnter={handleIconHover1}
                                              onMouseLeave={handleIconHover1}
                                              style={{ fontSize: '14px', color: 'grey', cursor: 'grab', paddingLeft: '-20px' }}
                                            />
                                          </OverlayTrigger> }
                                        </div>

                                        {board && board.boardData && board.boardData.boardView ? (
                                          <>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ display: 'flex' }}>
                                                <p>
                                                  {board.boardData.title}
                                                </p>
                                              </div>
                                              <span
                                                style={{
                                                  fontSize: '14px',
                                                  paddingLeft: '0px',
                                                  color: 'gray',
                                                  marginBottom: '10px',
                                                  marginTop: '5px',
                                                  whiteSpace: 'nowrap', // Add this property to prevent text wrapping
                                                }}
                                              >
                                                {formatDateRange(props.date)}
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          ''
                                        )}

                                        {!props.disabled && props.boards && props.boards.boards && (
                                          <div className="edit-button-container">
                                            <EditBoardsButton
                                              fullBoard={props.boards.boards[selectedBoard]}
                                              board={props.boards.boards[selectedBoard].tabs[selectedTab].boardData[i] ?? {}}
                                              getBoards={props.getBoards}
                                              boardId={props.boardId}
                                              boardType={board.boardData.boardType}
                                            />
                                          </div>
                                        )}
                                      </div>

                                      <div
                                        className="content"
                                        style={{
                                          width: '100%',
                                          overflow: 'hidden',
                                          overflowY: 'auto',
                                          overflowX: 'auto',
                                          height: isTextBoard ? 'auto' : '100%', // Adjust height based on board type
                                          paddingBottom: '70px',
                                        }}
                                      >
                                        {isTextBoard ? (
                                          <div>
                                            <p dangerouslySetInnerHTML={{ __html: board.boardData[0].text }} />
                                          </div>
                                        ) : (
                                          <div>{board.board}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </ResponsiveGridLayout>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {!props.disabled && <AddViews boards={props.boards} />}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      }
    </>
  );
};

export default BoardsView;

