/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Tabs, Tab, IconButton, InputBase, Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';
import { makeStyles } from '@material-ui/styles';
import { addNewTab, deleteTab } from '../../context/intuit-service';
import { AuthContext } from '../../context/context';
import { extractId, extractPermission } from './helpers';
import { updateTabName } from '../../context/intuit-service';
import { Box } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  editableTab: {
    padding: '6px 12px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  tabIcon: {
    visibility: 'hidden',
    marginLeft: '8px',
  },
  tabIconVisible: {
    visibility: 'visible',
  },
  tabRoot: {
    '&:focus': {
      outline: 'none',
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  selectedTab: {
    backgroundColor: 'transparent !important',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },
}));

const AddTab = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [hoveredTabIndex, setHoveredTabIndex] = useState(null);
  const [editTabIndex, setEditTabIndex] = useState(null);
  const [tabTitleInput, setTabTitleInput] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tabToDelete, setTabToDelete] = useState(null);
  const tabsRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const {
    state: { selectedBoard, allBoards, selectedTab, switchUser, isFullScreen },
    dispatch
  } = useContext(AuthContext);

  useEffect(() => {
    const boardTabs = allBoards?.boards?.[selectedBoard]?.tabs;
    if (boardTabs?.length > 0) {
      const validTabSelected = selectedTab >= 0 && selectedTab < boardTabs.length;
      setTabIndex(validTabSelected ? selectedTab : 0);
      if (!validTabSelected) {
        dispatch({ type: 'SET_SELECTEDTAB', payload: 0 });
      }
    }
  }, [selectedTab, allBoards, selectedBoard, dispatch]);

  useEffect(() => {
        dispatch({ type: 'SET_SELECTEDTAB', payload: 0 });
    }, [selectedBoard]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    dispatch({ type: 'SET_SELECTEDTAB', payload: newValue });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const addNewTabHandler = async () => {
    try {
      const selectedBoardId = allBoards.boards[selectedBoard]._id;
      const allBoardsId = allBoards._id;
      const response = await addNewTab({ boardId: allBoardsId, clientId: selectedBoardId, title: `Tab ${allBoards.boards[selectedBoard]?.tabs?.length + 1 || 1}` });
      const newBoards = response.data.data;
      dispatch({ type: 'SET_ALLBOARDS', payload: newBoards });
      const newTabIndex = newBoards.boards[selectedBoard].tabs.length - 1;
      dispatch({ type: 'SET_SELECTEDTAB', payload: newTabIndex });
      setTabIndex(newTabIndex);
    } catch (error) {
      console.error('Failed to add new tab:', error);
    }
  };

  const handleScroll = () => {
    if (tabsRef.current) {
      setIsSticky(window.scrollY > tabsRef.current.offsetTop);
    }
  };

  const handleTabEdit = (index) => {
    setEditTabIndex(index);
    setTabTitleInput(allBoards.boards[selectedBoard].tabs[index].title);
  };

  const checkAccess = () => {
    // if owner or you can modify then you have access to editing boards
    const access =
      (allBoards.boards[selectedBoard].access &&
        extractPermission(allBoards.boards[selectedBoard].access) ===
          "CAN_MODIFY") ||
      allBoards.boards[selectedBoard].owner === switchUser.userName;

    return access;
  };
  

  const handleTabTitleChange = (event) => {
    setTabTitleInput(event.target.value);
  };

  const handleTabTitleBlur = async () => {
    if (tabTitleInput !== allBoards.boards[selectedBoard].tabs[editTabIndex].title) {
      const newTabs = [...allBoards.boards[selectedBoard].tabs];
      newTabs[editTabIndex].title = tabTitleInput;
      let data= {
        boardId:allBoards._id, 
        clientId:allBoards.boards[selectedBoard]._id, 
        tabId:allBoards.boards[selectedBoard].tabs[editTabIndex]._id, 
        newTitle: tabTitleInput
      };
      if (allBoards.boards[selectedBoard].access) {
        data.boardId = extractId(allBoards.boards[selectedBoard].access);
      }
      const newBoards = { ...allBoards };
      newBoards.boards[selectedBoard].tabs = newTabs;
      const response=await updateTabName(data);
      dispatch({ type: 'SET_ALLBOARDS', payload: response.data.data });
      setEditTabIndex(null);
    } else {
      setEditTabIndex(null);

    }
  };

  const handleTabTitleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleTabTitleBlur();
    }
  };

  const handleTabDelete = (index) => {
    console.log('tab delete clicked');
    setTabToDelete(index);
    setOpenDeleteDialog(true);
  };

  const confirmTabDelete = async () => {
    try {
      const response = await deleteTab({ 
        boardId: allBoards._id,
        clientId: allBoards.boards[selectedBoard]._id, 
        tabId: allBoards.boards[selectedBoard].tabs[tabToDelete]._id 
      });
      const newBoards = response.data.data;
      dispatch({ type: 'SET_ALLBOARDS', payload: newBoards });
      const newTabIndex = Math.max(newBoards.boards[selectedBoard].tabs.length - 1, 0);
      dispatch({ type: 'SET_SELECTEDTAB', payload: newTabIndex });
      setTabIndex(newTabIndex);
      setOpenDeleteDialog(false);
      setTabToDelete(null);
    } catch (error) {
      console.error('Failed to delete tab:', error);
    }
  };

  const cancelTabDelete = () => {
    setTabToDelete(null);
    setOpenDeleteDialog(false);
  };

  return (
    <>
    {allBoards?.boards?.[selectedBoard]?.tabs && (
      <div
        ref={tabsRef}
        className={isSticky ? classes.stickyTabs : ""}
        style={{
          position: isSticky ? "fixed" : "static",
          top: 53.84,
          left: 235,
          right: 0,
          zIndex: 800, // Ensure it stays on top of other content
          backgroundColor: "white",
          paddingTop: "10px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr auto .03fr', // Set flexible space for tabs and auto for the button
            alignItems: 'center', // Aligns the content vertically
          }}
        >
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={tabIndex}
              onChange={handleTabChange} // Keep the event handler intact
              aria-label="scrollable auto chart tabs"
            >
              {allBoards.boards[selectedBoard].tabs.map((tab, index) => (
                <Tab
                  key={index}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selectedTab,
                  }}
                  label={
                    <div
                      className={classes.tabLabel}
                      onMouseEnter={() => setHoveredTabIndex(index)}
                      onMouseLeave={() => setHoveredTabIndex(null)}
                    >
                      {editTabIndex === index ? (
                        <InputBase
                          value={tabTitleInput}
                          onChange={handleTabTitleChange}
                          onBlur={handleTabTitleBlur}
                          onKeyPress={handleTabTitleKeyPress}
                          className={classes.editableTab}
                          autoFocus
                        />
                      ) : (
                        <span onDoubleClick={() => handleTabEdit(index)}>
                          {tab.title}
                        </span>
                      )}
                      <span className={isFullScreen ? 'hide-in-fullscreen' : ''}>
                        {checkAccess() && (
                          <EditIcon
                            onClick={() => handleTabEdit(index)}
                            className={
                              hoveredTabIndex === index
                                ? classes.tabIconVisible
                                : classes.tabIcon
                            }
                          />
                        )}
                        {checkAccess() && (
                          <CloseIcon
                            onClick={() => handleTabDelete(index)}
                            className={
                              hoveredTabIndex === index
                                ? classes.tabIconVisible
                                : classes.tabIcon
                            }
                          />
                        )}
                      </span>
                    </div>
                  }
                />
              ))}
            </Tabs>
          {/* IconButton on the right */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {checkAccess() && (
              <IconButton onClick={addNewTabHandler}>
                <AddIcon className={isFullScreen ? 'hide-in-fullscreen' : ''} />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    )}

      {/* Add the dialog for confirming tab deletion */}
      <Dialog open={openDeleteDialog} onClose={cancelTabDelete}>
        <Box sx={{padding: '10px 5px'}}>
          <DialogTitle>
            Are you sure you want to delete the tab "
            {allBoards.boards[selectedBoard]?.tabs[tabToDelete]?.title}"?
          </DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#000", color: "#fff" }}
              onClick={confirmTabDelete}
            >
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AddTab;
