/* eslint-disable */

import React, { useState } from 'react';
import { Chart } from "react-google-charts";
import { AuthContext } from '../../../context/context';
import { setListItemsLine } from '../helpers';

const LineGraphBoard = (props) => {
  const {
    state: {
      integrations, integrationInfo, selectedIntegration, selectedBoard
    }, 
    dispatch
  } = React.useContext(AuthContext);

  const [parsedDataTablePie, setParsedDataTablePie] = useState([])

  React.useEffect(() => {
    setListItems(props.data, props.board.boardData, props.board.boardFormulas, props.headers)
  }, [props.board, props.data])

  const options = {
    title: "",
    legend: {
      position: 'top'
    },
    chartArea: { width: '70%', right: 50 },
    hAxis: {
      title: '',
      format: ''
    },
    vAxis: {
      format: props.board.boardView
    },
    chartArea: {
      left: 100, // 50 pixels from the left edge of the chart
      top: 50, // 20 pixels from the top of the chart
      bottom: 50, // 20 pixels from the top of the chart
      right: 50, // 20 pixels from the top of the chart
  
      width: '100%', // 70% of the chart width
      height: '100%', // 80% of the chart height
    },
    crosshair: { 
      trigger: 'both',
      orientation: 'vertical'
    }, // Display crosshairs on focus and selection.
  };

  const setListItems = (allData, data, formulas, headers) => {
    let flippedData = setListItemsLine(allData, data, formulas, headers)
    setParsedDataTablePie(flippedData);
  }

  return (
    <>
      {parsedDataTablePie &&
        <Chart
          chartType="AreaChart"
          data={parsedDataTablePie}
          options={options}
          width={"100%"}
          height={"400px"}
        />
      }
    </>
  );
};

export default LineGraphBoard;
