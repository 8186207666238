/* eslint-disable */

import React from 'react';
import { Link } from 'react-router-dom';
import LOGO from '../../assets/Dx.png';
import axios from '../../context/axios';
import ConfirmRegistration from './Confirm';
import { useStyles } from './styles.js';

function SignUp() {
  const classes = useStyles();

  const [alertClass, setAlertClass] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [err, setErr] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [confirm, setConfrim] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState('password');
  const [signupForm, setSignupForm] = React.useState({
    email: '',
    cpassword: '',
  });

  const [passwordReqs, setPasswordReqs] = React.useState({
    lowerCase: false,
    upperCase: false,
    number: false,
    special: false,
    lengthOf8: false,
  });

  const [showPasswordReqsPanel, setShowPasswordReqsPanel] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(false);
  const [validPassword, setValidPassword] = React.useState(false);

  const handleSignUp = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'password') {
      passwordValidator(value);
    } else {
      emailValidator(value);
    }
    setSignupForm((prevState) =>
    ({
      ...prevState,
      [name]: value,
    }));



  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const signup = await axios.post('/signup', signupForm);
      if (signup.status === 200 || signup.status === 201) {
        setErr('');
        setAlertClass('show');
        setSignupForm({
          email: signupForm.email,
          password: '',
        });
        setConfrim(true);
        setMsg('Successfully Registered');
        setLoader(false);
      }
    } catch (e) {
      setAlertClass('show');
      setMsg('');
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else if (e.response.data.errors && e.response.data.errors.length > 0) {

          setErr(e.response.data.errors[0].msg);
        } else {
          setErr(e.response.data.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader(false);
    }
  };
  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  const handlePwdType = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };

  const showPasswordReqs = (e) => {
    e.preventDefault();
    setShowPasswordReqsPanel(true);
  };
  const hidePasswordReqs = (e) => {
    e.preventDefault();
    setShowPasswordReqsPanel(false);
  };

  const emailValidator = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(true);
    }
  };

  const passwordValidator = (password) => {
    let lowerCase = false;
    let upperCase = false;
    let number = false;
    let special = false;
    let lengthOf8 = false;
    if (password.match(/[a-z]/)) {
      lowerCase = true;
    }
    if (password.match(/[A-Z]/)) {
      upperCase = true;
    }
    if (password.match(/[0-9]/)) {
      number = true;
    }
    if (password.match(/(?=.*[!@#$%^&*])/)) {
      special = true;
    }
    if (password.length >= 8) {
      lengthOf8 = true;
    }

    setPasswordReqs({
      lowerCase, upperCase, number, special, lengthOf8,
    });
    setValidPassword(lowerCase && upperCase && number && special && lengthOf8);
  };

  const PasswordReqsPanel = (props) => (
    <div style={{
      borderStyle: 'solid', borderColor: '#CAD4D7', borderRadius: '5px', borderWidth: '1px', width: '250px', fontSize: '15px', position: 'fixed', backgroundColor: 'white', zIndex: '10',
    }}
    >
      <div className="row align-items-center justify-content-center">
        <p className="mt-3 ml-5">
          {props.passwordReqs.lowerCase ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One lowercase Character
        </p>
        <p className="ml-5">
          {props.passwordReqs.upperCase ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One uppercase character
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.number ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One number
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.special ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One special character
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.lengthOf8 ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          8 characters minimum
          {' '}
        </p>
      </div>
    </div>
  );

  return confirm ? (
    <ConfirmRegistration email={signupForm.email} alertClass={alertClass} setAlertClass={setAlertClass} setMsg={setMsg} msg={msg} err={err} setErr={setErr} loader={loader} setLoader={setLoader} />
  ) : (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className="row">
          <div className={[classes.toolbar, classes.Logo].join(' ')}>
            <Link to="/">
              <img src={LOGO} alt="logo-black" style={{ width: '190px', height: '30px', marginLeft: '30px' }} />
            </Link>
          </div>
        </div>
        <div>
          <div className={classes.container}>
            <h2 className="display-4 text-center mb-3" style={{ color: '#55616E' }}>Welcome to Datatrixs</h2>
            <p className="text-center mb-3" style={{ fontSize: 20}}>
              Already have an account?
              {' '}
              <Link to="/login">
                Sign in
              </Link>
            </p>
            <p className="text-center mb-3 or-divider" style={{ fontSize: 20}}>
              {' '}
              or
            </p>

            {msg && (
              <div className={`alert alert-success alert-dismissible fade ${alertClass}`} role="alert">
                <strong>{msg}</strong>
                <button onClick={handleCloseAlert} type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            )}
            {err && (
              <div className={`alert alert-danger alert-dismissible fade ${alertClass}`} role="alert">
                <strong>{err}</strong>
                <button onClick={handleCloseAlert} type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            )}

            <form onSubmit={handleSubmit} data-rewardful="true">
              <div className="form-group">

                <input type="email" name="email" value={signupForm.email} onChange={handleSignUp} className="form-control" required placeholder="Email" />
              </div>

              <div className="form-group">

                <div className="input-group input-group-merge">
                  <input className="form-control" name="password" onFocus={showPasswordReqs} onBlur={hidePasswordReqs} value={signupForm.password} onChange={handleSignUp} required type={passwordType} placeholder="Password" />

                  <span onClick={handlePwdType} className="input-group-text">
                    <i style={{ cursor: 'pointer', fontSize: '12px' }} className="fe fe-eye" />
                  </span>
                </div>
                {(showPasswordReqsPanel && !validPassword) && <PasswordReqsPanel passwordReqs={passwordReqs} />}
              </div>

              <button disabled={loader || (!validPassword || !validEmail)} style={{ margin: 0, background: 'black' }} className="btn btn-lg btn-block btn-custom mb-3">
                {loader && (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!loader && 'Sign up'}
              </button>
              <div className="text-center">
                <small className="text-muted text-center">
                  By clicking the "Sign Up" button, you are creating a Datatrixs account, and you agree to Datatrixs's
                  {' '}
                  <a target="_blank" rel="noreferrer" href="https://www.getfinpro.com/terms-of-service">Terms of Use</a>
                  {' '}
                  and 
                  {' '}
                  <a target="_blank" rel="noreferrer" href="https://www.getfinpro.com/privacy-policy">Privacy Policy</a>
                  .
                </small>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
